import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import cn from "../../utils/cn";
import IconButton from "./icon-button";

function DataTable({
  data,
  columns,
  border,
  label,
  handleRowClick,
  pageIndex = 0,
  pageSize = 10,
  totalCount = 0,
  isLoading = false,
  setPagination,
}) {
  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(totalCount / pageSize),
    state: { pagination },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  const tablePageIndex = table.getState().pagination.pageIndex;

  const startItemNumber = tablePageIndex * pageSize + 1;
  const endItemNumber = Math.min((tablePageIndex + 1) * pageSize, totalCount);

  if (
    !isLoading &&
    (totalCount === 0 || (totalCount < pageSize && data.length === 0))
  )
    return (
      <div className="text-center text-xs text-neutral-100">
        No data available
      </div>
    );

  return (
    <div className="text-xs text-neutral-100">
      <div className="mb-2 overflow-x-auto">
        <table className="w-full">
          <thead className="border-y border-neutral-300">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="whitespace-nowrap p-4 text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                className={cn(
                  "relative",
                  border ? "border-y border-neutral-300" : "border-none",
                  handleRowClick && "cursor-pointer hover:bg-slate-100",
                )}
                onClick={() => handleRowClick && handleRowClick(row.original)}
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="truncate p-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between gap-2 px-4">
        <div>
          {startItemNumber} - {endItemNumber} of {totalCount} {label}
        </div>
        <div className="flex items-center gap-1">
          {isLoading && <ArrowPathIcon className="h-4 w-4 animate-spin" />}
          {tablePageIndex + 1} of {table.getPageCount()}
          <IconButton
            variant="ghost"
            icon={<ArrowLongLeftIcon className="w-[1.5rem]" />}
            description="Next page"
            className="p-2 sm:p-2"
            onClick={() => table.previousPage()}
            disabled={isLoading || !table.getCanPreviousPage()}
          />
          <IconButton
            variant="ghost"
            icon={<ArrowLongRightIcon className="w-[1.5rem]" />}
            description="Next page"
            className="p-2 sm:p-2"
            onClick={() => table.nextPage()}
            disabled={isLoading || !table.getCanNextPage()}
          />
        </div>
      </div>
    </div>
  );
}

export default DataTable;
