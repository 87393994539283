import { Popover as HeadlessPopover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Button from "./button";

function Popover({ header, label, children, onReset }) {
  return (
    <HeadlessPopover>
      <HeadlessPopover.Button
        type="button"
        className="flex items-center gap-1 rounded-sm border border-neutral-400 px-3 py-2 ring-blue-400 focus:ring-1"
      >
        {label}
        <ChevronDownIcon className="h-4 w-4" />
      </HeadlessPopover.Button>

      <HeadlessPopover.Panel className="absolute left-0 right-0 z-20 mx-auto w-screen max-w-[calc(100%-32px)] translate-y-2 bg-white sm:left-auto sm:right-auto sm:max-w-sm">
        <div className="min-w-full rounded-sm border border-neutral-400 text-sm">
          <div className="flex items-center justify-between gap-2 border-b border-b-neutral-400 p-2">
            <p className="whitespace-nowrap">{header}</p>
            <Button
              variant="link"
              className="p-0 text-dark-100 underline"
              onClick={onReset}
            >
              Reset
            </Button>
          </div>
          <div className="flex flex-col gap-2 px-2 py-4">{children}</div>
        </div>
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  );
}

export default Popover;
