import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import DataTable from "../../components/commons/data-table";
import SearchInput from "../../components/commons/search-input";
import {
  EditIcon,
  EllipsisIcon,
  TrashIcon,
  ViewIcon,
} from "../../components/icons";
import { INVOICES } from "../../utils/fake-data";

const MENU_ITEMS = [
  {
    title: "View",
    Icon: ViewIcon,
    route: "#",
  },
  {
    title: "Edit",
    Icon: EditIcon,
    route: "#",
  },
  {
    title: "Delete",
    Icon: TrashIcon,
    route: "#",
  },
];

function InvoiceCreatorPage() {
  const [query, setQuery] = useState("");

  const columns = useMemo(
    () => [
      {
        accessorKey: "invoiceNumber",
        header: () => "Invoice Number",
        cell: info => info.getValue(),
      },
      {
        accessorKey: "dateIssued",
        header: () => "Date Issued",
        cell: info => info.getValue(),
      },
      {
        accessorKey: "billedTo",
        header: () => "Billed To",
        cell: info => info.getValue(),
      },
      {
        accessorKey: "dueDate",
        header: () => "Due Date",
        cell: info => info.getValue(),
      },
      {
        accessorKey: "id",
        header: () => "Actions",
        cell: info => (
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button
              size="sm"
              variant="ghost"
              className="inline-flex items-center justify-center rounded-md bg-transparent p-1 text-sm font-medium text-dark-100 transition-colors hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-1 active:scale-95 disabled:pointer-events-none disabled:opacity-50"
            >
              <EllipsisIcon />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 w-[11rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {MENU_ITEMS.map(({ route, title, Icon }) => (
                  <Menu.Item key={title}>
                    <Link to={route}>
                      <button
                        className={`flex w-full items-center gap-[10px] border-b border-neutral-300 px-5 py-3 text-dark-100 hover:bg-slate-100 ${
                          title === "Delete" ? "text-red-500" : ""
                        }`}
                      >
                        <Icon className="h-4 w-4" />
                        <p className="text-xs font-medium">{title}</p>
                      </button>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        ),
      },
    ],
    [],
  );

  return (
    <div className="mx-auto max-w-[1061px] rounded-lg border border-neutral-300 p-4">
      <div className="mb-6 flex items-center justify-between sm:mb-8">
        <p className="p-1 font-semibold text-dark-100">Invoices</p>
        <Link to="/invoice-creator/create">
          <Button
            variant="ghost"
            className="gap-1 text-xs font-semibold text-dark-100 shadow-none"
            size="sm"
          >
            <PlusIcon className="w-4 text-lg" />
            New Invoice
          </Button>
        </Link>
      </div>
      <div>
        {INVOICES.length === 0 ? (
          <div className="grid min-h-[300px] place-items-center text-xs font-semibold text-neutral-200">
            No invoices yet
          </div>
        ) : (
          <>
            <div className="mb-6 flex justify-end">
              <SearchInput
                onSearch={value => {}}
                placeholder="Search"
                query={query}
                setQuery={setQuery}
                containerClass="w-full sm:w-auto focus-within:border-b focus-within:border-b-blue-500"
                iconClass="h-4 w-4 sm:h-5 sm:w-5"
                inputClass="text-sm"
              />
            </div>
            <DataTable
              label="Invoices"
              data={INVOICES}
              columns={columns}
              totalCount={INVOICES.length}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default InvoiceCreatorPage;
