import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";

const AuthGuard = ({ children }) => {
  const user = useRecoilValue(userState);
  const location = useLocation();
  const searchParams = new URLSearchParams();
  searchParams.set("redirect", location.pathname);

  if (!user?.store)
    return (
      <Navigate
        to={{ pathname: "/login", search: `?${searchParams.toString()}` }}
        replace
      />
    );

  return children ? <>{children}</> : <Outlet />;
};
export default AuthGuard;
