import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../commons/button";

const ButtonContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="border-t-[1px] border-neutral-300 py-[2rem]">
      <div className="flex justify-end">
        <div className="flex gap-[1rem] pr-[2rem]">
          <Button variant="outline" onClick={() => navigate("/settings")}>
            Cancel changes
          </Button>
          <Button className="px-8 py-3" type="submit">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ButtonContainer;
