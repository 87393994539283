import React from "react";
import { SearchIcon } from "../../constants/assets-constants";
import cn from "../../utils/cn";

const SearchInput = ({
  placeholder,
  onSearch,
  query,
  setQuery,
  iconClass,
  inputClass,
  containerClass,
}) => {
  const handleInputChange = event => {
    const searchValue = event.target.value;
    if (setQuery) setQuery(searchValue);
    if (onSearch) onSearch(query);
  };

  return (
    <div className={cn("flex items-center", containerClass)}>
      <img src={SearchIcon} alt="search" className={iconClass} />
      <input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={e => handleInputChange(e)}
        className={cn(
          "w-full border-none bg-transparent focus:shadow-transparent focus:outline-none focus:ring-transparent",
          inputClass,
        )}
      />
    </div>
  );
};

export default SearchInput;
