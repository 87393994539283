import React, { useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import BusyOverlay from "../../components/commons/busy-overlay";
import Button from "../../components/commons/button";
import SEO from "../../components/commons/seo";
import { DigibleLogo } from "../../constants/assets-constants";
import { useVerifyOrderMutation } from "../../operations/mutations";
import formatPrice from "../../utils/formatPrice";

const VALID_STATUSES = ["successful", "completed", "cancelled"];

const getPrice = ({ totalPrice, quantity }) =>
  formatPrice(parseFloat(totalPrice) * quantity);

export default function VerifyOrderPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const status = searchParams.get("status");
  const paymentReference = searchParams.get("tx_ref");
  const transactionId = searchParams.get("transaction_id");
  const { mutate: verify, isLoading, data, isIdle } = useVerifyOrderMutation();
  const storeUrl = localStorage.getItem("storeUrl");
  const order = data?.data?.data?.order ?? data?.data?.data?.updateOrder;
  const orderDetail = order?.orderDetail ? JSON.parse(order.orderDetail) : null;
  const deliveryFee = orderDetail?.shipping?.amount ?? 0;

  const total = useMemo(() => {
    if (!orderDetail?.cart) return 0;
    return orderDetail.cart?.reduce(
      (acc, curr) => acc + parseFloat(curr.totalPrice) * curr.quantity,
      0,
    );
  }, [orderDetail]);

  useEffect(() => {
    if (status === "cancelled") {
      navigate(`/${storeUrl}/checkout?status=cancelled`);
      return;
    }

    if (!paymentReference) {
      toast.error("Payment reference is missing");
      return;
    }
    if (!transactionId) {
      toast.error("Transaction ID is missing");
      return;
    }
    if (!status || !VALID_STATUSES.includes(status)) {
      toast.error("Invalid payment status");
      return;
    }
    if (status === "successful" || status === "completed")
      verify({ paymentReference, transactionId });
  }, [status, transactionId, paymentReference, verify, navigate, storeUrl]);

  return (
    <div className="mx-auto max-w-4xl bg-[#FAFFFE] p-4 text-dark-100 sm:my-10">
      <SEO title="Verify Order" />
      {!paymentReference ||
      !transactionId ||
      !status ||
      !VALID_STATUSES.includes(status) ? null : isIdle || isLoading ? (
        <BusyOverlay
          loading={isIdle || isLoading}
          text="Verifying order..."
          opaque
        />
      ) : !order ? (
        <h1 className="text-center text-30 font-semibold">Order not found!</h1>
      ) : (
        <div className="flex flex-col gap-8 sm:gap-16">
          <div className="flex flex-col items-center gap-6">
            <Link to={`/${storeUrl}`}>
              <img src={DigibleLogo} alt="logo" />
            </Link>
            <h1 className="text-30 font-semibold">Thank you for your order!</h1>
          </div>
          <div className="sm:p-6">
            <div className="flex flex-col justify-between gap-6 sm:flex-row sm:items-center">
              <div>
                <h6 className="text-sm text-neutral-100 sm:text-lg">
                  Reference
                </h6>
                <p className="text-xl font-semibold sm:text-22">
                  {order?.orderReference}
                </p>
              </div>
              <div>
                <h6 className="text-sm text-neutral-100 sm:text-lg">Date</h6>
                <p className="text-xl font-semibold sm:text-22">
                  {order?.createdAt &&
                    Intl.DateTimeFormat("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }).format(new Date(order.createdAt))}
                </p>
              </div>
              <div>
                <h6 className="text-sm text-neutral-100 sm:text-lg">
                  Delivery Address
                </h6>
                <p className="text-xl font-semibold sm:text-22">
                  {order?.shipping?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="sm:p-6">
            <h4 className="border-b-2 border-b-neutral-300 pb-2 text-lg font-semibold sm:text-2xl">
              Product Summary
            </h4>
            <div className="overflow-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-sm text-neutral-100 sm:text-lg">
                    <th className="whitespace-nowrap p-3 text-left">Product</th>
                    <th className="whitespace-nowrap p-3 text-center">
                      Quantity
                    </th>
                    <th className="whitespace-nowrap p-3 text-right">Total</th>
                  </tr>
                </thead>
                <tbody className="text-base sm:text-2xl">
                  {orderDetail?.cart?.map(product => (
                    <tr
                      key={product._id}
                      className="text-base font-medium sm:text-22"
                    >
                      <td className="whitespace-nowrap p-3 text-left">
                        <p>{product.productId.productName}</p>
                        {product.productVariants.length > 0 && (
                          <p className="text-sm text-neutral-100">
                            {product.productVariants.map(
                              (variant, index, arr) => (
                                <React.Fragment key={index}>
                                  {variant.variantName}
                                  {index + 1 !== arr.length && ", "}
                                </React.Fragment>
                              ),
                            )}
                          </p>
                        )}
                      </td>
                      <td className="whitespace-nowrap p-3 text-center">
                        {product.quantity}
                      </td>
                      <td className="whitespace-nowrap p-3 text-right">
                        {getPrice(product)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-col-reverse gap-4 p-2 sm:flex-row sm:justify-end">
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <p className="text-sm text-neutral-100 sm:text-lg">Total</p>
                  <p className="justify-self-end text-base font-medium sm:justify-self-auto sm:text-2xl">
                    {formatPrice(total)}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <p className="text-sm text-neutral-100 sm:text-lg">
                    Delivery Fee
                  </p>
                  <p className="justify-self-end text-base font-medium sm:justify-self-auto sm:text-2xl">
                    {formatPrice(deliveryFee)}
                  </p>
                </div>
                <hr />
                <div className="grid grid-cols-2 gap-4">
                  <p className="text-sm text-neutral-100 sm:text-lg">
                    Grand Total
                  </p>
                  <p className="justify-self-end text-base font-medium sm:justify-self-auto sm:text-2xl">
                    {formatPrice(order?.orderAmount ?? 0)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Button
            variant="link"
            className="block text-center text-lg text-green-100 underline underline-offset-4 sm:text-30"
            onClick={() => window.print()}
          >
            Print Receipt
          </Button>
          <p className="text-base font-medium text-black sm:text-22">
            Having issues downloading? please reach out to us via
            <span>
              {" "}
              <a href="mailto:digibleonline@gmail.com" className="underline">
                digibleonline@gmail.com
              </a>
            </span>{" "}
            or have a chat with us.
          </p>
          <p className="text-base font-medium text-black sm:text-22">
            Got products of your own? Sign Up on Digible and start selling in
            minutes for free.
          </p>
        </div>
      )}
    </div>
  );
}
