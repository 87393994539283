import React from "react";
import { useRecoilValue } from "recoil";
import BusyOverlay from "../../components/commons/busy-overlay";
import { useGetAnalytics, useGetCurrentUser } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import cn from "../../utils/cn";
import formatPrice from "../../utils/formatPrice";
import SEO from "../../components/commons/seo";

function DashboardPage() {
  const { data: userData } = useGetCurrentUser();
  const { store } = useRecoilValue(userState);
  const { data: response, isLoading } = useGetAnalytics(store?.id);
  const analytics = response?.data?.data;
  const collectionSales = analytics?.collectionSales ?? [];
  const totalSales = collectionSales.reduce(
    (currentValue, currentItem) => currentValue + currentItem.productCount,
    0,
  );
  const nigerianOrders = analytics?.totalSales?.nigerianOrders ?? 0;
  const internationalOrders = analytics?.totalSales?.internationalOrders ?? 0;
  const totalOrders = nigerianOrders + internationalOrders;
  const storeViews = userData?.data?.data?.store?.storeViews ?? 0;
  const productCount = analytics?.productCount ?? 0;
  const totalStoreOrders = analytics?.totalStoreOrders ?? 0;

  return (
    <div className="grid grid-cols-2 gap-4 md:gap-6">
      <SEO title="Dashboard" />
      <BusyOverlay loading={isLoading} />
      <Card
        title="Total Sales"
        value={formatPrice(analytics?.totalNgSales)}
        className="col-span-2 sm:col-span-1"
      />
      <Card
        title="Total Sales"
        background={false}
        value={formatPrice(0, "USD")}
      />
      <Card
        title="Store Orders"
        background={false}
        value={totalStoreOrders.toLocaleString()}
      />
      <Card title="Store Views" value={storeViews.toLocaleString()} />
      <Card title="Product Count" value={productCount.toLocaleString()} />
      <div className="col-span-2 grid grid-cols-2 gap-4 sm:col-span-1">
        <div>
          <p className="mb-4 font-semibold text-dark-300 sm:text-lg">
            Products in Collection
          </p>
          <div>
            {collectionSales.length > 0 ? (
              collectionSales.map(product => (
                <Graph
                  key={product._id}
                  name={product.categoryName}
                  value={product.productCount}
                  total={totalSales}
                />
              ))
            ) : (
              <p className="text-center text-neutral-100">No data</p>
            )}
          </div>
        </div>
        <div>
          <p className="mb-4 font-semibold text-dark-300 sm:text-lg">
            Country with most orders
          </p>
          <div>
            <Graph name="Nigeria" value={nigerianOrders} total={totalOrders} />
            <Graph
              name="International"
              value={internationalOrders}
              total={totalOrders}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Graph = ({ name, value, total }) => {
  const numericValue = parseFloat(value ?? 0);
  const numericTotal = parseFloat(total ?? 0);
  const width = numericTotal > 0 ? (numericValue / numericTotal) * 100 : 0;

  return (
    <div>
      <div className="my-[0.3rem] flex justify-between">
        <p className="font-[500] text-neutral-100 sm:text-lg">{name}</p>
        <p className="font-[500] text-neutral-100 sm:text-lg">
          {numericValue.toLocaleString()}
        </p>
      </div>
      <div
        className="h-1.5 rounded-full bg-green-100"
        style={{ width: width + "%" }}
      />
    </div>
  );
};

const Card = ({ value, title, background = true, className }) => {
  return (
    <div
      className={cn(
        "rounded-lg border border-neutral-300 bg-white p-4 text-left text-dark-100 sm:h-36 sm:p-8",
        background && "bg-green-100 text-white",
        className,
      )}
    >
      <p className="text-sm font-medium">{title}</p>
      <p className="mb-2 mt-3 flex items-center justify-start text-2xl font-bold uppercase md:text-4xl lg:text-40">
        {value}
      </p>
    </div>
  );
};

export default DashboardPage;
