import { useState } from "react";
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "../../components/commons/tabs";
// import { DraftBody } from "../../components/order/DraftBody";
import { OrderBody } from "../../components/order/OrderBody";
import SEO from "../../components/commons/seo";

function OrdersPage() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="py-0 sm:py-9">
      <SEO title="Orders" />
      <TabGroup
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      >
        <div className="flex flex-wrap items-center justify-between gap-5 mb-6 sm:mb-8">
          <TabList>
            <Tab>All Orders</Tab>
            {/* <Tab>Drafts</Tab> */}
          </TabList>
        </div>
        <TabPanels>
          <TabPanel>
            <OrderBody />
          </TabPanel>
          {/* <TabPanel>
            <DraftBody />
          </TabPanel> */}
        </TabPanels>
      </TabGroup>
    </div>
  );
}

export default OrdersPage;
