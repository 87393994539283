import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import {
  EBookIcon,
  GroupedGreenDots,
  RightArrowIcon,
} from "../../constants/assets-constants";

const digibleProducts = [
  {
    name: "eBooks",
    icon: EBookIcon,
    content: `Sell your Ebooks Quickly and Easily with Digible`,
  },
  {
    name: "AudioBooks",
    icon: EBookIcon,
    content: `Upload and  Sell your Audio Books easily with no Hassle on Digible.`,
  },
  {
    name: "Videos",
    icon: EBookIcon,
    content: `Upload and Swll Your Video Courses/ guides On Digible with no hassle.`,
  },

  {
    name: "Documents",
    icon: EBookIcon,
    content: `Sell your Custom Documents on Digible in less than 5 minutes, all you have gto do is Sign up and upload`,
  },
  {
    name: "Graphics",
    icon: EBookIcon,
    content: `Sell Wallpapers, Graphica Arts, Digital Arts and all forms of Graphics Media on Digible`,
  },
  {
    name: "Software",
    icon: EBookIcon,
    content: `Sell your custom Software and Applications with Digible.`,
  },
  {
    name: "Physical Goods",
    icon: EBookIcon,
    content: `Sell any kind of physical products on Digible with logistics and fulfillments handled automatically.`,
  },
];

function SellProducts() {
  const navigate = useNavigate();

  return (
    <div className="w-full px-1/6 relative  flex-col lg:flex-row lg:items-center  justify-between py-10 text-left">
      <img
        src={GroupedGreenDots}
        className="hidden lg:block absolute left-0 -bottom-24"
        alt=""
      />
      <div className="mb-10 lg:mb-0 lg:px-0 w-full lg:w-1/55 !text-left ">
        <TitleComponent isMedium text="Sell Digital/Physical Products" />
        <ContentComponent text="Sell anything with our wide category of products you can sell and promote both Digital/Physical Products." />
        <div className="mt-10 lg:mt-20" />
        <ButtonComponent
          isBlack
          btnText="Create store"
          callToAction={() => navigate("/signup")}
        />
      </div>
      <div className="w-full">
        <div className="flex overflow-x-auto py-10">
          {digibleProducts.map((product, index) => {
            return (
              <div
                key={index}
                className="flex-none w-400 h-350 mx-2 rounded-md bg-neutral-150 box-shadow px-8 py-10"
              >
                <img
                  className="w-11 md:w-auto object-contain"
                  src={product.icon}
                  alt="e-book icon"
                />
                <h2 className="py-5 text-3xl font-semibold text-dark-100">
                  {product.name}
                </h2>
                <p className="text-base  leading-6 text-dark-100">
                  {product.content}
                </p>
                <Link to="/signup">
                  <img
                    className="mt-6 w-6 "
                    src={RightArrowIcon}
                    alt="e-book icon"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SellProducts;
