import { PlusIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import Button from "../../components/commons/button";
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "../../components/commons/tabs";
import CreateCustomerGroup from "./CreateCustomerGroup";
import CustomersTab from "./CustomersTab";
import SEO from "../../components/commons/seo";

function CustomersPage() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);

  return (
    <div className="py-0 sm:py-9">
      <SEO title="Customers" />
      <TabGroup
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      >
        <div className="mb-6 flex items-center justify-between gap-5 sm:mb-8">
          <TabList>
            <Tab>Customers</Tab>
            {/* <Tab>Groups</Tab> */}
          </TabList>
          {selectedIndex === 1 && (
            <>
              <Button
                onClick={openModal}
                variant="ghost"
                className="gap-1 text-xs font-semibold text-dark-100"
                size="sm"
              >
                <PlusIcon className="w-4 text-lg" />
                New group
              </Button>
              <CreateCustomerGroup isOpen={isOpen} setIsOpen={setIsOpen} />
            </>
          )}
        </div>
        <TabPanels>
          <TabPanel>
            <CustomersTab />
          </TabPanel>
          {/* <TabPanel>
            <CustomerGroupsTab />
          </TabPanel> */}
        </TabPanels>
      </TabGroup>
    </div>
  );
}

export default CustomersPage;
