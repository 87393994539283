import React from "react";

function Subscribe() {
  return (
    <div className="pt-14 md:pt-20 pb-20 mb:pb-40 px-0 md:px-1/10 bg-white">
      <h2 className="px-1/6 md:px-0 whitespace-nowrap font-semibold w-full sm:w-1/70 lg:w-1/2 text-base md:text-2xl text-neutral-100 leading-5 md:leading-7 text-left">
        Easiest way to sell your Products online, <br /> Start Earning Today.
      </h2>
      <div className="mt-6 md:mt-10 bg-dark-200 sm:rounded-md lg:rounded-15 px-5 md:px-16 py-10 ">
        <div className="flex flex-col lg:flex-row space-y-6 items-center justify-between ">
          <div className="text-left w-full lg:w-2/5">
            <h3 className="text-white font-medium text-lg lg:text-30 mb-6 lg:mb-8">
              Stay in the loop
            </h3>
            <p className="text-sm lg:text-base text-white lg:leading-6 pb-6">
              Subscribe to receive the latest news and updates about Digible. We
              promise not to spam you!{" "}
            </p>
          </div>
          <div className="bg-white w-full lg:w-96 pl-3 pr-2 py-2 rounded-lg flex justify-between">
            <input
              type="email"
              placeholder="Enter email address"
              className="border-none focus:border-0 w-3/5 outline-none text-10  md:text-13 placeholder:text-10 md:placeholder:text-13 text-neutral-100 placeholder:text-neutral-200  "
            />
            <button className="ml-onclick-form bg-dark-100 w-2/6 text-white text-10 md:text-sm rounded-md px-8/5 py-3 shadow-lg">
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
