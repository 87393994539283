import React from "react";
import { Cart } from "../../../components/marketplace/Cart";
import { Shipping } from "../../../components/marketplace/Shipping";
import SEO from "../../../components/commons/seo";

export const MarketPlaceCartPage = () => {
  return (
    <div className=" mx-[1.87rem]">
      <SEO title="MarketPlace Cart" />
      <Cart />
      <Shipping />
    </div>
  );
};
