import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../../components/commons/data-table";
import { Dot, NigerianFlag } from "../../../constants/assets-constants";
import { useGetCustomerOrders } from "../../../operations/queries";
import { useNavigate } from "react-router-dom";
import formatPrice from "../../../utils/formatPrice";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("orderReference", {
    cell: info => info.getValue(),
    header: "Order",
  }),
  columnHelper.accessor("createdAt", {
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    header: "Date Added",
  }),
  columnHelper.accessor("orderStatus", { header: "Fulfillment" }),
  columnHelper.accessor("paymentStatus", {
    header: "Payment Status",
    cell: info => (
      <div className="flex gap-2.5">
        <img src={Dot} alt="dot" />
        <p className="text-xs font-medium text-neutral-100 ">
          {info.getValue()}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor("orderAmount", {
    header: "Total",
    cell: info => formatPrice(info.getValue()),
  }),
  columnHelper.accessor("flag", {
    header: "",
    cell: () => <img src={NigerianFlag} className="h-4 w-6" alt="flag" />,
  }),
];

function CustomerOrders({ customer }) {
  const navigate = useNavigate();
  const { data: orders, isLoading } = useGetCustomerOrders(
    customer?.emailAddress,
  );

  return (
    <div className="spacey-4 rounded-md border border-neutral-200 p-4">
      <div className="mb-8">
        <h3 className="mb-2 text-xl font-semibold text-dark-100">
          Orders ({orders?.length ?? 0})
        </h3>
        <p className="text-xs text-neutral-100">
          An overview of Customer Orders
        </p>
      </div>
      {isLoading ? (
        <div className="grid h-20 place-items-center">
          <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />
        </div>
      ) : (
        <DataTable
          data={orders}
          columns={columns}
          handleRowClick={order => {
            navigate(`/orders/${order._id}`);
          }}
          pageIndex={0}
          pageSize={orders?.length ?? 0}
          totalCount={orders?.length ?? 0}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default CustomerOrders;
