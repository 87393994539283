import React from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import SearchInput from "./../../../components/commons/search-input";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../../components/commons/data-table";
import InviteUser from "../../../components/settings/InviteUser";
import { useState } from "react";
import SEO from "../../../components/commons/seo";

const columnHelper = createColumnHelper();

const data = [
  {
    name: "Uzumaki Naruto",
    email: "naruto@gmail.com",
    teamPermissions: "Approved",
    status: "approved",
  },
];

const columns = [
  columnHelper.accessor("name", {
    cell: info => info.getValue(),
    header: () => <span>Name</span>,
  }),
  columnHelper.accessor("email", {
    cell: info => <p>{info.getValue()}</p>,
    header: () => <span className="text-center md:text-left">Email</span>,
  }),
  columnHelper.accessor("teamPermissions", {
    header: () => "Team Permissions",
    cell: info => info.renderValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("status", {
    header: () => (
      <span className="text-center lg:text-left">Account Number</span>
    ),
    cell: info => <p>{info.getValue()}</p>,
    // footer: (info) => info.column.id,
  }),
];

const TheTeamPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="max-w-screen-lg py-0 mx-auto sm:py-9">
      <PageDisplay linkTitle="The Team" />
      <SEO title="The Team"/>
      <div className="w-full rounded-[0.42875rem] border-[1px] border-neutral-300 p-[2rem]">
        <PageTitle
          title="The Team"
          description="Manage users of Digible store"
          buttonTitle="Invite Users"
          onClick={() => setOpen(true)}
        />
        <div className="flex justify-between">
          <div className="flex gap-[0.3rem]">
            <div className="flex items-center">
              <p className=" text-[0.75rem] text-neutral-100">
                Team permissions:
              </p>
            </div>
            <select
              name="all"
              id="all"
              className="border-none py-0 pr-[1.8rem] text-[0.95rem] text-neutral-100"
            >
              <option value="all">All</option>
            </select>
            <select
              name="all"
              id="all"
              className="border-none py-0 pr-[1.8rem] text-[0.95rem] text-neutral-100"
            >
              <option value="all">Status</option>
            </select>
          </div>
          <div className="w-[10rem]">
            <SearchInput placeholder="Search" />
          </div>
        </div>
        <DataTable data={data} columns={columns} />
        <InviteUser isOpen={open} setIsOpen={setOpen} />
      </div>
    </div>
  );
};

export default TheTeamPage;
