import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import {
  CynthiaAvatar,
  EstherAvatar,
  HalfCircle,
} from "../../constants/assets-constants";

const customerLists = [
  {
    avatar: `${EstherAvatar}`,
    time: "Friday, 13th of October 2022 by 15:20PM",
    name: "Esther Azeta",
    testimony:
      "It’s been a smooth journey using digible for my business. It definitely has made selling my digital products hassle free. Highly recommended for every business out there.",
  },

  {
    avatar: `${CynthiaAvatar}`,
    time: "Monday, 6th of January 2022 by 1:10PM",
    name: "Cynthia Nwadiora",
    testimony:
      "Digible has created a platform where I can shop from seamlessly. I am so grateful for this smart innovation.",
  },
];

function CustomersComponent() {
  const navigate = useNavigate();

  return (
    <div className="relative py-10 md:py-16">
      <img
        className="absolute right-0 z-0 w-36 md:w-auto -top-1/6 md:bottom-0"
        src={HalfCircle}
        alt=""
      />
      <div className="relative z-20 flex flex-col-reverse w-full text-left px-1/6 md:flex-row md:justify-start md:items-center">
        <div className="mt-10 md:mt-0 w-full md:w-58 !text-dark-100 flex flex-col space-y-1">
          {customerLists.map((customer, index) => {
            return (
              <div
                key={index}
                style={{
                  boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.1)",
                }}
                className="w-full p-6 rounded-md bg-neutral-150 md:p-10"
              >
                <small className="flex justify-end mb-3 text-xs font-medium md:text-sm">
                  {customer.time}
                </small>
                <div className="flex items-start">
                  <img
                    className="object-contain w-12 h-12"
                    src={customer.avatar}
                    alt="customer avatar"
                  />
                  <div className="pr-0 ml-5 md:pr-16">
                    <h5 className="mb-3 text-base font-bold lg:text-xl">
                      {customer.name}
                    </h5>
                    <p className="text-xs sm:text-sm lg:text-lg lg:!leading-33">
                      {customer.testimony}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="md:ml-16">
          <TitleComponent isMedium text="500k+ Customers" />
          <ContentComponent text="See what our users are saying about Digible." />
          <div className="mt-10 lg:mt-20" />
          <ButtonComponent
            isBlack
            btnText="Create store"
            callToAction={() => navigate("/signup")}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomersComponent;
