import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React from "react";
import cn from "../../utils/cn";

const ButtonComponent = ({
  btnText,
  callToAction,
  isBlack,
  isGreen,
  hasWidth,
  fullWidth,
  loading,
  disabled,
  type,
  className,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={callToAction}
      className={cn(
        "inline-flex w-auto items-center justify-center gap-4 whitespace-nowrap rounded-md border border-dark-300 bg-white px-12 py-3 text-sm font-medium text-dark-300 shadow-lg",
        isBlack && "border-dark-100 bg-dark-100 text-white",
        isGreen && "border-green-100 bg-green-100 text-white",
        (disabled || loading) && "opacity-50",
        hasWidth && "w-full md:w-1/70",
        fullWidth && "w-full",
        className,
      )}
      disabled={loading || disabled}
      {...props}
    >
      {loading && <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />}
      {btnText}
    </button>
  );
};

export default ButtonComponent;
