import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import TitleComponent from "../../components/commons/title-component";
import { DigibleLogo } from "../../constants/assets-constants";
import AuthLayoutWrapper from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomInput from "../../components/commons/custom-inputs";
import { useLoginUserMutation } from "../../operations/mutations";
import BusyOverlay from "../../components/commons/busy-overlay";
import SEO from "../../components/commons/seo";

function LoginPage() {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("What is your email address?"),
    password: yup
      .string()
      .min(8, "Please enter a password that is longer than 8 characters")
      .required("Please enter your password"),
  });
  const { mutate: loginUser, isLoading } = useLoginUserMutation();

  const { errors, values, validateForm, handleSubmit, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: values => {
        validateForm(values).then(errors => {
          loginUser(values);
        });
      },
    });

  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="Login" />
      <AuthLayoutWrapper>
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>

        <div className="mt-10 text-left">
          <TitleComponent text="Welcome back" />
          <p className="text-xs leading-14 text-neutral-100 md:text-sm">
            Please sign into your Digible Account.
          </p>
        </div>

        {/* form */}

        <div className="mt-10 w-full text-left">
          <form onSubmit={handleSubmit}>
            <CustomInput
              type="email"
              id="email"
              name="email"
              values={values.email}
              placeholder="Enter your email address"
              error={Boolean(errors.email)}
              onChange={e => {
                handleChange(e);
              }}
              label="Email Address"
              errorMsg={errors.email}
            />
            <CustomInput
              type="password"
              value={values.password}
              id="password"
              onChange={e => {
                handleChange(e);
              }}
              name="password"
              error={Boolean(errors.password)}
              placeholder={"Enter password"}
              errorMsg={errors.password}
              label="Password"
            />
            <Link to="/forgot-password" className="text-xs text-neutral-200">
              Forgot password?
            </Link>
            <div className="mt-24 w-full">
              <ButtonComponent
                type="submit"
                isBlack
                fullWidth
                btnText="Sign in"
              />
            </div>
            <div className="mt-8 flex items-center space-x-1 text-xs font-medium text-neutral-100 md:text-sm">
              <p>Don't have an account yet? </p>
              <Link to="/signup" className="font-bold">
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </AuthLayoutWrapper>
    </>
  );
}

export default LoginPage;
