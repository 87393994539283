import Template1 from "../../assets/images/template-1.png";
import Template2 from "../../assets/images/template-2.png";
import Template3 from "../../assets/images/template-3.png";
import SEO from "../../components/commons/seo";
import Template from "./template";

const TEMPLATES = [
  { name: "Digible Supernova Template", price: 0, image: Template1 },
  { name: "Nebula Template", price: 5000, image: Template2 },
  { name: "Supernova Template", price: 7000, image: Template3 },
];

function StorefrontTemplatesPage() {
  return (
    <div className="p-4 -mt-10 text-dark-100">
      <SEO title="Storefront Templates"/>
      <div className="mb-10">
        <h1 className="mb-2 text-lg font-bold md:text-2xl">
          Select Store Front Template
        </h1>
        <p className="text-xs text-neutral-100">
          Customize Your Store with our selection of unique Templates
        </p>
      </div>
      <div className="flex flex-col gap-6">
        {TEMPLATES.map((template, index) => (
          <Template key={index} template={template} />
        ))}
      </div>
    </div>
  );
}

export default StorefrontTemplatesPage;
