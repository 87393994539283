import { PlusIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import {
  useGetCurrentUser,
  useGetStoreWithdrawal,
} from "../../operations/queries";
import formatPrice from "../../utils/formatPrice";
import BankAccount from "./BankAccount";
import WithdrawMoney from "./WithdrawMoney";

const WithdrawalHeader = () => {
  const { data: userData } = useGetCurrentUser();
  const { data: withdrawals } = useGetStoreWithdrawal({
    page: 1,
    pageSize: 10,
    search: "",
  });

  const balance = userData?.data?.data?.user?.balance ?? 0;

  const [isBankOpen, setIsBankOpen] = useState(false);
  const [isWithdrawalsOpen, setIsWithdrawalsOpen] = useState(false);

  return (
    <div>
      <div className="flex gap-[20px]">
        <button
          onClick={() => setIsBankOpen(true)}
          className="flex cursor-pointer gap-[2px] rounded-[0.375rem] border-[1px] border-[#D9D9D9] px-[0.8rem] py-[0.5rem] text-[1rem] font-medium text-[#1F1F1F]"
        >
          <PlusIcon className="h-[1.3125rem] w-[1.3125rem]" />
          Add Bank
        </button>
        <button
          className="flex cursor-pointer gap-[2px] rounded-[0.375rem] border-[1px] border-[#D9D9D9] bg-[#0FAA8E] px-[1.2rem] py-[0.5rem] text-[1rem] font-medium text-white"
          onClick={() => setIsWithdrawalsOpen(true)}
        >
          Withdraw
        </button>
      </div>
      <div className="mt-[2rem] flex flex-col gap-[10px] lg:flex-row">
        <Cards
          title="Total Balance"
          description="Available For Withdrawal"
          amount={balance}
          bgColor="bg-[#0FAA8E]"
          color="text-[#FFF]"
        />
        <Cards
          title="Total Balance"
          description="Available For Withdrawal"
          amount={0}
          currency="USD"
          bgColor="bg-[#FFF]"
          color="text-[#1F1F1F]"
        />
      </div>
      <div className="mt-[1rem] flex flex-col-reverse gap-[10px] lg:flex-row">
        <Cards
          title="Total Withdrawals"
          description="Withdrawal History"
          amount={0}
          currency="USD"
          bgColor="bg-[#FFF]"
          color="text-[#1F1F1F]"
        />
        <Cards
          title="Total Withdrawals"
          description="Withdrawal History"
          amount={withdrawals?.totalWithdrawals ?? 0}
          bgColor="bg-[#0FAA8E]"
          color="text-[#FFF]"
        />
      </div>
      <BankAccount isOpen={isBankOpen} setIsOpen={setIsBankOpen} />
      <WithdrawMoney
        isOpen={isWithdrawalsOpen}
        setIsOpen={setIsWithdrawalsOpen}
      />
    </div>
  );
};

export default WithdrawalHeader;

const Cards = ({ title, currency, color, amount, description, bgColor }) => {
  return (
    <div
      className={`mb-8 p-8 text-left text-white ${bgColor} border-[#DCDCDC)] w-full rounded-lg border-[1px] lg:w-1/48`}
    >
      <p className={`text-sm font-medium ${color}`}>{title}</p>
      <p
        className={`text-2xl ${color} mb-2 mt-3 flex items-center justify-start font-bold uppercase md:text-4xl lg:text-40`}
      >
        {formatPrice(amount, currency)}
      </p>
      <small className={`${color} text-10`}>{description}</small>
    </div>
  );
};
