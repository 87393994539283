import axios from "axios";
import { setRecoil } from "recoil-nexus";
import { userState } from "../recoil/atoms";

const BASE_URL = process.env.REACT_APP_API_URL || "https://api.example.com/";

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(
  req => {
    const userState = localStorage.getItem("userState");
    const state = JSON.parse(userState);
    if (!req.headers) return req;
    req.headers["x-access-token"] = state?.userState?.token || "null";
    return req;
  },
  err => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    const loginEndpoint = `${BASE_URL}/business/account/auth/login`;
    const isTokenInvalid = err.response?.data.message === "invalid token.";
    if (originalConfig?.url !== loginEndpoint && err.response) {
      if (isTokenInvalid && !originalConfig._retry) {
        originalConfig._retry = true;
        setRecoil(userState, null);
      }
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
