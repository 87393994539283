import React from "react";

function ShareIcon(props) {
  return (
    <svg
      width="22"
      height="30"
      viewBox="0 0 22 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
      {...props}
    >
      <path d="M2.9987 29.6663C2.26536 29.6663 1.63781 29.4055 1.11603 28.8837C0.593364 28.361 0.332031 27.733 0.332031 26.9997V12.333C0.332031 11.5997 0.593364 10.9717 1.11603 10.449C1.63781 9.92723 2.26536 9.66634 2.9987 9.66634H6.9987V12.333H2.9987V26.9997H18.9987V12.333H14.9987V9.66634H18.9987C19.732 9.66634 20.36 9.92723 20.8827 10.449C21.4045 10.9717 21.6654 11.5997 21.6654 12.333V26.9997C21.6654 27.733 21.4045 28.361 20.8827 28.8837C20.36 29.4055 19.732 29.6663 18.9987 29.6663H2.9987ZM9.66537 20.333V5.43301L7.53203 7.56634L5.66536 5.66634L10.9987 0.333008L16.332 5.66634L14.4654 7.56634L12.332 5.43301V20.333H9.66537Z" />
    </svg>
  );
}

export default ShareIcon;
