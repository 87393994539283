import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import PinInput from "../../components/commons/pin-input";
import SEO from "../../components/commons/seo";
import TitleComponent from "../../components/commons/title-component";
import { DigibleLogo } from "../../constants/assets-constants";
import AuthLayoutWrapper from "../../layouts/AuthLayout";

function VerifyPasswordCodePage() {
  const navigate = useNavigate();
  const email = localStorage.getItem("userEmail");

  return (
    <AuthLayoutWrapper>
      <SEO title="Verify Password" />
      <div className="w-full space-y-10 py-20 text-center">
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>
        <TitleComponent text="Verify your account" />
        <div className="w-full">
          <p className="mb-4 text-10 leading-14 text-neutral-100 md:text-xs">
            Please enter the code sent to <strong>{email}</strong>
          </p>
          <PinInput />
        </div>
      </div>
    </AuthLayoutWrapper>
  );
}

export default VerifyPasswordCodePage;
