import React from "react";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../icons";
import cn from "../../utils/cn";

function Breadcrumbs({ breadcrumbs, color }) {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="mb-6 flex list-none items-stretch gap-1 text-xs">
        {breadcrumbs.map((item, index) => (
          <li
            key={index}
            className={cn("flex items-center gap-1", {
              hidden: !item.name,
              "flex flex-1 items-center gap-1":
                index === breadcrumbs.length - 1,
            })}
          >
            <Link
              to={item.path}
              className={`${
                index === breadcrumbs.length - 1
                  ? "pointer-events-none max-w-[20ch] items-center gap-1 truncate whitespace-nowrap text-neutral-200"
                  : "flex max-w-[20ch] items-center gap-1 truncate whitespace-nowrap font-medium text-green-100 transition-colors hover:opacity-70"
              }`}
              style={{
                color: index === breadcrumbs.length - 1 ? undefined : color,
              }}
            >
              {item.name}
            </Link>
            {index !== breadcrumbs.length - 1 && (
              <ArrowIcon
                aria-labelledby={`aria-title-0${index} aria-description-0${index}`}
                titleId={`title-0${index}`}
                descriptionId={`description-0${index}`}
              />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
export default Breadcrumbs;
