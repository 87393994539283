import { Link, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import BusyOverlay from "../../../components/commons/busy-overlay";
import Filters from "../../../components/commons/filters";
import Pagination from "../../../components/commons/pagination";
import ProductCard from "../../../components/storefront/product-card";
import { NoImageAvailable } from "../../../constants/assets-constants";
import useDebounce from "../../../hooks/useDebounce";
import {
  useGetCollections,
  useGetProductList,
  useGetStorefront,
} from "../../../operations/queries";
import { MAX_PRICE } from "../../../utils/storefront";
import { pluralize } from "../../../utils/text";
import SEO from "../../../components/commons/seo";

const PAGE_SIZE = 13;

function CollectionPage() {
  const { storeUrl, collectionId } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: collectionsRes } = useGetCollections({ storeId: store?.id });
  const collections = collectionsRes?.data?.data;
  const collectionName = collections?.find(
    collection => collection.id === collectionId,
  )?.name;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") ?? 1;
  const search = searchParams.get("search");
  const startingPrice = searchParams.get("startingPrice");
  const endingPrice = searchParams.get("endingPrice") ?? MAX_PRICE;
  const debouncedSearch = useDebounce(search, 1000);
  const debouncedStartingPrice = useDebounce(startingPrice, 1000);
  const debouncedEndingPrice = useDebounce(endingPrice, 1000);
  const isPriceFiltered = debouncedStartingPrice && debouncedEndingPrice;

  const { data: response, isLoading } = useGetProductList({
    storeId: store?.id,
    search: debouncedSearch,
    collectionId,
    page,
    pageSize: PAGE_SIZE,
    ...(isPriceFiltered && {
      startingPrice: debouncedStartingPrice,
      endingPrice: debouncedEndingPrice,
    }),
  });

  const products = response?.data?.data;
  const pagination = response?.data?.pagination;

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: collectionName,
      path: `/${storeUrl}/collection/${collectionId}`,
    },
  ];

  return (
    <div className="mb-20">
      <SEO
        title={`${collectionName || "Collection"} | ${store?.storeName}`}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={store?.storeLogo}
      />
      <BusyOverlay loading={isLoading} />
      <div className="mb-6 flex flex-col justify-between bg-[#EDFFF5] md:h-72 md:flex-row">
        <div className="flex flex-col justify-between p-4 sm:pb-10">
          <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
          <Link
            to={`/${storeUrl}/product/${products?.[0]?.id}`}
            className="hover:underline"
          >
            <p className="text-xl font-medium text-dark-100 md:text-28">
              {products?.[0]?.productName}
            </p>
          </Link>
        </div>
        {products && products.length > 0 && (
          <Link
            to={`/${storeUrl}/product/${products?.[0]?.id}`}
            className="h-full w-full px-4 pb-4 sm:p-0 md:w-1/45"
          >
            <img
              src={
                products?.[0]?.productImages.length > 0
                  ? products?.[0]?.productImages?.[0]
                  : products?.[0]?.productImage || NoImageAvailable
              }
              alt="storefront"
              className="h-full w-full object-contain"
            />
          </Link>
        )}
      </div>

      <div className="p-4">
        <Filters />

        <p className="mb-10 text-base text-neutral-200 md:text-lg">
          {pluralize(pagination?.total, "product")}
        </p>

        <div className="mb-6 grid grid-cols-2 gap-4 sm:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {products?.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
        {pagination && (
          <Pagination
            currentPage={parseInt(pagination.page)}
            totalCount={pagination.total}
            pageSize={parseInt(pagination.pageSize)}
            onPageChange={page => setSearchParams(prev => ({ ...prev, page }))}
          />
        )}
      </div>
    </div>
  );
}

export default CollectionPage;
