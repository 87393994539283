import React from "react";
import { useRecoilValue } from "recoil";
import { useGetCollections } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import Button from "../commons/button";
import FormInput from "./../form/form-input";
import FormSelect from "./../form/form-select";
import FormTextarea from "./../form/form-textarea";
import WarningIcon from "./../icons/warning";

export const DigitalProductsSettings = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  ...Props
}) => {
  const { store } = useRecoilValue(userState);
  const collections = useGetCollections({ storeId: store.id });
  let options = collections?.data?.data?.data.map(c => {
    return { value: c.id, label: c.name };
  });
  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        General
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        To start selling, all you need is a name, price and image
      </p>
      <div className="my-[10px] flex justify-end md:my-[0px]">
        <Button type="submit">Save Details</Button>
      </div>
      <p className="mb-[0.94rem] text-[0.875rem] font-bold text-[#1F1F1F]">
        General
      </p>
      <p className="mt-[0.94rem] max-w-[22.5rem] text-[0.75rem] font-normal text-[#525252]">
        Give your product a short and clear name. 50-60 characters is the
        recommended length for search engines.
      </p>
      <div className="mb-[1.88rem] mt-[2.25rem] grid items-start gap-6 sm:grid-cols-2">
        <FormInput
          id="productName"
          name="productName"
          value={values.productName}
          placeholder="Shoes, Sunglasses..."
          error={Boolean(errors.productName)}
          onChange={handleChange}
          label="Name"
          errorMsg={errors.productName}
          isRequired
        />
      </div>
      <p className="mb-[1.5rem] max-w-[22.5rem] text-[0.75rem] font-normal text-[#525252]">
        Give your product a short and clear name. 120-160 characters is the
        recommended length for search engines.
      </p>
      <div className="mb-[1.88rem] mt-[2.25rem] grid items-start gap-6 sm:grid-cols-2">
        <FormTextarea
          id="productShortDescription"
          name="productShortDescription"
          value={values.productShortDescription}
          placeholder="Short description of the product..."
          error={Boolean(errors.productShortDescription)}
          onChange={handleChange}
          label="Description"
          errorMsg={errors.productShortDescription}
        />
        <FormSelect
          label="Collection"
          value={values.productCollection}
          openMenuOnFocus
          error={Boolean(errors.productCollection)}
          errorMsg={errors.productCollection}
          name="productCollection"
          onChange={selectedOption => {
            setFieldValue("productCollection", selectedOption ?? "");
          }}
          options={options}
        />
        <FormTextarea
          id="productLongDescription"
          name="productLongDescription"
          value={values.productLongDescription}
          placeholder="long description of the product..."
          error={Boolean(errors.productLongDescription)}
          onChange={handleChange}
          label="Description"
          errorMsg={errors.productLongDescription}
          className="h-[16.3rem]"
        />
      </div>
      <div className="mb-[1.44rem] mt-[1.44rem] flex gap-[2.4rem]">
        {/* <label className="text-[0.75rem] font-medium">
          <input
            type="checkbox"
            id="productSetting.discount.discountable"
            name="productSetting.discount.discountable"
            checked={values.productSetting.discount.discountable}
            onChange={handleChange}
            value={values.productSetting.discount.discountable}
            className="mr-[10px] rounded-[5px] text-[#0FAA8E]"
          />
          Discountable
          <span className="checkmark"></span>
          <WarningIcon className="mb-[2px] ml-[5px] inline h-[0.75rem] w-[0.75rem]" />
        </label> */}
        <label className="text-[0.75rem] font-medium">
          <input
            type="checkbox"
            id="productSetting.downloadable"
            name="productSetting.downloadable"
            checked={values.productSetting.downloadable}
            onChange={handleChange}
            value={values.productSetting.downloadable}
            className="mr-[10px] rounded-[5px] text-[#0FAA8E]"
          />
          Downloadable
          <span className="checkmark"></span>
          <WarningIcon className="mb-[2px] ml-[5px] inline h-[0.75rem] w-[0.75rem]" />
        </label>
      </div>
    </div>
  );
};
