const formatPrice = (value = 0, currency = "NGN", notation = "standard") => {
  const numericPrice =
    typeof value !== "string" && typeof value !== "number"
      ? 0
      : typeof value === "string"
      ? parseFloat(value)
      : value;

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    notation,
    maximumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(numericPrice);
};

export default formatPrice;
