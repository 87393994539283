import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NoImageAvailable } from "../../constants/assets-constants";
import { useUpdateCartMutation } from "../../operations/mutations";
import { useGetCart, useGetStorefront } from "../../operations/queries";
import cn from "../../utils/cn";
import formatPrice from "../../utils/formatPrice";
import { getDefaultVariants, updatedProducts } from "../../utils/storefront";
import Button from "../commons/button";
import IconButton from "../commons/icon-button";
import { ShoppingBagIcon } from "../icons";

function calculateDiscount(product) {
  const numericPrice = parseFloat(product.productPrice);
  const numericDiscount = parseFloat(product.productSetting.discount.amount);
  const discountedPrice = numericPrice - (numericPrice * numericDiscount) / 100;
  return formatPrice(discountedPrice, product.productCurrency);
}

function ProductCard({ product, className }) {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: cartRes } = useGetCart(store?.id);
  const { mutate: updateCart, isLoading: isUpdating } = useUpdateCartMutation();

  const handleAddToCart = () => {
    if (!store) return toast.error("Store not found");
    const cartId = localStorage.getItem(store.id);
    if (!cartId) return toast.error("Create a cart for this store");
    const cart = cartRes?.data?.data;
    if (!cart) return toast.error("Cart not found");
    const productToAdd = {
      productId: product.id,
      productVariants: getDefaultVariants(product.productVariants),
      quantity: 1,
    };

    updateCart({
      ...cart,
      id: cart._id,
      shipping: cart.shipping?.id || null,
      products: updatedProducts(cart, productToAdd),
    });
  };

  return (
    <div
      className={cn(
        "group relative flex h-full flex-col gap-2 text-base text-black md:text-22",
        className,
      )}
    >
      {product?.productSetting &&
        product.productSetting?.discount?.discountable && (
          <p className="absolute right-3 top-3 z-10 text-xs text-[#FF0606] sm:text-base">
            -{product.productSetting.discount.amount}%
          </p>
        )}
      <div className="relative h-56 grow overflow-hidden lg:h-64">
        <img
          src={
            product.productImages.length > 0
              ? product.productImages[0]
              : product.productImage || NoImageAvailable
          }
          alt="product"
          className="h-full w-full object-cover"
        />
        <Button
          onClick={handleAddToCart}
          className="absolute bottom-0 left-0 z-10 hidden w-full translate-y-28 rounded-none transition-transform focus:ring-offset-0 active:scale-100 group-hover:translate-y-0 sm:inline-flex"
          style={{ backgroundColor: store?.brandColor }}
          isLoading={isUpdating}
        >
          Add to cart
        </Button>
        <IconButton
          variant="black"
          onClick={handleAddToCart}
          disabled={isUpdating}
          icon={
            isUpdating ? (
              <ArrowPathIcon className="h-full w-full animate-spin" />
            ) : (
              <ShoppingBagIcon className="h-full w-full text-white" />
            )
          }
          description="Add to cart"
          className="absolute bottom-1 right-1 z-10 h-7 w-7 bg-black p-1 sm:hidden"
          style={{ backgroundColor: store?.brandColor }}
        />
      </div>
      <Link
        to={`/${storeUrl}/product/${product.id}`}
        className="before:absolute before:left-0 before:top-0 before:z-0 before:block before:h-full before:w-full hover:underline"
      >
        <p className="truncate text-sm font-medium md:text-2xl">
          {product.productName}
        </p>
      </Link>

      <div className="flex gap-2 text-xs md:text-base">
        <p className="flex items-center gap-1">
          {product?.productSetting &&
          product.productSetting?.discount?.discountable
            ? calculateDiscount(product)
            : formatPrice(product.productPrice, product.productCurrency)}
        </p>
        {product?.productSetting &&
          product.productSetting?.discount?.discountable && (
            <p className="relative flex items-center gap-1 text-neutral-200 after:absolute after:h-px after:w-full after:bg-neutral-200">
              {formatPrice(product.productPrice, product.productCurrency)}
            </p>
          )}
      </div>
    </div>
  );
}

export default ProductCard;
