import React, { useState } from "react";
import { ProductItems } from "./MarketPlaceBody";
import { ItemContainer } from "./ItemContainer";
import { useNavigate, generatePath } from "react-router-dom";

const MarketPlaceCategoryProducts = () => {
  const navigate = useNavigate();
  const [hoveractive, sethoveractive] = useState("");
  return (
    <div className="grid items-start gap-[2.31rem] grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mt-[1rem] mb-[1.88rem]">
      {ProductItems.map((pi, index) => {
        return (
          <ItemContainer
            image={pi.image}
            title={pi.name}
            id={pi.id}
            active={hoveractive}
            price={pi.price}
            key={index}
            onClick={() => {
              navigate(generatePath("/market-details/:id", { id: pi.id }));
              // navigate("/market-details");
            }}
            onHover={() => sethoveractive(pi.id)}
          />
        );
      })}
    </div>
  );
};

export default MarketPlaceCategoryProducts;
