import React from "react";
import {
  NoImageAvailable,
  ServiceImage,
} from "../../constants/assets-constants";

const ProductImages = ({ product }) => {
  return (
    <div className="mt-[2.81rem]">
      {product?.productImage && (
        <ProductCardThumbnail
          productName="Thumbnail"
          productImage={product?.productImage}
        />
      )}
      <ProductCardImages
        productImages={product?.productImages}
        productName={product?.productName}
      />
    </div>
  );
};

export default ProductImages;

const ProductCardThumbnail = ({ productName, productImage }) => {
  return (
    <div className="w-full rounded-[8px] border-[1px] border-solid border-[#E5E7EB] px-[2.06rem] py-[1.56rem] lg:w-[26rem]">
      <div className="space-y-2">
        <h2 className="text-[1.5rem] font-bold text-[#111827]">
          {productName}
        </h2>
      </div>
      {productImage === undefined ? (
        <img
          className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563ren] rounded-[8px] object-cover"
          alt="NoImage"
          src={NoImageAvailable}
        />
      ) : (
        <>
          {productImage.endsWith("jpg") ? (
            <img
              src={productImage}
              className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563ren] rounded-[8px] object-cover"
              alt="productImage"
            />
          ) : (
            <img
              src={ServiceImage}
              className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563ren] rounded-[8px] object-cover"
              alt="productImage"
            />
          )}
        </>
      )}
    </div>
  );
};

const ProductCardImages = ({ productImages, productName }) => {
  return (
    <div className="mb-[7.04rem] mt-[10px] w-full rounded-[8px] border-[1px] border-solid border-[#E5E7EB] px-[2.06rem] py-[1.56rem] lg:max-w-[53rem]">
      <div className="space-y-2">
        <h2 className="text-[1.5rem] font-bold text-[#111827]">
          {productName}
        </h2>
      </div>
      <div className="flex flex-wrap gap-[1.89rem]">
        {productImages?.map((image, i) => (
          <img
            key={i}
            src={image}
            className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563rem] rounded-[8px] object-cover"
            alt="productImage"
          />
        ))}
      </div>
    </div>
  );
};
