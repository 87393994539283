import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MAX_PRICE } from "../../utils/storefront";
import Chip from "./chip";
import Popover from "./popover";
import SearchInput from "./search-input";

function Filters() {
  const [filters, setFilters] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const startingPrice = searchParams.get("startingPrice") ?? 0;
  const endingPrice = searchParams.get("endingPrice") ?? MAX_PRICE;
  const search = searchParams.get("search") ?? "";

  const resetPage = () => {
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const handleSearch = value => {
    if (value) searchParams.set("search", value);
    else searchParams.delete("search");
    setSearchParams(searchParams);
    resetPage();
  };

  const handleStartingPrice = value => {
    if (parseInt(value)) searchParams.set("startingPrice", value);
    else searchParams.delete("startingPrice");
    setSearchParams(searchParams);
    resetPage();
  };

  const handleEndingPrice = value => {
    if (parseInt(value) === MAX_PRICE) searchParams.delete("endingPrice");
    else searchParams.set("endingPrice", value);
    setSearchParams(searchParams);
    resetPage();
  };

  const handleResetPrice = () => {
    searchParams.delete("startingPrice");
    searchParams.delete("endingPrice");
    setSearchParams(searchParams);
    resetPage();
  };

  const handleFilter = e => {
    if (filters.includes(e.target.name)) removeFilter(e.target.name);
    else setFilters(prev => [...prev, e.target.name]);
  };

  const removeFilter = filter => {
    setFilters(prev => prev.filter(f => f !== filter));
  };

  return (
    <div>
      <div className="mb-4 flex flex-col flex-wrap justify-between gap-4 md:flex-row">
        <div>
          <p className="mb-2 text-sm md:text-base">Filter:</p>
          <div className="flex flex-wrap items-end gap-4">
            {/* <Popover label="Availability" header="0 selected">
              <div>
                <input
                  id="inStock"
                  type="checkbox"
                  name="In stock"
                  onChange={handleFilter}
                  className="mr-2 rounded text-green-100"
                />
                <label htmlFor="inStock">In stock (41)</label>
              </div>
              <div>
                <input
                  id="outOfStock"
                  type="checkbox"
                  name="Out of stock"
                  onChange={handleFilter}
                  className="mr-2 rounded text-green-100"
                />
                <label htmlFor="outOfStock">Out of stock (6)</label>
              </div>
            </Popover> */}
            <Popover
              label="Price"
              header={`The highest price is ₦${MAX_PRICE.toLocaleString()}`}
              onReset={() => handleResetPrice()}
            >
              <div className="flex items-center justify-between gap-4 md:gap-10">
                <div className="flex flex-1 items-center gap-2 border border-neutral-400 p-2 focus-within:ring-1 focus-within:ring-blue-400">
                  <span className="text-gray-400">₦</span>
                  <input
                    type="number"
                    placeholder="0"
                    min="0"
                    max={MAX_PRICE}
                    step={1000}
                    className="flex-1 border-none p-0 focus:shadow-none focus:ring-0"
                    value={startingPrice}
                    onChange={e => handleStartingPrice(e.target.value)}
                  />
                </div>
                <p>to</p>
                <div className="flex flex-1 items-center gap-2 border border-neutral-400 p-2 focus-within:ring-1 focus-within:ring-blue-400">
                  <span className="text-gray-400">₦</span>
                  <input
                    type="number"
                    placeholder={MAX_PRICE}
                    min="0"
                    max={MAX_PRICE}
                    step={1000}
                    className="flex-1 border-none p-0 focus:shadow-none focus:ring-0"
                    value={endingPrice}
                    onChange={e => handleEndingPrice(e.target.value)}
                  />
                </div>
              </div>
            </Popover>
            {/* <Popover label="Product type" header="0 selected">
              <div>
                <input
                  id="beanie"
                  type="checkbox"
                  name="beanie"
                  onChange={handleFilter}
                  className="mr-2 rounded text-green-100"
                />
                <label htmlFor="beanie">beanie (5)</label>
              </div>
              <div>
                <input
                  id="blanket"
                  type="checkbox"
                  name="blanket"
                  onChange={handleFilter}
                  className="mr-2 rounded text-green-100"
                />
                <label htmlFor="blanket">blanket (6)</label>
              </div>
              <div>
                <input
                  id="book"
                  type="checkbox"
                  name="book"
                  onChange={handleFilter}
                  className="mr-2 rounded text-green-100"
                />
                <label htmlFor="book">book (2)</label>
              </div>
            </Popover> */}
            <SearchInput
              placeholder="Search for product"
              query={search}
              setQuery={handleSearch}
              containerClass="rounded-sm border border-neutral-400 px-3 ring-blue-400 focus-within:ring-1"
            />
          </div>
        </div>
        {/* <div>
          <p className="mb-2 text-sm md:text-base">Sort by:</p>
          <select className="form-select border-neutral-300 text-base text-dark-100 focus:ring-blue-400 md:text-lg">
            <option value="Featured">Featured</option>
            <option value="Best Selling">Best Selling</option>
            <option value="Alphabetically, A-Z">Alphabetically, A-Z</option>
            <option value="Alphabetically, A-Z">Alphabetically, Z-A</option>
            <option value="Price, low to high">Price, low to high</option>
            <option value="Price, high to low">Price, high to low</option>
            <option value="Date, old to new">Date, old to new</option>
            <option value="Date, new to old">Date, new to old</option>
          </select>
        </div> */}
      </div>
      {filters.length > 0 && (
        <div className="mb-4 flex flex-wrap gap-4">
          {filters.map(filter => (
            <Chip
              key={filter}
              className="hover:border-red-500 hover:bg-white hover:text-red-500"
              onClick={() => removeFilter(filter)}
              withIcon
            >
              {filter}
            </Chip>
          ))}
          <Chip
            variant="black"
            className="border-transparent hover:bg-red-500"
            onClick={() => setFilters([])}
          >
            Clear all
          </Chip>
        </div>
      )}
    </div>
  );
}

export default Filters;
