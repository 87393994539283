import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { TrashIcon } from "../../../components/icons";
import { EditIcon, Ellipses } from "../../../constants/assets-constants";
import { useDeleteShippingProfileMutation } from "../../../operations/mutations";
import formatPrice from "../../../utils/formatPrice";

const ShippingItem = ({ shipping, onEdit }) => {
  const { mutate: deleteShippingProfile } = useDeleteShippingProfileMutation();
  return (
    <div className="mb-[1rem] flex justify-between rounded-[0.375rem] border-[1px] border-neutral-300 px-[1.0rem] py-[1.8rem]">
      <div>
        <p className="text-sm font-semibold text-dark-100">{shipping.city}</p>
        <p className="mt-[0.1rem] flex gap-[0.2rem] text-sm text-dark-100">
          {formatPrice(shipping.amount, shipping.currency)}
        </p>
      </div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className=":outline-none inline-flex items-center justify-center rounded-md bg-transparent p-2 text-dark-100 transition-colors hover:bg-slate-200 hover:text-slate-900 focus:ring-2 focus:ring-slate-400 focus:ring-offset-1 disabled:pointer-events-none disabled:opacity-50">
            <img
              src={Ellipses}
              alt="ellipses"
              className="h-[1.5rem] w-[1.5rem]"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-[15rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 font-semibold">
              <Menu.Item>
                <button
                  onClick={onEdit}
                  className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-neutral-100 ui-active:bg-slate-100"
                >
                  <img
                    src={EditIcon}
                    alt="duplicate"
                    className="mr-2 h-5 w-5"
                  />
                  Edit Shipping Details
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => deleteShippingProfile(shipping.id)}
                  className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-red-500 ui-active:bg-slate-100"
                >
                  <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  Delete Shipping Details
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ShippingItem;
