import React from "react";

function FormTextarea(props) {
  return (
    <div className={props.className}>
      <div className="bg-[#F4F6F6] border border-neutral-400 rounded-md p-4 flex flex-col h-full">
        <div className="flex items-center gap-0.5 mb-2 text-neutral-100 text-xs font-medium">
          <label htmlFor={props.id}>{props.label}</label>
          {props.isRequired && (
            <span className="font-black text-[#FF0606]">*</span>
          )}
          {props.labelIcon}
        </div>
        <textarea
          className="bg-transparent text-xs p-0 text-neutral-200 disabled:text-neutral-200 border-none focus:ring-transparent w-full basis-full resize-none"
          name={props.name}
          onChange={props.onChange}
          id={props.id}
          value={props.value}
          aria-describedby={props.id}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          required={props.isRequired}
        />
        {!!props.errorMsg && (
          <small id={props.id} className="block mt-1 text-xs text-red-600">
            {props.errorMsg}
          </small>
        )}
      </div>
    </div>
  );
}

export default FormTextarea;
