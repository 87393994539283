import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import TitleComponent from "../../components/commons/title-component";
import { CreateStoreImg } from "../../constants/assets-constants";

const createStoreSteps = [
  {
    number: 1,
    step: "Create an account",
    story:
      "Sign up for an account with your name, email address and phone number",
  },

  {
    number: 2,
    step: "Verify your account",
    story:
      "Verify your account by inputting the authentication code sent to your email address or phone number.",
  },
  {
    number: 3,
    step: "Add products to store",
    story:
      "Start uploading your products available for sale and watch it get sold out.",
  },
];

function CreateStore() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #FFFFFF 0%, #EEFFFC 11.98%)",
      }}
      className="py-10 md:py-20 px-1/6"
    >
      <div className="flex flex-col-reverse lg:flex-row justify-between  items-center">
        <div className="w-full lg:w-1/45 mt-6 md:mt-0 ">
          <img className="object-contain w-full" src={CreateStoreImg} alt="" />
        </div>
        <div className=" w-full lg:w-1/2 text-left flex flex-col space-y-3">
          <TitleComponent text="Steps to creating your own store" />
          <div>
            {createStoreSteps.map((step, index) => {
              return (
                <div
                  key={index}
                  className="flex items-start space-x-8 md:space-x-12 mt-2 md:mt-4"
                >
                  <div className="flex flex-col space-y-2 md:space-y-4 items-center justify-center">
                    <span className="text-green-100 font-semibold text-xl md:text-2xl">
                      0{step.number}
                    </span>
                    {step.number !== 3 && (
                      <div className="h-16 md:h-24 border border-green-100"></div>
                    )}
                  </div>
                  <div>
                    <h6 className="mb-2 font-bold text-dark-100 text-base md:text-lg lg:text-26 leading-26 ">
                      {step.step}
                    </h6>
                    <p className="text-10 md:text-sm lg:text-17 tracking-wide md:leading-21 text-neutral-100">
                      {step.story}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="mt-10 lg:mt-20" />
            <ButtonComponent
              isBlack
              btnText="Create store"
              callToAction={() => navigate("/signup")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateStore;
