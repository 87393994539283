import React from "react";
import { Logo } from "../../constants/assets-constants";
import Button from "../../components/commons/button";
import SEO from "../../components/commons/seo";

function InvoicePage() {
  return (
    <div className="container mx-auto p-4 text-neutral-100">
      <SEO title="Invoice" />
      <div className="mb-10 flex items-center gap-6 sm:mb-20 sm:gap-10">
        <img
          src={Logo}
          alt="logo"
          className="w-[100px] object-contain sm:w-[213px]"
        />
        <div>
          <div className="mb-4 sm:mb-6">
            <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
              Invoice Number
            </h2>
            <p className="text-base sm:text-2xl">2S-MRGJ0</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
              Issued Date
            </h2>
            <p className="text-base sm:text-2xl">24 Jan, 22</p>
          </div>
        </div>
      </div>
      <div className="mb-10 grid flex-col justify-between gap-10 sm:grid-cols-2 sm:flex-row sm:gap-40">
        <div>
          <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
            From:
          </h2>
          <p className="text-base sm:text-2xl">
            23rd Street Studios 09045443455 Warri, Delta State
          </p>
        </div>
        <div>
          <div className="mb-10">
            <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
              Billed To:
            </h2>
            <p className="text-base sm:text-2xl">
              Obasanjo Studios services limited integrated Lokgoma, Federal
              Capital Territory
            </p>
          </div>
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
              Due Date:
            </h2>
            <p className="text-base sm:text-2xl">30th May 2023</p>
          </div>
        </div>
      </div>
      <div className="overflow-auto">
        <table className="w-full table-auto overflow-hidden rounded-lg shadow-[0_0_0_1px_#0FAA8E6B]">
          <thead>
            <tr className="bg-[#0FAA8E6B] text-left text-xl font-semibold text-dark-100 sm:text-3xl">
              <th className="whitespace-nowrap p-4">Item</th>
              <th className="whitespace-nowrap p-4">Quantity</th>
              <th className="whitespace-nowrap p-4">Price</th>
              <th className="whitespace-nowrap p-4">Subtotal</th>
            </tr>
          </thead>
          <tbody className="text-base sm:text-2xl">
            <tr>
              <td className="whitespace-nowrap p-4">Bag of Rice</td>
              <td className="whitespace-nowrap p-4">23</td>
              <td className="whitespace-nowrap p-4">$75,000.00</td>
              <td className="whitespace-nowrap p-4">$600,000.00</td>
            </tr>
            <tr className="bg-[#0FAA8E6B]">
              <td className="whitespace-nowrap p-4">T-Shirts</td>
              <td className="whitespace-nowrap p-4">30</td>
              <td className="whitespace-nowrap p-4">$35,500.00</td>
              <td className="whitespace-nowrap p-4">$300,000.00</td>
            </tr>
            <tr>
              <td className="whitespace-nowrap p-4">Flash Drives</td>
              <td className="whitespace-nowrap p-4">35</td>
              <td className="whitespace-nowrap p-4">$36,000.00</td>
              <td className="whitespace-nowrap p-4">$200,000.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mb-10">
        <div className="flex items-center justify-between border-b border-b-[#0FAA8E6B] p-4">
          <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
            Subtotal:
          </h2>
          <p className="text-base sm:text-2xl">$1,000,000.00</p>
        </div>
        <div className="flex items-center justify-between border-b border-b-[#0FAA8E6B] p-4">
          <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
            Vat:
          </h2>
          <p className="text-base sm:text-2xl">$75,000.00</p>
        </div>
        <div className="flex items-center justify-between border-b border-b-[#0FAA8E6B] p-4">
          <h2 className="text-xl font-semibold text-dark-100 sm:text-3xl">
            Total:
          </h2>
          <p className="text-base sm:text-2xl">$1,075,000.00</p>
        </div>
      </div>
      <div className="my-20 grid place-items-center">
        <Button>PAY HERE</Button>
      </div>
      <footer className="text-center text-base font-medium text-green-100 sm:text-2xl">
        Powered By Digible
      </footer>
    </div>
  );
}

export default InvoicePage;
