import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DigibleLogo, HamBurger } from "../../constants/assets-constants";
import { NavigationLinks } from "../../constants/nav-links";
import ButtonComponent from "../commons/buttons";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);
  return (
    <div className="bg-white shadow-lg md:shadow-none md:bg-green-50 w-full md:mt-5 flex justify-between items-center px-1/6 py-2 md:py-4">
      <Link to="/">
        <img
          className="w-40 lg:w-auto object-contain"
          src={DigibleLogo}
          alt=""
        />
      </Link>
      <img
        onClick={() => setMobileView(!mobileView)}
        className="block md:hidden"
        src={HamBurger}
        alt=""
      />

      {mobileView && (
        <div className="absolute top-1/10 z-100 left-0 w-full px-6 ">
          <div className="flex flex-col justify-start items-end space-y-4 rounded-md border border-green-100 bg-white shadow-lg p-6">
            {NavigationLinks.map((nav, index) => {
              return (
                <Link
                  onClick={() => setMobileView(false)}
                  key={index}
                  to={nav.link}
                >
                  <p className="text-dark-100 text-13 font-semibold">
                    {nav.name}
                  </p>
                </Link>
              );
            })}
            <ButtonComponent
              type="button"
              isBlack
              btnText="Sign in"
              callToAction={() => navigate("/login")}
            />
          </div>
        </div>
      )}
      <div className="hidden md:flex justify-center items-center space-x-8 lg:space-x-14">
        {NavigationLinks.map((nav, index) => {
          return (
            <Link key={index} to={nav.link}>
              <p className="text-dark-100 text-xs lg:text-13 font-semibold">
                {nav.name}
              </p>
            </Link>
          );
        })}
        <ButtonComponent
          type="button"
          isBlack
          btnText="Sign in"
          callToAction={() => navigate("/login")}
        />
      </div>
    </div>
  );
};

export default HeaderComponent;
