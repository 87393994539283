import React from "react";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "swiper/css";
import "swiper/css/pagination";
import { cartMarketplaceState } from "../../recoil/atoms";
import formatPrice from "../../utils/formatPrice";

export const Cart = () => {
  const cart = useRecoilValue(cartMarketplaceState);

  // const handleRemove = () => {
  //   setCart((prev) =>
  //     prev.filter((item) => item.product.name !== Product.name)
  //   );
  //   toast.success("Product removed successfully");
  // };
  return (
    <div className="my-[3.87rem] flex flex-col gap-[2rem] rounded-[0.375rem] border-[1px] border-dark-100 px-[1.81rem] py-[2.51rem] lg:flex-row">
      <div className="min-w-0 flex-1 ">
        {cart.length < 1 ? (
          <div className="flex h-[100%] items-center justify-center">
            <p className="text-center text-[2rem] font-[600]">
              Images will be displayed when products are added to your cart
            </p>
          </div>
        ) : (
          <Swiper
            cssMode
            pagination
            mousewheel
            keyboard
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper"
          >
            {cart.map((c, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={c.Product.image}
                    alt="sneakers"
                    className="h-full  w-full rounded-[0.375rem] object-contain lg:object-cover"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
      <div className="flex-1">
        {cart.length < 1 ? (
          <div className="flex items-center justify-center"></div>
        ) : (
          <div className="h-[10rem] overflow-y-scroll">
            {cart.map((c, index) => {
              return (
                <CartInfo
                  name={c.Product.name}
                  qty={c.quantity}
                  cartItem={c}
                  key={index}
                />
              );
            })}
          </div>
        )}
        {/* <div className="border-[1px] border-neutral-300 my-[1rem]"></div> */}
        <div className="my-[2rem] flex flex-1 justify-between">
          <p className="text-[1.875rem] font-bold text-neutral-100">Subtotal</p>
          <p className="flex gap-[0.2rem] text-[1.875rem] font-medium text-neutral-100">
            {formatPrice(51000)}
          </p>
        </div>
        <div className="my-[1rem] flex justify-between gap-[1rem]">
          <div>
            <input
              type="text"
              placeholder="Discount Code"
              className="w-full rounded-[6px] border-[#CCCCCC] bg-[#F4F6F6] text-[0.75rem] font-normal text-[#898989] focus:border-transparent focus:shadow-none focus:outline-none xl:w-[37.635rem]"
            />
          </div>
          <button className="flex cursor-pointer items-center gap-[2px] rounded-[0.375rem] border-[1px] border-[#D9D9D9] px-[1.5rem] py-[0.2rem] text-[1rem] font-medium text-[#1F1F1F]">
            Apply
          </button>
        </div>
        <div className="my-[2rem] border-[1px] border-neutral-300"></div>
        <div className="my-[2rem] flex flex-1 justify-between">
          <p className="text-[1.875rem] font-bold text-neutral-100">Total</p>
          <p className="flex gap-[0.2rem] text-[1.875rem] font-medium text-neutral-100">
            {formatPrice(51000)}
          </p>
        </div>
      </div>
    </div>
  );
};

const CartInfo = ({ name, qty, cartItem }) => {
  const { Product } = cartItem;
  const setCart = useSetRecoilState(cartMarketplaceState);

  const handleRemove = () => {
    setCart(prev => prev.filter(item => item.Product.name !== Product.name));
    toast.success("Product removed successfully");
  };

  return (
    <div className="my-[1rem] mb-[1rem] border-b-[1px] border-neutral-300">
      <div className="flex flex-1 justify-between">
        <p className="max-w-[10rem] text-[1.875rem] font-bold text-neutral-100">
          {name}
        </p>
        <p className="flex gap-[0.2rem] text-[1.875rem] font-medium text-neutral-100">
          {formatPrice(51000)}
        </p>
      </div>
      <div className="mt-[3rem] flex justify-between">
        <p className="text-[1.875rem] font-medium text-neutral-100">
          Qty: {qty}
        </p>
        <div className="flex flex-col gap-[1.88rem] lg:flex-row">
          <div className="items-center">
            <p className="cursor-pointer text-[1.25rem] font-medium text-neutral-100 underline">
              Configure
            </p>
          </div>
          <div className="items-center" onClick={handleRemove}>
            <p className="cursor-pointer text-[1.25rem] font-medium text-neutral-100 underline">
              Remove
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
