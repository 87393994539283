import React, { useState } from "react";
import {
  Audio,
  CloseIcon,
  Coding,
  CosmeticsProducts,
  Couch,
  Courses,
  Default,
  Ebooks,
  HamBurger,
  Journal,
  Microphone,
  Netflix,
  Perfume,
  PhysicalGoods,
  Physics,
  Services,
  Video,
  VideoEditing,
} from "../../constants/assets-constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from "swiper";
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { ItemContainer } from "./ItemContainer";
import MarketPlaceCategoryProducts from "./MarketPlaceCategoryProducts";
import { ProductCategory } from "./ProductCategory";
import { useNavigate, generatePath } from "react-router-dom";

export const ProductItems = [
  {
    name: "Preorder Physical Product",
    price: "5000",
    id: "1223333",
    image: `${Perfume}`,
  },
  {
    name: "Subscription",
    price: "1000",
    id: "222222",
    image: `${CosmeticsProducts}`,
  },
  {
    name: "AudioBooks",
    price: "1000",
    id: "33333",
    image: `${Microphone}`,
  },
  {
    name: "JC Williams Courses",
    price: "7000",
    id: "124555",
    image: `${Coding}`,
  },
  {
    name: "Video",
    price: "7000",
    id: "234561",
    image: `${VideoEditing}`,
  },
  {
    name: "Percy’s Journals",
    price: "7000",
    id: "456666",
    image: `${Journal}`,
  },
  {
    name: "Joe’s Teachings",
    price: "7000",
    id: "23456",
    image: `${Physics}`,
  },
  {
    name: "A Time in Space",
    price: "7000",
    id: "23555",
    image: `${Couch}`,
  },
  {
    name: "Subscription",
    price: "7000",
    id: "12344",
    image: `${Netflix}`,
  },
];

const DailyDeals = [
  {
    name: "Sneakers",
    price: "5000",
    id: "567809",
    image: `${Default}`,
  },
  {
    name: "Sneakers",
    price: "5000",
    id: "567812",
    image: `${Default}`,
  },
  {
    name: "Sneakers",
    price: "5000",
    id: "567816",
    image: `${Default}`,
  },
];

const LimitedTimeDeals = [
  {
    name: "Sneakers",
    price: "5000",
    id: "56780911",
    image: `${Default}`,
    discount: "7500",
    percent: "12",
  },
  {
    name: "Sneakers",
    price: "5000",
    id: "56781222",
    image: `${Default}`,
    discount: "7500",
    percent: "12",
  },
  {
    name: "Sneakers",
    price: "5000",
    id: "56781678",
    image: `${Default}`,
    discount: "7500",
    percent: "12",
  },
];

const sidebarlinks = [
  {
    filter: "All",
    title: "All",
  },
  {
    filter: "Physical Product",
    title: "Physical Product",
  },
  {
    filter: "Audio",
    title: "Audio",
  },
  {
    filter: "Video",
    title: "Video",
  },
  {
    filter: "Graphics",
    title: "Graphics",
  },
  {
    filter: "Documents",
    title: "Ebooks",
  },
  {
    filter: "Services",
    title: "Services",
  },
  {
    filter: "Software",
    title: "Software",
  },
  {
    filter: "Tickets",
    title: "Tickets",
  },
];

export const MarketPlaceBody = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("All");
  const [sidebar, setSidebar] = useState(false);
  const [displayCategory, setDisplayCategory] = useState(false);
  const [hoveractive, sethoveractive] = useState("");
  const [product, setProduct] = useState("");

  const PopularCategories = [
    {
      name: "Ebooks",
      id: "56781",
      image: `${Ebooks}`,
      onClick: () => setDisplayCategory(true),
    },
    {
      name: "Courses",
      id: "56783",
      image: `${Courses}`,
      onClick: () => setDisplayCategory(true),
    },
    {
      name: "Physical Goods",
      id: "56780",
      image: `${PhysicalGoods}`,
      onClick: () => setDisplayCategory(true),
    },
    {
      name: "Video",
      id: "56782",
      image: `${Video}`,
      onClick: () => setDisplayCategory(true),
    },
    {
      name: "Audio",
      id: "56786",
      image: `${Audio}`,
      onClick: () => setDisplayCategory(true),
    },
    {
      name: "Services",
      id: "56785",
      image: `${Services}`,
      onClick: () => setDisplayCategory(true),
    },
  ];
  return (
    <div className="relative mt-[2rem] flex gap-[0rem] md:gap-[4.12rem]">
      <div className={sidebar ? "showmenu" : "hidemenu"}>
        <div className=" rounded-t-[0.375rem] bg-[#0FAA8E] px-[0.62rem] py-[0.62rem]">
          <p className="font-Metropolis-Medium px-[1.5rem] text-[1.25rem] text-[#fff]">
            Categories
          </p>
        </div>
        {sidebarlinks.map((sl, index) => {
          return (
            <NavigationBoxes
              title={sl.title}
              key={index}
              active={active}
              onClick={() => {
                setActive(sl.title);
                setDisplayCategory(true);
              }}
            />
          );
        })}
      </div>
      <div className="min-w-0 lg:w-fit">
        <div className="flex justify-between">
          <div className="flex gap-[1rem]">
            <img
              src={HamBurger}
              alt="hamburger"
              className="hamburger"
              onClick={() => setSidebar(true)}
            />
            <p className="text-[1.25rem] font-bold text-[#000]">Featured</p>
          </div>
          <img
            src={CloseIcon}
            className="close"
            alt="close"
            onClick={() => setSidebar(false)}
          />
        </div>
        <div className="my-[2rem] h-[22rem] rounded-[8px]">
          <Swiper
            cssMode
            loop
            pagination
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            {ProductItems.map((pi, index) => {
              return (
                <SwiperSlide key={index}>
                  <ItemContainer
                    image={pi.image}
                    title={pi.name}
                    id={pi.id}
                    active={hoveractive}
                    price={pi.price}
                    key={index}
                    onClick={() => {
                      navigate(
                        generatePath("/market-details/:id", { id: pi.id }),
                      );
                      // navigate("/market-details");
                    }}
                    onHover={() => sethoveractive(pi.id)}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {displayCategory ? (
          <MarketPlaceCategoryProducts />
        ) : (
          <div className="min-w-0">
            <p className="my-[1.5rem] text-[1.25rem] font-bold text-[#000]">
              Explore Popular Categories
            </p>
            <div className="mb-[1.88rem] mt-[1rem] grid grid-cols-2 items-start gap-4 sm:gap-[2.31rem] md:grid-cols-2 lg:grid-cols-3">
              {PopularCategories.map((pc, index) => {
                return (
                  <ProductCategory
                    image={pc.image}
                    title={pc.name}
                    onClick={pc.onClick}
                    key={index}
                  />
                );
              })}
            </div>
            <p className="my-[1.5rem] text-[1.25rem] font-bold text-[#000]">
              Daily Deals
            </p>
            <div className=" mb-[1.88rem] mt-[1rem]">
              <Swiper
                cssMode
                pagination
                loop
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                modules={[Navigation, Pagination, Autoplay]}
                className="mySwiper"
              >
                {DailyDeals.map((dd, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ItemContainer
                        image={dd.image}
                        title={dd.name}
                        id={dd.id}
                        active={hoveractive}
                        price={dd.price}
                        onHover={() => sethoveractive(dd.id)}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <p className="my-[1.5rem] text-[1.25rem] font-bold text-[#000]">
              Limited Time Deals
            </p>

            <div className="mb-[1.88rem] mt-[1rem]">
              <Swiper
                cssMode
                pagination
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                modules={[Navigation, Pagination, Autoplay]}
                className="mySwiper"
              >
                {LimitedTimeDeals.map((ltd, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ItemContainer
                        image={ltd.image}
                        title={ltd.name}
                        id={ltd.id}
                        active={hoveractive}
                        price={ltd.price}
                        percent={ltd.percent}
                        discount={ltd.discount}
                        onHover={() => sethoveractive(ltd.id)}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        )}
        <div className="mt-[4rem] flex justify-center">
          <div className="flex rounded-[0.375rem] border-[2px] border-[#0FAA8E]">
            <div className="px-[1rem] py-[0.62rem]">
              <p className="font-Metropolis-Medium text-[1rem] text-[#1f1f1f]">
                Previous
              </p>
            </div>
            <div className="flex w-[2rem] items-center justify-center bg-[#0FAA8E]">
              <p className="font-Metropolis-Medium text-[0.75rem] text-[#fff]">
                1
              </p>
            </div>
            <div className="flex w-[2rem] items-center justify-center">
              <p className="font-Metropolis-Medium text-[0.75rem] text-[#1F1F1F]">
                2
              </p>
            </div>
            <div className="px-[1rem] py-[0.62rem]">
              <p className="font-Metropolis-Medium text-[1rem] text-[#1f1f1f]">
                Next
              </p>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .hidemenu{
           position: static;
           width: 14.875rem;
           border-radius: 0.375rem;
        }

          .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
        }  
        
        span.swiper-pagination-bullet{
          width: 14px;
          height: 14px;
        }
        
        @media screen and (max-width: 768px){
          .hidemenu{
            transition: 0.5s;
            position: absolute;
            left: -300px;
            top: 0px;
            width: 0px;
            border-radius: 0.375rem;
            z-index:23;
            transform: translateX(0%)
          }

          .showmenu{
            width: 14.875rem;
            border-radius: 0.375rem;
            position:absolute;
            z-index: 25;
            transition: 0.5s;
          }
        }
        `}
      </style>
    </div>
  );
};

const NavigationBoxes = ({ title, onClick, active }) => {
  return (
    <div
      className={`relative z-40 cursor-pointer bg-[#FAFAFA] py-[0.62rem] pl-[0.62rem] 
       ${active === title ? "border-l-[4px] border-[#0FAA8E]" : "border-[0px]"}
      `}
      onClick={onClick}
    >
      <p
        className={`font-Metropolis-Regular whitespace-nowrap px-[1.5rem] text-[1.25rem] ${
          active === title ? "text-[#0FAA8E]" : "text-[#525252]"
        }`}
      >
        {title}
      </p>
    </div>
  );
};
