import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import CustomInput from "../../components/commons/custom-inputs";
import TitleComponent from "../../components/commons/title-component";
import { DigibleLogo } from "../../constants/assets-constants";
import AuthLayoutWrapper from "../../layouts/AuthLayout";
import { useFormik } from "formik";
import * as yup from "yup";
import BusyOverlay from "../../components/commons/busy-overlay";
import { useForgotPasswordMutation } from "../../operations/mutations";
import SEO from "../../components/commons/seo";

function ForgotPasswordPage() {
  const [, setLoading] = useState(true);
  const { mutate: resetPassword, isLoading } = useForgotPasswordMutation();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("What is your email address?"),
  });

  const { errors, values, validateForm, handleSubmit, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit: values => {
        validateForm(values).then(errors => {
          setLoading(false);

          resetPassword(values);
        });
      },
    });
  return (
    <div>
      <BusyOverlay loading={isLoading} />
      <SEO title="Forgot Password" />
      <AuthLayoutWrapper>
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>

        <div className="mt-10 text-left">
          <TitleComponent text="Forgot your password?" />
          <p className="text-xs leading-14 text-neutral-100 md:text-sm">
            Enter the email associated with your account.
          </p>
        </div>

        {/* form */}

        <div className="mt-10 w-full text-left">
          <form onSubmit={handleSubmit}>
            <CustomInput
              type="email"
              id="email"
              name="email"
              value={values.email}
              placeholder="Enter your email address"
              error={Boolean(errors.email)}
              onChange={e => {
                handleChange(e);
              }}
              label="Email Address"
              errorMsg={errors.email}
            />

            <div className="mt-14 w-full">
              <ButtonComponent type="submit" isBlack fullWidth btnText="Send" />
            </div>
            <div className="mt-8 flex items-center space-x-1 text-xs font-medium text-neutral-100 md:text-sm">
              <p>Don't have an account yet? </p>
              <Link to="/signup">
                <p className="font-bold">Sign Up</p>
              </Link>
            </div>
          </form>
        </div>
      </AuthLayoutWrapper>
    </div>
  );
}

export default ForgotPasswordPage;
