import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "../../components/commons/tabs";
import ProductBodyTable from "../../components/products/ProductBodyTable";
import {
  AddIcon,
  BlackPlusIcon,
  DigitalProductIcon,
  PhysicalProductIcon,
} from "../../constants/assets-constants";
import CollectionModal from "./../../components/products/CollectionModal";
import ExportProduct from "./../../components/products/ExportProduct";
import ProductsCollection from "./../../components/products/ProductsCollection";
import SEO from "../../components/commons/seo";

const ProductsPage = () => {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="py-0 sm:py-9">
      <SEO title="Products" />
      <TabGroup
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      >
        <div className="flex flex-wrap items-center justify-between gap-5 mb-6 sm:mb-8">
          <TabList>
            <Tab>Products</Tab>
            <Tab>Collection</Tab>
          </TabList>
          {selectedIndex === 0 ? (
            <div className="flex gap-2.5">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex items-center justify-center gap-[10px] rounded-md border bg-transparent px-[1rem] py-[0.7rem] text-dark-100 outline-none transition-colors hover:bg-slate-200 hover:text-slate-900 focus:ring-2 focus:ring-slate-400 focus:ring-offset-1 disabled:pointer-events-none disabled:opacity-50">
                    <img
                      src={BlackPlusIcon}
                      alt="ellipses"
                      className="h-[1rem] w-[1rem]"
                    />
                    <p className="whitespace-nowrap text-[0.75rem] font-[600] text-dark-100">
                      New Product
                    </p>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 right-auto w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:left-auto md:right-0">
                    <div className="px-1 py-1 font-semibold">
                      <Menu.Item>
                        <Link to="/products/physical-product">
                          <button className="flex items-center w-full px-2 py-2 text-sm rounded-md group text-dark-100 ui-active:bg-slate-100">
                            <img
                              src={PhysicalProductIcon}
                              alt="duplicate"
                              className="w-5 h-5 mr-2"
                            />
                            Physical Product
                          </button>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/products/digital-product">
                          <button className="flex items-center w-full px-2 py-2 text-sm rounded-md group text-dark-100 ui-active:bg-slate-100">
                            <img
                              src={DigitalProductIcon}
                              alt="duplicate"
                              className="w-5 h-5 mr-2"
                            />
                            Digital Product
                          </button>
                        </Link>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <ExportProduct
                isOpen={isOpenExport}
                setIsOpen={setIsOpenExport}
              />
            </div>
          ) : (
            <>
              <button
                className="flex items-center gap-[10px] whitespace-nowrap rounded-md border px-[0.75rem] py-[0.4375rem] "
                onClick={() => setIsOpenCategory(true)}
              >
                <img src={AddIcon} alt="add new" />
                <p className="text-sm font-bold">New Category</p>
              </button>
              <CollectionModal
                isOpen={isOpenCategory}
                setIsOpen={setIsOpenCategory}
              />
            </>
          )}
        </div>
        <TabPanels>
          <TabPanel>
            <ProductBodyTable />
          </TabPanel>
          <TabPanel>
            <ProductsCollection />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default ProductsPage;
