import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import BusyOverlay from "../../components/commons/busy-overlay";
import EditVariants from "../../components/products/EditVariants";
import { ImageUpload } from "../../components/products/ImageUpload";
import PhysicalProductsSettings from "../../components/products/PhysicalProductsSettings";
import { Price } from "../../components/products/Price";
import Stock from "../../components/products/Stock";
import { useAddProduct } from "../../operations/mutations";
import SEO from "../../components/commons/seo";

const validationSchema = yup.object().shape({
  productName: yup.string().required("Please enter the product name"),
  productShortDescription: yup
    .string()
    .required("Please enter a product description"),
  productPrice: yup.string().required("Please enter the product price"),
  productCurrency: yup.object().required("Please select a currency"),
  productSKU: yup.string().required("Please enter SKU number"),
  productQuantity: yup.string().required("Please enter quantity"),
});

const PhysicalProduct = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const { mutate: addProduct, isLoading } = useAddProduct();

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: "",
      productShortDescription: "",
      productLongDescription: "",
      productPrice: "",
      productCurrency: "",
      productQuantity: "",
      productType: "PHYSICAL",
      productLink: "",
      productSKU: "",
      productCollection: "",
      productVariants: [],
      productSetting: {
        discount: {
          discountable: false,
          amount: "10",
        },
        downloadable: false,
      },
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        const { productVariants, productCollection, productCurrency, ...rest } =
          values;
        // If the variantHeader is the same, then the variants should be merged into one
        const product = isOpen ? values : rest;
        addProduct({
          ...product,
          productImages,
          productCollection: !!productCollection
            ? productCollection.value
            : null,
          productCurrency: !!productCurrency ? productCurrency.value : null,
        });
      });
    },
  });

  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="Add Physical Product" />
      <form onSubmit={handleSubmit} className="space-y-8">
        <PhysicalProductsSettings
          values={values}
          errors={errors}
          isOpen={isOpen}
          setFieldValue={setFieldValue}
          setIsOpen={setIsOpen}
          handleChange={handleChange}
        />
        <Price
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <ImageUpload uploads={productImages} setUploads={setProductImages} />
        <Stock values={values} errors={errors} handleChange={handleChange} />
        <EditVariants
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </form>
    </>
  );
};

export default PhysicalProduct;
