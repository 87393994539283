import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { CloseIcon } from "../../constants/assets-constants";

const ExportOrder = ({ isOpen, setIsOpen }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[48.938rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-[1.25rem] font-bold text-[#1F1F1F]"
                >
                  Create Draft Order
                </Dialog.Title>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  className="absolute right-[20px] top-[20px] cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
                <p className=" mt-[0.75rem] font-[0.875rem] text-[#898989] ">
                  Initialize an export of your orders
                </p>
                <div className="mt-[20px] border-t-[1px] border-[#DCDCDC]"></div>
                <div className="mt-[10px] flex justify-end">
                  <div className="flex gap-[10px]">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="cursor-pointer font-[0.75rem] text-[#898989]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => window.print()}
                      className="w-[5.875rem] rounded-[6px] bg-[#0FAA8E] px-[1.563rem] py-[0.438rem] font-[0.75rem] text-[#ffffff]"
                    >
                      Export
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ExportOrder;
