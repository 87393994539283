import React from "react";
import SignUpFlow from "../components/signup-flow";
import { AuthManImg, DashboardSnap } from "../constants/assets-constants";

function AuthLayoutWrapper({ isSignUp, children }) {
  return (
    <div className="flex h-full  lg:h-screen lg:overflow-hidden">
      <div className="w-full mx-auto md:w-4/5 lg:w-1/2  bg-white overflow-scroll">
        <div className="py-10 md:py-20">
          <div className="px-6 sm:px-10 md:px-1/13 xl:px-1/20 w-full">
            {isSignUp ? <SignUpFlow /> : null}

            {children}
          </div>
        </div>
      </div>
      <div className=" w-1/2 h-screen hidden lg:block ">
        <img
          src={isSignUp ? AuthManImg : DashboardSnap}
          className="w-full h-screen  object-cover"
          alt=""
        />
      </div>
    </div>
  );
}

export default AuthLayoutWrapper;
