import React, { useState } from "react";
import {
  DigibleLogo,
  NotifyIcon,
  QuestionIcon,
} from "../../constants/assets-constants";
import { Link } from "react-router-dom";
import { SearchIcon } from "../icons";
import NotificationModal from "../header/NotificationModal";
import { CartIcon } from "../icons";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import IconButton from "../commons/icon-button";
import { cartMarketplaceState } from "../../recoil/atoms";

export const MarketplaceNavigation = ({ hideSidebar }) => {
  const cart = useRecoilValue(cartMarketplaceState);
  const [notification, setNotification] = useState(false);

  return (
    <div className="border-b-[1px] border-[#DCDCDC]">
      <div className="flex flex-col lg:flex-row py-[1rem] px-4 sm:px-[1.87rem] border-b-[1px] justify-between container mx-auto">
        <div className="flex gap-4 sm:gap-[3rem] justify-between lg:justify-start flex-1 flex-wrap">
          <Link to="/marketplace">
            <img className="object-contain h-11" src={DigibleLogo} alt="logo" />
          </Link>
          <div className="flex items-center gap-2 p-2 w-full sm:w-auto">
            <SearchIcon className="w-5 h-5 text-neutral-200" />
            <input
              placeholder="Search"
              className="text-sm border-none flex-1"
            />
          </div>
        </div>
        <div className="flex justify-end flex-1">
          <div className="flex mt-[20px] lg:mt-[0px] w-full md:w-[60%] lg:w-fit items-center justify-around lg:justify-start gap-[0rem] lg:gap-[1.88rem]">
            <Link className="relative h-7 sm:h-8" to="/marketplace/cart">
              <IconButton
                variant="ghost"
                text="Your Cart"
                icon={<CartIcon />}
                description="View your cart"
                className="inline-flex gap-1 p-0 lg:p-4 sm:w-auto font-Metropolis-Medium text-dark-100"
                textClassName="hidden sm:inline"
              />
              {cart.length > 0 && (
                <p className="absolute right-0 grid w-5 h-5 text-white bg-black rounded-full place-items-center text-10 sm:text-xs -top-2">
                  {cart.length}
                </p>
              )}
            </Link>
            <select className="text-xs border-none form-select sm:text-base">
              <option value="NGN">NGN</option>
            </select>
            <Link to="">
              <img src={QuestionIcon} alt="question" />
            </Link>
            <div onClick={() => setNotification(true)}>
              <img src={NotifyIcon} alt="question" className="cursor-pointer" />
            </div>
            <div className="flex items-center justify-center w-[1.7rem] h-[1.7rem] lg:w-[1.875rem] lg:h-[1.875rem] text-[0.875rem] text-white bg-green-100 rounded-[30px]">
              A
            </div>
          </div>
        </div>
        <NotificationModal isOpen={notification} setIsOpen={setNotification} />
      </div>
      <Outlet />
    </div>
  );
};
