import React from "react";
import VerificationInput from "react-verification-input";
import cn from "../../utils/cn";

const PinInput = ({ errors, ...props }) => {
  return (
    <div className="flex justify-center">
      <VerificationInput
        validChars="0-9"
        inputProps={{
          type: "tel",
          className:
            "focus:shadow-transparent focus:outline-none focus:ring-transparent",
        }}
        className=""
        classNames={{
          container: "w-full",
          character: cn(
            "grid h-12 w-12 place-items-center border-neutral-400 bg-white text-base text-dark-100 md:h-14 md:w-14 md:text-xl",
            errors && "border-red-500",
          ),
        }}
        autoFocus
        {...props}
      />
    </div>
  );
};

export default PinInput;
