import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import Button from "../../../components/commons/button";
import ButtonComponent from "../../../components/commons/buttons";
import SEO from "../../../components/commons/seo";
import CartItem from "../../../components/storefront/cart-item";
import { useUpdateCartMutation } from "../../../operations/mutations";
import {
  useGetCart,
  useGetShippingProfile,
  useGetStorefront,
} from "../../../operations/queries";
import formatPrice from "../../../utils/formatPrice";
import { pluralize } from "../../../utils/text";

function CartPage() {
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);
  const { storeUrl } = useParams();
  const { data, isFetching } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: cartRes } = useGetCart(store?.id);
  const cart = cartRes?.data?.data;
  const { data: response, isLoading } = useGetShippingProfile(store?.id);
  const shippingProfiles = response?.data?.data;
  const [shippingId, setShippingId] = useState(null);
  const { mutate: updateCart, isLoading: isUpdating } = useUpdateCartMutation();

  const handleIsLoading = isLoading => setIsUpdatingCart(isLoading);

  const total = useMemo(() => {
    if (!cart) return 0;
    const productsTotal = cart.products.reduce(
      (acc, value) => acc + parseFloat(value.totalPrice) * value.quantity,
      0,
    );
    if (cart.shipping) return productsTotal + parseFloat(cart.shipping.amount);
    return productsTotal;
  }, [cart]);

  const isDisabled =
    isFetching ||
    !cart ||
    cart.products.length === 0 ||
    isUpdatingCart ||
    isLoading ||
    (!!shippingProfiles?.length && !shippingId) ||
    isUpdating;

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: "Cart",
      path: `/${storeUrl}/cart`,
    },
  ];

  const clearCart = () => {
    setShippingId(null);
    updateCart({ ...cart, id: cart._id, shipping: null, products: [] });
  };

  useEffect(() => {
    if (!shippingId || cart?.shipping?.id === shippingId) return;
    updateCart({
      ...cart,
      id: cart._id,
      shipping: shippingId,
      products: cart.products.map(item => ({
        ...item,
        productId: item.productId.id,
      })),
    });
  }, [cart, shippingId, updateCart]);

  useEffect(() => {
    if (cart?.shipping?.id) setShippingId(cart.shipping.id);
  }, [cart?.shipping?.id]);

  return (
    <div className="mb-20 space-y-10 p-4">
      <SEO
        title={`Cart | ${store?.storeName}`}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={store?.storeLogo}
      />
      <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
      <h1 className="text-2xl font-semibold md:text-4xl">Shopping Cart</h1>
      <div className="flex items-center justify-between">
        <p className="text-lg md:text-2xl">
          {pluralize(cart?.products.length, "product")} in your cart
        </p>
        <Button
          variant="link"
          onClick={clearCart}
          disabled={isUpdating}
          className="h-auto w-auto p-0 text-lg font-normal text-inherit active:text-inherit md:text-2xl"
        >
          Clear cart
        </Button>
      </div>
      <div className="flex flex-col gap-6">
        {cart?.products.map(product => (
          <CartItem
            key={
              product.productVariants.length === 0
                ? product.productId.id
                : product.productId.id +
                  product.productVariants.reduce(
                    (acc, curr) => acc.variantName + curr.variantName,
                    "",
                  )
            }
            product={product}
            cart={cart}
            handleIsLoading={handleIsLoading}
          />
        ))}
      </div>
      {!!shippingProfiles?.length && (
        <div>
          <h2 className="mb-2 text-lg md:text-2xl">Select shipping</h2>
          <div className="space-y-2 text-base md:text-xl">
            {shippingProfiles.map(shipping => (
              <div key={shipping.id} className="flex items-center gap-2">
                <input
                  type="radio"
                  name="shipping-profile"
                  id={shipping.id}
                  value={shipping.id}
                  onChange={e => setShippingId(e.target.value)}
                  checked={shippingId === shipping.id}
                  disabled={isUpdating}
                  className="disabled:opacity-50"
                />
                <label htmlFor={shipping.id}>
                  {shipping.city}, {shipping.state} -{" "}
                  {formatPrice(shipping.amount, shipping.currency)}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col items-center justify-between gap-4 text-dark-100 md:flex-row">
        <div className="w-full md:w-auto">
          <p className="mb-4 flex items-center gap-2 text-lg font-medium md:text-3xl">
            Total:
            <span className="flex items-center gap-1 font-semibold">
              {formatPrice(total)}
            </span>
          </p>
        </div>
        <div className="w-full md:w-[313px]">
          <Link
            to={`/${storeUrl}/checkout`}
            aria-disabled={isDisabled}
            className={isDisabled ? "pointer-events-none block" : "block"}
          >
            <ButtonComponent
              type="button"
              isBlack
              fullWidth
              btnText="Check Out"
              className="border-transparent"
              disabled={isDisabled}
              style={{ backgroundColor: store?.brandColor }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CartPage;
