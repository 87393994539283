import { React } from "react";
import { TitleAndContent } from "./TitleAndContent";

export const AdditionalDetails = ({ order }) => {
  return (
    <div className="w-full border-[1px] px-[1.563rem] py-[2.063rem] lg:w-[53rem]">
      <p className="text-[0.875rem] font-bold text-[#111827]">
        Additional Details
      </p>
      <TitleAndContent
        title="Shipping Address"
        content={order?.shipping?.address || "N/A"}
      />
      <TitleAndContent
        title="Postal Code"
        content={order?.shipping?.postalCode || "N/A"}
      />
      <TitleAndContent
        title="Other Info"
        content={order?.specialInstructions || "N/A"}
      />
    </div>
  );
};
