function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="currentcolor"
      {...props}
    >
      <g clipPath="url(#clip0_504_3071)">
        <path d="M4.16667 20.2497C3.70833 20.2497 3.31583 20.0863 2.98917 19.7597C2.66306 19.4336 2.5 19.0413 2.5 18.583V6.91634C2.5 6.45801 2.66306 6.06551 2.98917 5.73884C3.31583 5.41273 3.70833 5.24967 4.16667 5.24967H11.6042L9.9375 6.91634H4.16667V18.583H15.8333V12.7913L17.5 11.1247V18.583C17.5 19.0413 17.3369 19.4336 17.0108 19.7597C16.6842 20.0863 16.2917 20.2497 15.8333 20.2497H4.16667ZM13.4792 5.72884L14.6667 6.89551L9.16667 12.3955V13.583H10.3333L15.8542 8.06217L17.0417 9.22884L11.5208 14.7497C11.3681 14.9025 11.1911 15.0241 10.99 15.1147C10.7883 15.2047 10.5764 15.2497 10.3542 15.2497H8.33333C8.09722 15.2497 7.89944 15.17 7.74 15.0105C7.58 14.8505 7.5 14.6525 7.5 14.4163V12.3955C7.5 12.1733 7.54167 11.9613 7.625 11.7597C7.70833 11.5586 7.82639 11.3816 7.97917 11.2288L13.4792 5.72884ZM17.0417 9.22884L13.4792 5.72884L15.5625 3.64551C15.8958 3.31217 16.2953 3.14551 16.7608 3.14551C17.2258 3.14551 17.6181 3.31217 17.9375 3.64551L19.1042 4.83301C19.4236 5.15245 19.5833 5.54134 19.5833 5.99967C19.5833 6.45801 19.4236 6.8469 19.1042 7.16634L17.0417 9.22884Z" />
      </g>
      <defs>
        <clipPath id="clip0_504_3071">
          <rect
            width="20"
            height="20"
            fill="transparent"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditIcon;
