import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/commons/button";
import { useRef } from "react";
import { useUploadFileMutation } from "../../operations/mutations";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

const MAX_FILE_SIZE = 73400320; // 70MB

const File = ({ values, setFieldValue }) => {
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const controller = useRef(new AbortController());
  const { mutate: uploadFile, isError, data } = useUploadFileMutation();
  const isUploading = progress > 0 && progress < 100;

  const handleFile = e => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    if (files[0].size > MAX_FILE_SIZE) toast.error("File size exceeded");
    else {
      setFileName(files[0].name);
      setFile(files[0]);
      handleUpload(files[0]);
    }
    e.target.value = "";
  };

  const handleUpload = file => {
    uploadFile({
      file,
      cb: handleProgress,
      signal: controller.current.signal,
    });
  };

  const handleCancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
    setProgress(0);
    setFileName("");
  };

  const handleProgress = event => {
    const value = event.total
      ? Math.round((event.loaded / event.total) * 100)
      : 0;
    setProgress(value);
  };

  useEffect(() => {
    if (data?.data?.data) setFieldValue("productLink", data.data.data);
  }, [setFieldValue, data]);

  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        File
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        Upload your digital products file
      </p>

      <div className="relative m-8 flex h-[8.5rem] items-center justify-center border-[1px] border-dashed border-[#525252]">
        <input
          onChange={handleFile}
          ref={inputRef}
          type="file"
          hidden
          accept="file_extension|audio/*|video/*|image/*|media_type"
          // accept="image/*"
          className="absolute bottom-0 left-0 right-0 top-0 cursor-pointer opacity-0"
        />
        <p className="maxW-[15.063rem] text-center text-[0.625rem] font-medium text-[#525252]">
          Drop your messages here, or{" "}
          <span
            className="cursor-pointer text-[#0FAA8E]"
            onClick={() => inputRef.current.click()}
          >
            click to browse
          </span>{" "}
          <br />
          upload not more than 70MB each
        </p>
      </div>
      <div className="flex flex-col gap-1 text-sm text-dark-100 sm:flex-row sm:items-center sm:justify-between sm:text-base">
        {isUploading ? (
          <div className="mt-4 h-1.5 w-full rounded-full bg-gray-200">
            <div
              className="h-1.5 rounded-full bg-green-100"
              style={{ width: progress + "%" }}
            />
          </div>
        ) : progress === 100 && fileName ? (
          <div>
            <p className="break-all">{fileName}</p>
            {isError && (
              <div className="flex items-center gap-2 text-xs text-red-600">
                <button
                  onClick={() => handleUpload(file)}
                  type="button"
                  className="flex items-center gap-2 leading-none"
                >
                  Retry upload
                  <ArrowPathIcon className="h-4 w-4" />
                </button>
              </div>
            )}
          </div>
        ) : (
          <p></p>
        )}
        {isUploading ? (
          <Button
            type="button"
            onClick={handleCancel}
            variant="link"
            className="w-max whitespace-nowrap text-red-600"
            size="sm"
          >
            Cancel upload
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default File;
