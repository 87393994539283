import React from "react";
import { settingsLinks } from "../../constants/settings-links";
import SettingContainer from "../../components/settings/SettingContainer";
import SEO from "../../components/commons/seo";

const SettingsPage = () => {
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <SEO title="Settings" />
      <p className="text-[1.7rem] font-[600] text-dark-100">Settings</p>
      <p className="mb-[3.3rem] mt-[0.9rem] text-[0.8rem] text-neutral-100">
        Manage the settings for your Digible Store
      </p>
      <div className="mt-[1rem] grid grid-cols-1 gap-[1.81rem] lg:grid-cols-2">
        {settingsLinks.map((sl, index) => {
          return (
            <SettingContainer
              image={sl.image}
              title={sl.title}
              link={sl.link}
              key={index}
              description={sl.description}
              rightIcon={sl.rightIcon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SettingsPage;
