import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BusyOverlay from "../../../components/commons/busy-overlay";
import Button from "../../../components/commons/button";
import OrderInfo from "../../../components/order/OrderInfo";
import { OrderPictureAndName } from "../../../components/order/OrderPictureAndName";
import { ArrowLeft } from "../../../constants/assets-constants";
import { useGetOrderDetails } from "../../../operations/queries";
import SEO from "../../../components/commons/seo";

const OrderDetails = ({ innerRef }) => {
  const { orderId } = useParams();
  const orders = useGetOrderDetails(orderId);
  const extractedData = orders?.data?.data?.data;
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="sm:p-4" ref={componentRef}>
      <SEO title="Order Details" />
      <BusyOverlay loading={orders.isLoading} />
      <div className="flex justify-between mb-4">
        <Link to={-1}>
          <Button
            variant="ghost"
            className="gap-2 p-1 text-sm font-medium text-[#6B7280]"
          >
            <img src={ArrowLeft} alt="arrow-right" />
            Back to Order
          </Button>
        </Link>
        <Button variant="outline" onClick={() => handlePrint()}>
          Export Order Details
        </Button>
      </div>
      <OrderPictureAndName orders={extractedData} />
      <OrderInfo order={extractedData} />
    </div>
  );
};

export default OrderDetails;
