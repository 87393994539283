import React from "react";
import { DOTS, usePagination } from "../../hooks/usePagination";
import cn from "../../utils/cn";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import IconButton from "./icon-button";

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) return null;

  const onNext = () => onPageChange(currentPage + 1);

  const onPrevious = () => onPageChange(currentPage - 1);

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul
      className={cn(
        "my-4 flex w-full list-none justify-center gap-2 sm:my-6",
        className,
      )}
    >
      <li>
        <IconButton
          variant="ghost"
          icon={<ArrowLeftIcon className="w-[1.5rem]" />}
          description="Next page"
          className="p-2 text-sm sm:h-10 sm:w-10 sm:p-2 md:text-xl"
          disabled={currentPage === 1}
          onClick={onPrevious}
        />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              className="inline-flex h-7 w-7 items-center justify-center rounded-full text-center text-sm text-dark-100 sm:h-8 sm:w-8 md:text-2xl"
              key={index}
            >
              &#8230;
            </li>
          );
        }

        return (
          <li key={index}>
            <IconButton
              variant="ghost"
              icon={pageNumber}
              description="Next page"
              className={`p-2 text-sm sm:h-10 sm:w-10 sm:p-2 md:text-xl ${
                pageNumber === currentPage && "bg-slate-200"
              }`}
              onClick={() => onPageChange(pageNumber)}
            />
          </li>
        );
      })}
      <li>
        <IconButton
          variant="ghost"
          icon={<ArrowRightIcon className="w-[1.5rem]" />}
          description="Next page"
          className="p-2 text-sm sm:h-10 sm:w-10 sm:p-2 md:text-xl"
          disabled={currentPage === lastPage}
          onClick={onNext}
        />
      </li>
    </ul>
  );
};

export default Pagination;
