import React from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import FormInput from "./../../../components/form/form-input";
import ButtonContainer from "../../../components/settings/ButtonContainer";
import SEO from "../../../components/commons/seo";

const StoreDetailsPage = () => {
  return (
    <div className="max-w-screen-lg py-0 mx-auto sm:py-9">
      <PageDisplay linkTitle="Store Details" />
      <SEO title="Store Details" />
      <div className="w-full rounded-[0.42875rem]  border-[1px] border-neutral-300 md:w-[36rem]">
        <div className="p-4 sm:p-8">
          <PageTitle
            title="Store Details"
            description="Manage your business details"
            buttonTitle="Add details"
          />
          <p className="mb-[0.2rem] text-[0.75rem] font-[500] text-neutral-100">
            General
          </p>
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="Digible Store"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Store Name"
            // errorMsg={errors.billFrom}
            isRequired
            className="mb-[1rem]"
          />
          <p className="mb-[0.2rem] mt-[0.5rem] text-[0.75rem] font-[500] text-neutral-100">
            Advanced Settings
          </p>
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="https://"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Swap link template"
            // errorMsg={errors.billFrom}
            isRequired
            className="mb-[1rem]"
          />
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="https://"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Draft order link template"
            // errorMsg={errors.billFrom}
            isRequired
            className="mb-[1rem]"
          />
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="https://"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Invite link template"
            // errorMsg={errors.billFrom}
            isRequired
            className="mb-[1rem]"
          />
        </div>
        <ButtonContainer />
      </div>
    </div>
  );
};

export default StoreDetailsPage;
