import React from "react";
import { EyeIcon } from "../../constants/assets-constants";
import formatPrice from "../../utils/formatPrice";

export const ItemContainer = ({
  image,
  title,
  price,
  active,
  onClick,
  onHover,
  id,
  discount,
  percent,
}) => {
  return (
    <div>
      <div className="relative" onMouseOver={onHover}>
        <img
          src={image}
          alt="productimage"
          className="z-1 relative h-[12.125rem] w-full rounded-[0.375rem] object-cover"
        />
        <div
          className={`${active === id ? `hoverdisplay` : `hovernotdisplay`}`}
          onClick={onClick}
        >
          <div className="flex gap-[0.5rem]">
            <img src={EyeIcon} alt="eye" />
            <p className="font-Metropolis-Medium text-[1rem] text-[#fff]">
              View
            </p>
          </div>
        </div>
      </div>
      <p className="font-Metropolis-Medium my-[1.3rem] text-left text-[1rem] text-[#000] lg:my-[0.75rem]">
        {title}
      </p>
      <div className="flex gap-[0.5rem]">
        <p className="font-Metropolis-Medium text-[1rem] text-[#000]">
          {formatPrice(price)}
        </p>
      </div>
      {discount && percent !== "" && (
        <div className="my-[0.5rem] flex gap-[0.3rem]">
          <p className="font-Metropolis-Medium text-[1rem] text-neutral-200">
            <span className="line-through">{formatPrice(discount)}</span> |{" "}
            {percent}% OFF
          </p>
        </div>
      )}
      <style>
        {`
        .hovernotdisplay{
          background-color: transparent;
          display:none;
          z-index: 12;
        }
        .hoverdisplay{
          cursor: pointer;
          display: block;
          position: absolute;
          width: 100%;
          height: 12.1rem;
          border-radius: 0.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
         background: rgba(57, 57, 57, 0.5);
          z-index:12;
        }
        .hamburger{
          display: none;
          cursor: pointer;
        }

        .close{
          display: none;
          cursor: pointer;
        }
       @media screen and (max-width: 770px){
          .hovernotdisplay{
            width:100%;
          } 
          .hoverdisplay{
            width:100%;
            border-radius: 0.375rem;
          }
          .hamburger{
            display: block;
            cursor: pointer;
          }
          .close{
            display: block;
            cursor: pointer;
          }
             }
        `}
      </style>
    </div>
  );
};
