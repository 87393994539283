import { PlusIcon } from "@heroicons/react/20/solid";
import { WarningIcon } from "../icons";
import Variants from "./Variants";

const newVariant = {
  variantHeader: "",
  variants: [{ variantName: "", additionalPrice: "", variantQuantity: "" }],
};

const EditVariants = ({ values, handleChange, setFieldValue, isOpen }) => {
  const handleAdd = () => {
    setFieldValue("productVariants", [...values.productVariants, newVariant]);
  };

  const handleRemove = index => {
    const newArr = values.productVariants.filter((_, idx) => idx !== index);
    setFieldValue("productVariants", newArr);
  };

  if (!isOpen) return null;

  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <div>
        <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
          Variants
        </p>
        <p className="text-[0.75rem] font-normal text-[#525252]">
          Add variations of this product.
        </p>
        <p className="text-[0.75rem] font-normal text-[#525252]">
          Offer your customers different options for color. format, size, shape,
          etc.
        </p>
        <p className="my-4 flex text-[0.875rem] font-medium text-[#1F1F1F]">
          Product Option <WarningIcon className="ml-[10px]" />
        </p>
      </div>
      <div className="space-y-10">
        {values.productVariants.map((variant, index) => (
          <Variants
            key={index}
            index={index}
            variant={variant}
            handleChange={handleChange}
            handleRemove={handleRemove}
            values={values.productVariants}
            setFieldValue={setFieldValue}
          />
        ))}
      </div>
      <button
        type="button"
        className="mt-[1rem] flex w-[100%] items-center justify-center border-[1px] py-[0.5rem] text-[#1F1F1F]"
        onClick={handleAdd}
      >
        <PlusIcon className="mr-[10px] h-[1.3125rem] w-[1.3125rem] text-[0.75rem] text-[]" />
        Add an Option
      </button>
    </div>
  );
};

export default EditVariants;
