import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import NotificationModal from "../components/header/NotificationModal";
import { HamBurger } from "../constants/assets-constants";
import { SideBarLinks } from "../constants/sidebar-links";
import { userState } from "../recoil/atoms";
import cn from "../utils/cn";
import EmailModal from "./../components/header/EmailModal";
import { useQueryClient } from "react-query";
import { useGetSubscription } from "../operations/queries";

const SidebarLink = ({ to, children, onClick, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(userState);
  const isActive = location.pathname === to || location.pathname.includes(to);
  const queryClient = useQueryClient();
  useGetSubscription();

  const handleLogout = () => {
    setUser(null);
    queryClient.clear();
    navigate("/login");
  };

  return (
    <li>
      <Link
        to={to === "logout" ? "#" : to}
        onClick={() => {
          if (onClick) onClick();
          if (to === "logout") handleLogout();
        }}
        className={`block rounded px-4 py-3 hover:bg-gray-200  ${
          isActive ? "bg-gray-200" : ""
        }`}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
};

const WrapperLayout = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [notification, setNotification] = useState(false);
  const { user, store } = useRecoilValue(userState);
  const firstName = user.fullNames.split(" ")[0];
  const lastName = user.fullNames.split(" ")[1];
  const { pathname } = useLocation();
  const mainRef = useRef(null);

  useEffect(() => {
    // Scroll to top on route change
    if (mainRef.current) mainRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="fixed inset-0 flex bg-white text-black">
      {/* Overlay */}
      <div
        onClick={() => setIsOpen(false)}
        className={cn("fixed z-20 h-screen w-screen bg-black opacity-50", {
          hidden: !isOpen,
        })}
        style={{ height: "100dvh" }}
      />
      {/* Sidebar */}
      <div
        className={cn(
          "sidebar fixed z-20 h-screen overflow-auto border-r border-r-slate-300 bg-white transition-transform md:static md:flex-[0.27] md:translate-x-0",
          { "-translate-x-full": !isOpen },
        )}
        style={{ height: "100dvh" }}
      >
        <div className="flex items-center space-x-5 p-4 md:my-5">
          <div className="flex h-8 w-8 min-w-[2rem] items-center justify-center rounded-lg bg-black text-sm text-white">
            {store.storeName.charAt(0)}
          </div>
          <p className="truncate">{store.storeName}</p>
        </div>
        <ul className="flex flex-col space-y-2 p-4">
          {SideBarLinks.map((link, index) => {
            return (
              <SidebarLink
                to={link.path}
                key={index}
                onClick={() => setIsOpen(false)}
              >
                <div className="flex items-center gap-[10px]">
                  <img src={link.icon} alt="" className="h-5 w-5 " />
                  <p className="text-sm">{link.name}</p>
                </div>
              </SidebarLink>
            );
          })}
        </ul>
      </div>

      {/* Wrapper */}
      <div className="w-full bg-white md:w-auto md:flex-1">
        <header className="flex h-[4rem] items-center justify-between border-b border-neutral-300 px-1/3 py-4">
          <div className="flex gap-[10px]">
            <img
              src={HamBurger}
              alt="icon"
              onClick={() => setIsOpen(!isOpen)}
              className="block cursor-pointer lg:hidden"
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="grid h-8 w-8 place-items-center rounded-full bg-green-100 text-xs leading-none text-white">
              {`${firstName.charAt(0)}${lastName.charAt(0)}`}
            </div>
          </div>
        </header>
        {/* 4rem is the height of the header */}
        <main
          className="h-[calc(100dvh-4rem)] overflow-auto p-3 sm:p-4"
          ref={mainRef}
        >
          <Outlet />
          {children}
        </main>
      </div>
      <NotificationModal isOpen={notification} setIsOpen={setNotification} />
      <EmailModal isOpen={isEmail} setIsOpen={setIsEmail} />
    </div>
  );
};

export default WrapperLayout;
