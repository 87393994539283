import React, { useEffect, useRef, useState } from "react";
import { useUploadProductImagesMutation } from "../../operations/mutations";
import Button from "../commons/button";

export const UploadMultipleProductImages = ({ file, setUploads }) => {
  const [progress, setProgress] = useState(0);
  const controller = useRef(new AbortController());
  const { mutate: uploadMultipleFiles, data } =
    useUploadProductImagesMutation();
  const isUploading = progress > 0 && progress < 100;

  const handleCancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
    setProgress(0);
  };

  const handleProgress = event => {
    const value = event.total
      ? Math.round((event.loaded / event.total) * 100)
      : 0;
    setProgress(value);
  };

  useEffect(() => {
    uploadMultipleFiles({
      file,
      cb: handleProgress,
      signal: controller.current.signal,
    });
  }, [file, uploadMultipleFiles]);

  useEffect(() => {
    if (data) setUploads(prev => [...prev, data?.data?.data]);
  }, [data, setUploads]);

  return (
    <div className="mb-2 flex flex-col gap-1 text-sm text-dark-100 sm:flex-row sm:items-center sm:justify-between sm:text-base">
      {isUploading && (
        <div className="mt-4 h-1.5 w-full rounded-full bg-gray-200">
          <div
            className="h-1.5 rounded-full bg-green-100"
            style={{ width: progress + "%" }}
          />
        </div>
      )}
      {isUploading && (
        <Button
          type="button"
          onClick={handleCancel}
          variant="link"
          className="w-max whitespace-nowrap text-red-600"
          size="sm"
        >
          Cancel upload
        </Button>
      )}
    </div>
  );
};
