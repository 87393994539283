import React from "react";

export const ProductCategory = ({ image, title, onClick }) => {
  return (
    <div className="cursor-pointer " onClick={onClick}>
      <div>
        <img
          src={image}
          alt="productimage"
          className="rounded-[0.375rem] w-full object-cover h-[12.125rem] z-1 relative"
        />
      </div>
      <p className="text-[#000] text-[1rem] my-[1.3rem] lg:my-[0.75rem] font-Medium text-left">
        {title}
      </p>
    </div>
  );
};
