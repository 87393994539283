import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";

export const PageTitle = ({
  title,
  description,
  onClick,
  buttonTitle,
  displayPlus,
}) => {
  return (
    <div className="flex justify-between mb-[2rem]">
      <div>
        <p className="text-dark-100 text-[1.75rem] font-[600]">{title}</p>
        <p className="mt-[1rem] text-neutral-100 text-[0.75rem]">
          {description}
        </p>
      </div>
      {displayPlus && (
        <div
          className="flex gap-[0.3rem] cursor-pointer mt-[0.5rem] h-[20px]"
          onClick={onClick}
        >
          <PlusIcon className="w-[1.1rem] h-[1.1rem] text-neutral-100" />
          <p className="text-[0.75rem] text-neutral-100 font-[500] whitespace-nowrap">
            {buttonTitle}
          </p>
        </div>
      )}
    </div>
  );
};
