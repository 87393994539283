import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import BusyOverlay from "../../components/commons/busy-overlay";
import ButtonComponent from "../../components/commons/buttons";
import CustomInput from "../../components/commons/custom-inputs";
import PinInput from "../../components/commons/pin-input";
import SEO from "../../components/commons/seo";
import TitleComponent from "../../components/commons/title-component";
import { DigibleLogo } from "../../constants/assets-constants";
import AuthLayoutWrapper from "../../layouts/AuthLayout";
import { useResetPasswordMutation } from "../../operations/mutations";

function NewPasswordPage() {
  const paramEmail = localStorage.getItem("reset-password-email");

  const [loading, setLoading] = useState(true);
  const validationSchema = yup.object().shape({
    token: yup.string().required("Please enter verification code"),

    newPassword: yup
      .string()
      .min(8, "Please enter a password that is longer than 8 characters")
      .required("Please enter your password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });
  const { mutate: resetPassword, isLoading } = useResetPasswordMutation();

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      token: "",
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(errors => {
        setLoading(false);
        const data = {
          email: paramEmail,
          token: values.token,
          newPassword: values.newPassword,
        };
        resetPassword(data);
      });
    },
  });

  useEffect(() => {
    if (
      values.email &&
      values.confirmPassword &&
      values.newPassword &&
      values.token
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [values, loading]);
  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="New Password" />
      <AuthLayoutWrapper>
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>

        <div className="mt-10 text-center">
          <TitleComponent text="Create a new password" />
        </div>

        <div className="mt-10 w-full">
          <form onSubmit={handleSubmit}>
            <div className="mb-6 ">
              <p className="mb-4 text-center text-10 leading-14 text-neutral-100 md:text-xs">
                Please enter the code sent to <strong>{paramEmail}</strong>
              </p>
              <PinInput onChange={code => setFieldValue("token", code)} />
              <small
                id="phoneNumber"
                className="mt-1 block text-xs text-red-600 sm:mt-2 md:mt-3"
              >
                {errors.token}
              </small>
            </div>
            <CustomInput
              type="password"
              value={values.newPassword}
              id="newPassword"
              onChange={handleChange}
              name="newPassword"
              error={Boolean(errors.newPassword)}
              placeholder={"Enter new password"}
              errorMsg={errors.newPassword}
              label="New Password"
            />
            <CustomInput
              type="password"
              value={values.confirmPassword}
              id="confirmPassword"
              onChange={handleChange}
              name="confirmPassword"
              error={Boolean(errors.confirmPassword)}
              placeholder={"Confirm new password"}
              errorMsg={errors.confirmPassword}
              label="Confirm Password"
            />
            <div className="mt-14 w-full">
              <ButtonComponent isBlack fullWidth btnText="Update Password" />
            </div>
          </form>
        </div>
      </AuthLayoutWrapper>
    </>
  );
}

export default NewPasswordPage;
