import CustomerIcon from "../assets/images/customer.svg";
import DashboardIcon from "../assets/images/dashboard.svg";
import DiscountIcon from "../assets/images/discount.svg";
import InvoiceIcon from "../assets/images/invoice.svg";
import LogoutIcon from "../assets/images/logout.svg";
import OrderIcon from "../assets/images/order.svg";
import ProductIcon from "../assets/images/product.svg";
import SettingsIcon from "../assets/images/setting.svg";
import StoreIcon from "../assets/images/store.svg";
import WithdrawalIcon from "../assets/images/withdrawal.svg";

export const SideBarLinks = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    name: "Orders",
    icon: OrderIcon,
    path: "/orders",
  },
  {
    name: "Products",
    icon: ProductIcon,
    path: "/products",
  },
  {
    name: "Customers",
    icon: CustomerIcon,
    path: "/customers",
  },
  // {
  //   name: "Discounts",
  //   icon: DiscountIcon,
  //   path: "/discounts",
  // },
  {
    name: "Store management",
    icon: StoreIcon,
    path: "/store-management",
  },
  // {
  //   name: "Invoice Creator",
  //   icon: InvoiceIcon,
  //   path: "/invoice-creator",
  // },
  {
    name: "Withdrawals",
    icon: WithdrawalIcon,
    path: "/withdrawal",
  },
  {
    name: "Settings",
    icon: SettingsIcon,
    path: "/settings",
  },
  {
    name: "Logout",
    icon: LogoutIcon,
    path: "logout",
  },
];
