import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import Button from "../../components/commons/button";
import { useGetCollections } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import { FormInput, FormSelect, FormTextarea } from "../form";

export const EditPhysicalProductsSettings = ({
  values,
  errors,
  isOpen,
  setIsOpen,
  setFieldValue,
  handleChange,
}) => {
  const { store } = useRecoilValue(userState);
  const collections = useGetCollections({ storeId: store.id });

  const options = useMemo(() => {
    if (!collections?.data?.data?.data) return [];
    return collections.data.data.data.map(collection => ({
      value: collection.id,
      label: collection.name,
    }));
  }, [collections]);

  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        General
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        To start selling, all you need is a name, price and image
      </p>
      <div className="my-[10px] flex justify-end md:my-[0px]">
        <Button type="submit">Save Details</Button>
      </div>
      <p className="mb-[0.94rem] text-[0.875rem] font-bold text-[#1F1F1F]">
        General
      </p>
      <p className="mt-[0.94rem] max-w-[22.5rem] text-[0.75rem] font-normal text-[#525252]">
        Give your product a short and clear name. 50-60 characters is the
        recommended length for search engines.
      </p>
      <div className="mb-[1.88rem] mt-[2.25rem] grid items-start gap-6 sm:grid-cols-2">
        <FormInput
          id="productName"
          name="productName"
          value={values.productName}
          placeholder="Shoes, Sunglasses..."
          error={Boolean(errors.productName)}
          onChange={handleChange}
          label="Name"
          errorMsg={errors.productName}
          isRequired
        />
      </div>
      <p className="mb-[1.5rem] max-w-[22.5rem] text-[0.75rem] font-normal text-[#525252]">
        Give your product a short and clear name. 120-160 characters is the
        recommended length for search engines.
      </p>
      <div className="mb-[1.88rem] mt-[2.25rem] grid items-start gap-6 sm:grid-cols-2">
        <FormTextarea
          id="productShortDescription"
          name="productShortDescription"
          value={values.productShortDescription}
          placeholder="Short description of the product..."
          error={Boolean(errors.productShortDescription)}
          onChange={handleChange}
          label="Description"
          errorMsg={errors.productShortDescription}
        />
        <FormSelect
          label="Collection"
          value={values.productCollection}
          openMenuOnFocus
          error={Boolean(errors.productCollection)}
          errorMsg={errors.productCollection}
          name="productCollection"
          onChange={selectedOption => {
            setFieldValue("productCollection", selectedOption ?? "");
          }}
          options={options}
        />
        <FormTextarea
          id="productLongDescription"
          name="productLongDescription"
          value={values.productLongDescription}
          placeholder="long description of the product..."
          error={Boolean(errors.productLongDescription)}
          onChange={handleChange}
          label="Description"
          errorMsg={errors.productLongDescription}
          className="h-[16.3rem]"
        />
      </div>
      <label className="text-[0.75rem] font-medium">
        <input
          type="checkbox"
          id="productSetting.discount.discountable"
          name="productSetting.discount.discountable"
          value={values.productSetting.discount.discountable}
          onChange={handleChange}
          className="mr-[10px] rounded-[5px] text-[#0FAA8E]"
        />
        Discountable
        <span className="checkmark"></span>
      </label>
      <div className="mb-[1.44rem] mt-[1.44rem] flex gap-[2.4rem]">
        <div>
          <label id="container" className="text-[0.75rem] font-medium">
            <input
              type="radio"
              name="product"
              id="product"
              value={isOpen}
              checked={isOpen === false}
              className="mr-[10px] text-[#0FAA8E]"
              onChange={() => setIsOpen(false)}
            />
            Simple products
            <span id="checkmark"></span>
          </label>
        </div>
        <div>
          <label id="container" className="text-[0.75rem] font-medium">
            <input
              type="radio"
              name="product"
              value={isOpen}
              checked={isOpen === true}
              className="mr-[10px] text-[#0FAA8E]"
              onChange={() => setIsOpen(true)}
            />
            Products with variants
            <span id="checkmark"></span>
          </label>
        </div>
      </div>
      <style>
        {`
          /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          margin-right: 10px;
          border-radius: 6px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #0FAA8E;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        `}
      </style>
    </div>
  );
};
