import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import BusyOverlay from "../../components/commons/busy-overlay";
import { ImageUpload } from "../../components/products/ImageUpload";
import { EditPhysicalProductsSettings } from "../../components/products/EditPhysicalProduct";
import EditVariants from "../../components/products/EditVariants";
import { Price } from "../../components/products/Price";
import Stock from "../../components/products/Stock";
import { useUpdateProduct } from "../../operations/mutations";
import { useGetProductDetails } from "../../operations/queries";
import SEO from "../../components/commons/seo";

const validationSchema = yup.object().shape({});
const defaultProductSetting = {
  discount: { discountable: false, amount: "0" },
  downloadable: false,
};

export const EditProduct = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const { productId } = useParams();
  const { data, isLoading: isFetching } = useGetProductDetails(productId);
  const { mutate: updateProduct, isLoading } = useUpdateProduct();
  const product = data?.data?.data;

  useEffect(() => {
    if (!product) return;
    setProductImages(product.productImages);
    setIsOpen(!!product.productVariants.length);
  }, [product]);

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: product?.productName ?? "",
      productShortDescription: product?.productShortDescription ?? "",
      productLongDescription: product?.productLongDescription ?? "",
      productPrice: product?.productPrice ?? "",
      productQuantity: product?.productQuantity ?? "",
      productType: product?.productType ?? "",
      productLink: product?.productLink ?? "",
      productSKU: product?.productSKU ?? "",
      productCollection: product?.productCollection
        ? {
            value: product.productCollection._id,
            label: product.productCollection.name,
          }
        : "",
      productCurrency: product?.productCurrency
        ? { value: product.productCurrency, label: product.productCurrency }
        : "",
      productVariants: product?.productVariants ?? [],
      productSetting: product?.productSetting ?? defaultProductSetting,
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        const { productCollection, productCurrency } = values;
        const productVariants = isOpen ? values.productVariants : [];
        const payload = {
          ...values,
          productImages,
          productVariants,
          productCollection: !!productCollection
            ? productCollection.value
            : null,
          productCurrency: !!productCurrency ? productCurrency.value : null,
        };
        updateProduct({ id: productId, payload });
      });
    },
  });

  return (
    <>
      <BusyOverlay loading={isLoading || isFetching} />
      <SEO title="Edit Product" />
      <form onSubmit={handleSubmit} className="space-y-8">
        <EditPhysicalProductsSettings
          values={values}
          errors={errors}
          isOpen={isOpen}
          setFieldValue={setFieldValue}
          setIsOpen={setIsOpen}
          handleChange={handleChange}
        />
        <Price
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <ImageUpload uploads={productImages} setUploads={setProductImages} />
        <Stock values={values} errors={errors} handleChange={handleChange} />
        <EditVariants
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </form>
    </>
  );
};
