import React from "react";

const TitleComponent = ({ text, isLarge, isMedium, ...props }) => {
  return (
    <h2
      className={`mb-2 text-dark-100    md:mb-3
    ${
      isLarge
        ? "w-full text-2xl font-bold leading-9 md:text-40 md:leading-48 xl:text-50 xl:leading-77"
        : isMedium
        ? "text-2xl font-bold leading-9 md:text-4xl md:leading-48 lg:text-40 "
        : "text-xl font-semibold leading-9 md:text-2xl lg:text-30"
    }
    `}
      {...props}
    >
      {text}
    </h2>
  );
};

export default TitleComponent;
