import React, { useState, Fragment } from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import { DuplicateIcon, Ellipses } from "../../../constants/assets-constants";
import { Menu, Transition } from "@headlessui/react";
import { SelectContainer } from "../../../components/settings/SelectContainer";
import FormInput from "../../../components/form/form-input";
import ButtonContainer from "../../../components/settings/ButtonContainer";
import { TrashIcon } from "../../../components/icons";
import SEO from "../../../components/commons/seo";

const ReturnReasonsPage = () => {
  const [title, setTitle] = useState("");
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <PageDisplay linkTitle="" />
      <SEO title="Return Reasons" />
      <div className="flex flex-col gap-[1.2rem] md:flex-row">
        <div className="flex-1 rounded-[0.42875rem] border-[1px] border-neutral-300">
          <div className="p-4 sm:p-8">
            <PageTitle
              title="Return Reasons"
              description="Manage reasons for returned items"
              buttontitle="Add reasons"
            />
            <SelectContainer
              title="Wrong size"
              description="Customer wishes to replace the item due to a wrong size"
              state={title === "Wrong Size"}
              onClick={() => setTitle("Wrong Size")}
            />
          </div>
        </div>
        <div className="flex-1 rounded-[0.42875rem] border-[1px] border-neutral-300">
          <div className="p-4 sm:p-8">
            <div className="mb-[2rem] flex justify-between">
              <div>
                <p className="text-[1.75rem] font-[600] text-dark-100">
                  Details
                </p>
                <p className="mt-[1.5rem] text-[0.75rem] text-neutral-100">
                  wrong_size
                </p>
              </div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className=":outline-none inline-flex items-center justify-center rounded-md bg-transparent p-2 text-dark-100 transition-colors hover:bg-slate-200 hover:text-slate-900 focus:ring-2 focus:ring-slate-400 focus:ring-offset-1 disabled:pointer-events-none disabled:opacity-50">
                    <img
                      src={Ellipses}
                      alt="ellipses"
                      className="h-[1.5rem] w-[1.5rem]"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 font-semibold">
                      <Menu.Item>
                        <button className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-neutral-100 ui-active:bg-slate-100">
                          <img
                            src={DuplicateIcon}
                            alt="duplicate"
                            className="mr-2 h-5 w-5"
                          />
                          Duplicate Reason
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-red-500 ui-active:bg-slate-100">
                          <TrashIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Delete Reason
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <FormInput
              id="name"
              name="Name"
              // value={values.billFrom}
              placeholder="Wrong size"
              // error={Boolean(errors.billFrom)}
              // onChange={handleChange}
              label="Label"
              // errorMsg={errors.billFrom}
              isRequired
              className="mb-[1rem]"
            />
            <FormInput
              id="name"
              name="Name"
              // value={values.billFrom}
              placeholder="Wrong size"
              // error={Boolean(errors.billFrom)}
              // onChange={handleChange}
              label="Customer wishes to replace the item due to a wrong size"
              // errorMsg={errors.billFrom}
              isRequired
              className="mb-[1rem]"
            />
          </div>
          <ButtonContainer />
        </div>
      </div>
    </div>
  );
};

export default ReturnReasonsPage;
