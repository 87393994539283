import { Dialog, Transition } from "@headlessui/react";
import { Form, Formik, useFormik } from "formik";
import { Fragment, useEffect, useMemo } from "react";
import * as yup from "yup";
import { CloseIcon } from "../../constants/assets-constants";
import {
  useSaveBankDetailsMutation,
  useVerifyBankAccountMutation,
} from "../../operations/mutations";
import { useGetBankList } from "../../operations/queries";
import Button from "../commons/button";
import FormInput from "./../form/form-input";
import FormSelect from "./../form/form-select";

const validationSchema = yup.object().shape({
  bankCode: yup.object().required("Select your bank"),
  accountNumber: yup
    .string()
    .max(11, "Bank account number must not be more than 11 digits")
    .required("Please enter your account number"),
});

const BankAccount = ({ isOpen, setIsOpen }) => {
  const {
    data: accountResponse,
    mutate: verifyBankAccount,
    isLoading,
    reset,
  } = useVerifyBankAccountMutation();
  const accountName = accountResponse?.data?.data?.accountName;
  const {
    mutate: saveBankDetails,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useSaveBankDetailsMutation();

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: { bankCode: "", accountNumber: "" },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        const payload = { ...values, bankCode: values.bankCode.value };
        if (accountName) saveBankDetails({ ...payload, accountName });
        else verifyBankAccount(payload);
      });
    },
  });
  const { data: response, isLoading: isFetching } = useGetBankList(isOpen);

  const sortedList = useMemo(() => {
    if (!response?.data?.data) return [];
    const banks = response.data.data.map(bank => ({
      value: bank.bankCode,
      label: bank.bankName,
    }));
    return banks.sort((a, b) => a.label.localeCompare(b.label));
  }, [response]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
      reset();
    }
  }, [isOpen, reset, resetForm]);

  useEffect(() => {
    if (isSaved) setIsOpen(false);
  }, [isSaved, setIsOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <Formik>
          <Form onSubmit={handleSubmit}>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[48.938rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-[1.25rem] font-bold text-[#1F1F1F]"
                    >
                      Add a New Bank Account
                    </Dialog.Title>
                    <img
                      src={CloseIcon}
                      alt="close-icon"
                      className="absolute right-[20px] top-[20px] cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    />
                    <p className="my-[1.125rem] text-[0.875rem] font-bold text-[#1F1F1F]">
                      Add Bank For Cash Withdrawals
                    </p>
                    <FormSelect
                      name="bankCode"
                      label="Select Bank"
                      value={values.bankCode}
                      onChange={selectedOption => {
                        setFieldValue("bankCode", selectedOption ?? "");
                      }}
                      error={Boolean(errors.bankCode)}
                      errorMsg={errors.bankCode}
                      options={sortedList}
                      isLoading={isFetching}
                      isDisabled={isFetching}
                    />
                    <FormInput
                      id="accountNumber"
                      name="accountNumber"
                      value={values.accountNumber}
                      placeholder="Enter Account Number"
                      error={Boolean(errors.accountNumber)}
                      onChange={handleChange}
                      label="Enter Account Number"
                      errorMsg={errors.accountNumber}
                      className="my-[1.69rem]"
                    />
                    {accountName && (
                      <div>
                        <p className="text-[0.875rem] font-semibold text-[#1F1F1F]">
                          Account Name: {accountName}
                        </p>
                      </div>
                    )}
                    <div className="mb-[2.38rem] mt-[1.69rem] flex justify-center">
                      <Button type="submit" isLoading={isLoading || isSaving}>
                        {accountName ? "Save Details" : "Verify Details"}
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Form>
        </Formik>
      </Dialog>
    </Transition>
  );
};

export default BankAccount;
