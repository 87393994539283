function TrashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="currentcolor"
      {...props}
    >
      <path d="M5.83398 18C5.37565 18 4.98343 17.8369 4.65732 17.5108C4.33065 17.1842 4.16732 16.7917 4.16732 16.3333V5.5H3.33398V3.83333H7.50065V3H12.5007V3.83333H16.6673V5.5H15.834V16.3333C15.834 16.7917 15.6709 17.1842 15.3448 17.5108C15.0182 17.8369 14.6257 18 14.1673 18H5.83398ZM14.1673 5.5H5.83398V16.3333H14.1673V5.5ZM7.50065 14.6667H9.16732V7.16667H7.50065V14.6667ZM10.834 14.6667H12.5007V7.16667H10.834V14.6667ZM5.83398 5.5V16.3333V5.5Z" />
    </svg>
  );
}

export default TrashIcon;
