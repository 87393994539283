import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import { FormInput, FormTextarea } from "../../components/form";
import { WarningIcon } from "../../components/icons";

function InvoiceSettings({ values, errors, handleChange, ...props }) {
  return (
    <>
      <div className="p-4 mb-6 border rounded-lg border-neutral-300">
        <h1 className="mb-2 text-lg font-bold md:text-2xl">Invoice Settings</h1>
        <p className="mb-8 text-xs text-neutral-100">
          Customize a Payable Invoice
        </p>
        <h4 className="mb-2 text-sm font-semibold">General</h4>
        <p className="mb-4 text-xs text-neutral-100">
          Give your invoice a Number
        </p>
        <div className="grid gap-6 mb-6 sm:grid-cols-2">
          <FormInput
            id="invoiceNumber"
            name="invoiceNumber"
            value={values.invoiceNumber}
            placeholder="E.G.....2S-657JD"
            error={Boolean(errors.invoiceNumber)}
            onChange={handleChange}
            label="Invoice Number"
            errorMsg={errors.invoiceNumber}
            isRequired
          />
          <FormInput
            id="dateIssued"
            name="dateIssued"
            value={values.dateIssued}
            placeholder="EG.....24 Jan,23"
            error={Boolean(errors.dateIssued)}
            onChange={handleChange}
            label="Date Issued"
            errorMsg={errors.dateIssued}
            labelIcon={<WarningIcon className="inline" />}
          />
        </div>
        <p className="mb-4 text-xs text-neutral-100">
          Billed to Add Customers business details
        </p>
        <div className="grid gap-6 mb-6 sm:grid-cols-2">
          <FormTextarea
            id="billedTo"
            name="billedTo"
            value={values.billedTo}
            placeholder="EG.......Obasanjo Services Limited integrated Lokogoma, Federal Capital Territory"
            error={Boolean(errors.billedTo)}
            onChange={handleChange}
            label="Billed  To"
            errorMsg={errors.billedTo}
            className="h-40 sm:h-full"
          />
          <FormInput
            id="dueDate"
            name="dueDate"
            value={values.dueDate}
            placeholder="EG.....24 Jan,23"
            error={Boolean(errors.dueDate)}
            onChange={handleChange}
            label="Due Date"
            errorMsg={errors.dueDate}
            labelIcon={<WarningIcon className="inline" />}
          />
        </div>
        <div className="flex items-center justify-end gap-8">
          <Button>Share Invoice</Button>
          <Link to="/invoice-creator/view">
            <Button variant="outline">Preview Invoice</Button>
          </Link>
        </div>
      </div>
      <div className="p-4 mb-6 border rounded-lg border-neutral-300">
        <p className="mb-4 text-xs text-neutral-100">
          Add Your business details
        </p>
        <div className="grid items-start gap-6 sm:grid-cols-2">
          <FormTextarea
            id="billFrom"
            name="billFrom"
            value={values.billFrom}
            placeholder="EG.......Obasanjo Services Limited integrated Lokogoma, Federal Capital Territory"
            error={Boolean(errors.billFrom)}
            onChange={handleChange}
            label="Bill From"
            errorMsg={errors.billFrom}
            className="h-40"
          />
          <FormInput
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber}
            placeholder="E.G.....08010090100"
            error={Boolean(errors.phoneNumber)}
            onChange={handleChange}
            label="Phone Number"
            errorMsg={errors.phoneNumber}
            isRequired
          />
        </div>
      </div>
    </>
  );
}

export default InvoiceSettings;
