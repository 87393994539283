import { FormInput } from "../../components/form";

function Socials({ values, handleChange }) {
  return (
    <div className="mb-6 rounded-lg border border-neutral-300 p-4">
      <div className=" mb-4">
        <h2 className="mb-2 text-lg font-semibold md:text-2xl">Socials</h2>
        <p className="text-xs text-neutral-100">
          Enter social media handles to show on store footer
        </p>
      </div>
      <div className="grid gap-6 sm:grid-cols-2">
        {values.socials.map((social, index) => (
          <FormInput
            key={index}
            id={`socials[${index}].username`}
            name={`socials[${index}].username`}
            value={social.username}
            onChange={handleChange}
            label={`${social.platform} handle`}
            placeholder={`@${social.platform}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Socials;
