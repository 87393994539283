import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import FooterComponent from "../../components/footer";
import HeaderComponent from "../../components/header";
import SEO from "../../components/commons/seo";

const NotFoundPage = () => {
  return (
    <div className="flex h-screen flex-col">
      <SEO title="Not Found" />
      <HeaderComponent />
      <div className="flex flex-1 flex-col items-center justify-center gap-4 px-1/6 py-6">
        <h1 className="text-center text-2xl font-bold md:text-3xl">
          Oops the page you're looking for does not exist
        </h1>
        <Link to="/">
          <Button>Back to home</Button>
        </Link>
      </div>
      <FooterComponent />
    </div>
  );
};

export default NotFoundPage;
