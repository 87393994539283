import React, { useState } from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import { SelectContainer } from "../../../components/settings/SelectContainer";
import SEO from "../../../components/commons/seo";

const data = [
  { region: "Lagos", provider: "Payment providers: manual" },
  { region: "Outside Lagos", provider: "Payment providers: manual" },
];

const RegionsPage = () => {
  const [region, setRegion] = useState("");
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <PageDisplay linkTitle="Regions" />
      <SEO title="Region" />
      <div className="w-fulll rounded-[0.42875rem] border-[1px] border-neutral-300 p-[2rem] md:w-[36rem]">
        <PageTitle
          title="Regions"
          description="Manage the settings for your Digible Store"
          buttontitle="Add region"
        />
        {data.map((d, i) => {
          return (
            <SelectContainer
              key={i}
              title={d.region}
              description={d.provider}
              state={d.region === region}
              onClick={() => setRegion(d.region)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RegionsPage;
