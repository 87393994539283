import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "Digible | Online Stores Made Easy",
  description = "Create a free online store that helps you find customers, accept payments from anyone, and grow your digital business.",
  image = "https://digible.one/opengraph-image.png",
  alt = "Digible Landing Page",
  withoutTemplate = false,
  ...props
}) => {
  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={withoutTemplate ? undefined : "%s | Digible"}
      {...props}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={alt} />
      <meta property="og:url" content="https://digible.one/" />
      <meta property="og:site_name" content="Digible" />
      <meta property="og:type" content="website" />
      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={alt} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Digible6" />
      <meta name="twitter:creator" content="@ekesolonge" />
    </Helmet>
  );
};

export default SEO;
