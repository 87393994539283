import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import { FormInput, FormTextarea } from "../../components/form";
import { WarningIcon } from "../../components/icons";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";
import { useEffect, useRef, useState } from "react";
import { useUploadFileMutation } from "../../operations/mutations";
import { XMarkIcon } from "@heroicons/react/20/solid";

function StoreSettings({
  values,
  errors,
  handleChange,
  handlePublish,
  setFieldValue,
}) {
  const { store } = useRecoilValue(userState);
  const inputRef = useRef(null);
  const [preview, setPreview] = useState("");
  const [progress, setProgress] = useState(0);
  const controller = useRef(new AbortController());
  const isUploading = progress > 0 && progress < 100;

  const { mutate: uploadFile, data } = useUploadFileMutation();

  const handleFile = e => {
    const files = e.target.files;
    const prevLogo = values.storeLogo;
    if (!files || files.length > 1) return;
    if (prevLogo?.startsWith("blob")) URL.revokeObjectURL(prevLogo);
    setPreview(URL.createObjectURL(files[0]));
    handleUpload(files[0]);
    e.target.value = "";
  };

  const handleUpload = file => {
    uploadFile({
      file,
      cb: handleProgress,
      signal: controller.current.signal,
    });
  };

  const handleCancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
    setProgress(0);
    setPreview("");
  };

  const handleProgress = event => {
    const value = event.total
      ? Math.round((event.loaded / event.total) * 100)
      : 0;
    setProgress(value);
  };

  useEffect(() => {
    if (!data) return;
    setFieldValue("storeLogo", data.data.data);
  }, [data, setFieldValue]);

  return (
    <div className="mb-6 rounded-lg border border-neutral-300 p-3 sm:p-4">
      <h1 className="mb-2 text-lg font-bold md:text-2xl">Store Settings</h1>
      <p className="mb-8 text-xs text-neutral-100">Customize Your Store</p>
      <h4 className="mb-2 text-sm font-semibold">General</h4>
      <p className="mb-4 text-xs text-neutral-100">
        Give your store a unique name.
      </p>
      <div className="grid gap-6 sm:grid-cols-2">
        <FormInput
          id="storeName"
          name="storeName"
          value={values.storeName}
          placeholder="E.G.....Sunglass Hut"
          error={Boolean(errors.storeName)}
          onChange={handleChange}
          label="Store Name"
          errorMsg={errors.storeName}
          isRequired
          className="order-1"
        />
        <div className="order-3 grid min-h-[10rem] place-items-center sm:order-2 sm:row-span-2 sm:h-auto">
          {preview || values.storeLogo ? (
            <>
              <img
                src={preview || values.storeLogo}
                alt="logo"
                className="max-h-[180px] w-full object-contain"
              />
              {isUploading && (
                <div className="mt-2 flex w-full max-w-xs items-center justify-between gap-1">
                  <div className="h-1.5 w-full rounded-full bg-gray-200">
                    <div
                      className="h-1.5 rounded-full bg-green-100"
                      style={{ width: progress + "%" }}
                    />
                  </div>
                  <Button
                    type="button"
                    onClick={handleCancel}
                    variant="ghost"
                    className="h-auto w-auto p-1 text-red-600"
                    aria-label="Cancel upload"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="flex h-1/94 w-full items-center justify-center gap-1 rounded-md bg-neutral-450 text-xl font-semibold text-neutral-200 sm:w-1/70">
              <span className="text-3xl">+</span> Add Logo
            </div>
          )}
        </div>
        <FormInput
          id="uniqueLink"
          name="uniqueLink"
          value={values.uniqueLink}
          placeholder="http://digible.one/3estsrfs"
          error={Boolean(errors.uniqueLink)}
          onChange={handleChange}
          label="Unique Link"
          errorMsg={errors.uniqueLink}
          labelIcon={<WarningIcon className="inline" />}
          className="order-2 sm:order-3"
          disabled
        />
        <p className="order-5 text-xs text-neutral-100 sm:order-4">
          Give your Store a description/ details about your Business. 120-160
          characters is the recommended length for search engines.
        </p>
        <Button
          type="button"
          onClick={() => inputRef.current.click()}
          variant="outline"
          className="order-4 place-self-center text-sm sm:order-5"
        >
          Upload Image
        </Button>
        <input
          onChange={handleFile}
          ref={inputRef}
          type="file"
          hidden
          accept="image/*"
        />
        <FormTextarea
          id="storeDescription"
          name="storeDescription"
          value={values.storeDescription}
          placeholder="Short description of your business/store.."
          error={Boolean(errors.storeDescription)}
          onChange={handleChange}
          label="Description"
          errorMsg={errors.storeDescription}
          className="order-6 h-40 sm:row-span-2 sm:h-full"
        />
        <div className="order-7 flex flex-col gap-6">
          <FormInput
            id="customDomain"
            name="customDomain"
            value={values.customDomain}
            placeholder="Connect an Existing Domain Name eg www.digible.com"
            error={Boolean(errors.customDomain)}
            onChange={handleChange}
            label="Add Custom Domain"
            errorMsg={errors.customDomain}
          />
          <Button
            variant="ghost"
            className="gap-1 self-end text-xs font-semibold"
          >
            <span className="text-xl">+</span> Add Domain
          </Button>
        </div>
        <div className="order-8 flex items-center justify-center gap-8">
          <Button type="button" onClick={handlePublish}>
            Publish Store
          </Button>
          <Link to={`/${store.storeUrl}`} target="_blank">
            <Button type="button" variant="outline">
              View Store
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StoreSettings;
