import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/commons/buttons";
import TitleComponent from "../../../components/commons/title-component";
import { DigibleLogo } from "../../../constants/assets-constants";
import AuthLayoutWrapper from "../../../layouts/AuthLayout";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useResetRecoilState } from "recoil";
import * as yup from "yup";
import BusyOverlay from "../../../components/commons/busy-overlay";
import CustomInput from "../../../components/commons/custom-inputs";
import SEO from "../../../components/commons/seo";
import { useCreateUserMutation } from "../../../operations/mutations";
import {
  onBoardingNavigationState,
  onBoardingProcessState,
} from "../../../recoil/atoms";

function SignUserUp() {
  const { mutate: createUser, isLoading } = useCreateUserMutation();
  const resetOpenTab = useResetRecoilState(onBoardingNavigationState);
  const resetProcess = useResetRecoilState(onBoardingProcessState);
  const validationSchema = yup.object().shape({
    fullNames: yup.string().required("What is your name?"),
    phone: yup.string().required("What is your phone number?"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("What is your email address?"),
    password: yup
      .string()
      .min(8, "Please enter a password that is longer than 8 characters")
      .required("Please enter your password"),
  });

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    control,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullNames: "",
      phone: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(async errors => {
        createUser({ ...values, accountType: "business" });
      });
    },
  });

  const handlePhoneChange = value => {
    setFieldValue("phone", value);
  };

  useEffect(() => {
    localStorage.clear();
    resetOpenTab();
    resetProcess();
  }, []);

  return (
    <>
      <BusyOverlay loading={isLoading} />

      <AuthLayoutWrapper isSignUp>
        <SEO title="Sign Up" />
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>

        <div className="mt-10 text-left">
          <TitleComponent text="Create your account" />
          <p className="text-xs font-medium leading-14 text-neutral-100 md:text-sm">
            Easiest way to sell your Products online. Start <br /> Earning
            Today.
          </p>
        </div>

        <div className="mt-10 w-full text-left">
          <form onSubmit={handleSubmit}>
            <CustomInput
              type="text"
              id="fullNames"
              name="fullNames"
              value={values.fullNames}
              placeholder="Write your full name"
              error={Boolean(errors.fullNames)}
              onChange={e => {
                handleChange(e);
              }}
              label="Full Name"
              errorMsg={errors.fullNames}
            />

            <div className="mb-6 ">
              <label
                htmlFor="phone"
                className="form-label mb-1 text-left text-xs font-medium text-dark-100"
              >
                Phone Number
              </label>
              <div className="phone-input-container">
                <PhoneInput
                  id="phone"
                  name="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="NG"
                  value={values.phone}
                  onChange={handlePhoneChange}
                  rules={{ required: true }}
                  control={control}
                  className="phone-input"
                  inputClassName="phone-input-field"
                  buttonClassName="phone-input-button"
                  error={Boolean(errors.phone)}
                />
              </div>

              <small id="phone" className="mt-1 block text-xs text-red-600">
                {errors.phone}
              </small>
            </div>
            <CustomInput
              type="email"
              id="email"
              name="email"
              value={values.email}
              placeholder="Enter your email address"
              error={Boolean(errors.email)}
              onChange={e => {
                handleChange(e);
              }}
              label="Email Address"
              errorMsg={errors.email}
            />
            <CustomInput
              type="password"
              value={values.password}
              id="password"
              onChange={e => {
                handleChange(e);
              }}
              name="password"
              error={Boolean(errors.password)}
              placeholder={"Enter password"}
              errorMsg={errors.password}
              label="Password"
            />
            <div className="mt-16 w-full">
              <ButtonComponent isBlack fullWidth btnText="Sign up" />
            </div>
            <div className="mt-8 flex items-center space-x-1 text-xs font-medium text-neutral-100 md:text-sm">
              <p>Already have an account? </p>
              <Link to="/login" className="font-bold">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </AuthLayoutWrapper>
    </>
  );
}

export default SignUserUp;
