import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import React from "react";
import { EmailIcon } from "../../constants/assets-constants";
import Button from "./button";

function CustomInput(props) {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <div>
      <div className="mb-6 ">
        <label
          htmlFor={props.id}
          className="form-label mb-1 text-left text-xs font-medium text-dark-100 md:text-sm"
        >
          {props.label}
        </label>
        <div className="relative">
          {props.type === "email" ? (
            <div className="absolute left-0 top-0 flex h-full w-10 border border-transparent">
              <div className="z-10 flex h-full w-full items-center justify-center rounded-bl rounded-tl border-r bg-white text-lg text-gray-600">
                <img src={EmailIcon} alt="email" />
              </div>
            </div>
          ) : null}
          <input
            type={isShown ? "text" : props.type}
            className={`${
              props.type === "email" ? "pl-12" : ""
            } form-control m-0 block h-10 w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-10 text-dark-100 transition ease-in-out placeholder:text-neutral-200 focus:border-blue-600 focus:bg-white focus:text-dark-100 focus:outline-none`}
            name={props.name}
            onChange={props.onChange}
            id={props.id}
            value={props.value}
            aria-describedby={props.id}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
          />
          {props.type === "password" && (
            <Button
              variant="ghost"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 p-0"
              onClick={() => setIsShown(!isShown)}
            >
              {isShown ? (
                <EyeIcon className="h-4 w-4" aria-label="hide password" />
              ) : (
                <EyeSlashIcon className="h-4 w-4" aria-label="show password" />
              )}
            </Button>
          )}
        </div>
        {!!props.errorMsg && (
          <small id={props.id} className="mt-1 block text-xs text-red-600">
            {props.errorMsg}
          </small>
        )}
      </div>
    </div>
  );
}

export default CustomInput;
