import { Tab as HeadlessTab } from "@headlessui/react";

function Tab({ children }) {
  return (
    <HeadlessTab className="text-neutral-200 font-semibold ui-selected:text-dark-100 p-1 rounded-md focus:outline-slate-300">
      {children}
    </HeadlessTab>
  );
}

export default Tab;
