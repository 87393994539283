import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  onBoardingNavigationState,
  onBoardingProcessState,
  userState,
} from "../recoil/atoms";
import {
  AddProduct,
  activateUserMutation,
  addCollection,
  addShippingProfile,
  cancelSubscription,
  createCart,
  createOrderMutation,
  createStoreMutation,
  createUserMutation,
  deleteCollection,
  deleteProduct,
  deleteShippingProfile,
  forgotPasswordMutation,
  loginUserMutation,
  passwordResetMutation,
  resendActivationCodeMutation,
  saveBankDetails,
  updateCart,
  updateCollection,
  updateProduct,
  updateShippingProfile,
  updateStoreMutation,
  updateSubscription,
  uploadFileMutation,
  uploadImagesMutation,
  verifyBankAccountMutation,
  verifyOrderMutation,
  verifySubscription,
  withdrawMoney,
} from "./mutation.def";

export const useCreateUserMutation = () => {
  const [openTab, setOpenTab] = useRecoilState(onBoardingNavigationState);
  const [process, setProcess] = useRecoilState(onBoardingProcessState);
  const navigate = useNavigate();
  return useMutation(createUserMutation, {
    onSuccess: data => {
      if (Boolean(data.data.data.email)) {
        toast.success("Account created successfully!");
        localStorage.setItem("userEmail", data.data.data.email);
        setOpenTab({
          ...openTab,
          signUp: "completed",
          verify: "active",
          store: "disabled",
        });
        setProcess({
          ...process,
          signUp: true,
          verify: true,
          store: false,
        });
        navigate("/verify-account");
      } else {
        toast.error("Can't create an account with this email");
      }
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error creating an account");
    },
  });
};

export const useActivateUserMutation = () => {
  const [openTab, setOpenTab] = useRecoilState(onBoardingNavigationState);
  const [process, setProcess] = useRecoilState(onBoardingProcessState);
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(userState);

  return useMutation(activateUserMutation, {
    onSuccess: data => {
      if (!!data.data.data) {
        toast.success("Account activated successfully!");
        setUser(prev => ({ ...prev, ...data.data.data }));
        setOpenTab({
          ...openTab,
          signUp: "completed",
          verify: "completed",
          store: "active",
        });
        setProcess({
          ...process,
          signUp: true,
          verify: true,
          store: true,
        });
        navigate("/create-store");
      }
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error activating your email",
      );
    },
  });
};

export const useResendActivationCodeMutation = () => {
  const [openTab, setOpenTab] = useRecoilState(onBoardingNavigationState);

  const [process, setProcess] = useRecoilState(onBoardingProcessState);
  const navigate = useNavigate();
  return useMutation(resendActivationCodeMutation, {
    onSuccess: data => {
      if (!!data.data) {
        setOpenTab({
          ...openTab,
          signUp: "completed",
          verify: "completed",
          store: "active",
        });
        setProcess({
          ...process,
          signUp: true,
          verify: true,
          store: true,
        });
        navigate("/create-store");
      }
    },
  });
};

export const useCreateStoreMutation = () => {
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(userState);
  return useMutation(createStoreMutation, {
    onSuccess: data => {
      toast.success("Store successfully created!");
      if (!!data.data) {
        setUser(prev => ({ ...prev, store: data.data.data }));
        navigate("/dashboard");
      }
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error creating store");
    },
  });
};

export const useUpdateStoreMutation = () => {
  const [, setUser] = useRecoilState(userState);
  return useMutation(updateStoreMutation, {
    onSuccess: data => {
      toast.success("Changes saved");
      if (!!data.data) {
        setUser(prev => ({
          ...prev,
          store: { ...prev.store, ...data.data.data },
        }));
      }
    },
    onError: error => {
      const message = error.response?.data?.message || "Error updating store";
      toast.error(message);
    },
  });
};

export const useCreateOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrderMutation, {
    onSuccess: data => {
      if (!!data.data) {
        toast.success("Your order is processing...");
        queryClient.invalidateQueries("ordersList");
        window.location.href = data.data.data.paymentUrl;
      }
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error processing order");
    },
  });
};

export const useVerifyOrderMutation = () => {
  const navigate = useNavigate();
  const storeUrl = localStorage.getItem("storeUrl");

  return useMutation(verifyOrderMutation, {
    onSuccess: () => {
      localStorage.removeItem("checkout");
    },
    onError: err => {
      navigate(`/${storeUrl}/checkout`);
      toast.error(err?.response?.data?.message || "Error verifying order");
    },
  });
};

export const useUploadFileMutation = () => {
  return useMutation(uploadFileMutation, {
    onError: err => {
      if (err.code !== "ERR_CANCELED")
        toast.error(err?.response?.data?.message || "Error uploading file");
    },
  });
};

export const useLoginUserMutation = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);
  const setOpenTab = useSetRecoilState(onBoardingNavigationState);
  const setProcess = useSetRecoilState(onBoardingProcessState);

  return useMutation(loginUserMutation, {
    onSuccess: data => {
      setUser(data.data.data);
      if (!!data.data.data.store) return;
      setOpenTab({
        signUp: "completed",
        verify: "completed",
        store: "active",
      });
      setProcess({ signUp: true, verify: true, store: true });
      navigate("/create-store");
    },
    onError: err => {
      if (err?.code === "ERR_BAD_REQUEST")
        toast.error("Invalid user credentials");
      else toast.error("Error logging in");
    },
  });
};

export const useForgotPasswordMutation = () => {
  const navigate = useNavigate();

  return useMutation(forgotPasswordMutation, {
    onSuccess: data => {
      localStorage.setItem("reset-password-email", data.data.data);
      navigate("/new-password");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error verifying password");
    },
  });
};

export const useResetPasswordMutation = () => {
  const navigate = useNavigate();

  return useMutation(passwordResetMutation, {
    onSuccess: () => {
      navigate("/login");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error Resetting password");
    },
  });
};

export const useVerifyBankAccountMutation = () => {
  return useMutation(verifyBankAccountMutation, {
    onError: err => {
      toast.error(err?.response?.data?.message || "Error verifying account");
    },
  });
};

export const useSaveBankDetailsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(saveBankDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("bankAccount");
      toast.success("Bank details have been saved");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error Saving Bank Details");
    },
  });
};

export const useWithdrawMoney = callback => {
  const queryClient = useQueryClient();
  return useMutation(withdrawMoney, {
    onSuccess: () => {
      toast.success("Amount has been withdrawn");
      callback();
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Insufficient balance");
    },
    onSettled: () => {
      queryClient.invalidateQueries("currentUser");
      queryClient.invalidateQueries("withdrawals");
    },
  });
};

export const useCreateCartMutation = () => {
  return useMutation(createCart, {
    onSuccess: data => {
      // key is store ID and value is cart ID
      localStorage.setItem(data.data.data.storeId, data.data.data.id);
    },
    onError: err => toast.error(err?.response?.data?.message),
  });
};

export const useUpdateCartMutation = redirect => {
  const { storeUrl } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(updateCart, {
    onSuccess: data => {
      queryClient.invalidateQueries(["cart", data.data.data.id]);
      toast.success("Cart updated successfully");
      if (redirect) navigate(`/${storeUrl}/cart`);
    },
    onError: err => toast.error(err?.response?.data?.message),
  });
};

export const useAddProduct = () => {
  const QueryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(AddProduct, {
    onSuccess: () => {
      toast.success("Product has been added");
      navigate("/products");
      QueryClient.invalidateQueries("products");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Incorrect details");
    },
  });
};

export const useAddCollection = () => {
  const navigate = useNavigate();
  return useMutation(addCollection, {
    onSuccess: () => {
      toast.success("Collection Created");
      navigate("/products");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Incorrect details");
    },
  });
};

export const useUploadProductImagesMutation = () => {
  return useMutation(uploadImagesMutation, {
    onError: err => {
      if (err.code === "ERR_CANCELED") toast.error("Upload canceled");
      else toast.error(err?.response?.data?.message || "Error uploading file");
    },
  });
};

export const useUpdateSubscription = () => {
  return useMutation(updateSubscription, {
    onSuccess: data => {
      if (data.data?.data?.paymentUrl)
        window.location.href = data.data.data.paymentUrl;
      if (data.data?.data?.cancelled)
        toast.success("Subscription cancelled successfully");
    },
    onError: err => {
      if (err?.response?.status !== 401)
        toast.error(
          err?.response?.data?.message || "Error updating subscription",
        );
    },
  });
};

export const useCancelSubscription = () => {
  return useMutation(cancelSubscription, {
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error cancelling subscription",
      );
    },
  });
};

export const useVerifySubscriptionMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(verifySubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("subscription");
      navigate("/dashboard");
      toast.success("Your subscription has been verified");
    },
    onError: err => {
      navigate("/pricing");
      toast.error(
        err?.response?.data?.message || "Error verifying subscription",
      );
    },
  });
};

export const useUpdateProduct = () => {
  const navigate = useNavigate();
  return useMutation(updateProduct, {
    onSuccess: () => {
      toast.success("Product Updated");
      navigate("/products");
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Incorrect details");
    },
  });
};

export const useDeleteProductMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteProduct, {
    onSuccess: () => {
      toast.success("Product deleted");
      queryClient.invalidateQueries(["products"]);
    },
    onError: err => {
      toast.error(err?.response?.data?.message || "Error in deleting product");
    },
  });
};

export const useAddShippingProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(addShippingProfile, {
    onSuccess: () => {
      toast.success("Shipping Profile has been added");
      queryClient.invalidateQueries(["shipping"]);
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error adding shipping profile",
      );
    },
  });
};

export const useDeleteShippingProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShippingProfile, {
    onSuccess: () => {
      toast.success("Shipping Profile deleted");
      queryClient.invalidateQueries(["shipping"]);
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error in deleting Shipping Profile",
      );
    },
  });
};

export const useDeleteCollectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCollection, {
    onSuccess: () => {
      toast.success("Collection deleted");
      queryClient.invalidateQueries(["collection"]);
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error in deleting Collection",
      );
    },
  });
};

export const useUpdateShippingProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShippingProfile, {
    onSuccess: () => {
      toast.success("Shipping Profile Updated");
      queryClient.invalidateQueries(["shipping"]);
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error in updating Shipping Profile",
      );
    },
  });
};

export const useUpdateCollectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCollection, {
    onSuccess: () => {
      toast.success("Collection Updated");
      queryClient.invalidateQueries(["collection"]);
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error in updating collection",
      );
    },
  });
};
