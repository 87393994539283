import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { CloseBlack } from "../../constants/assets-constants";
import { UploadMultipleProductImages } from "./UploadMultipleProductImages";

const MAX_FILE_SIZE = 10485760; // 10MB

export const ImageUpload = ({ heading = "Images", uploads, setUploads }) => {
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);

  const handleFile = e => {
    const totalFiles = [...e.target.files];
    totalFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        return toast.error("File Size is exceeded");
      }
    });
    const imagesNumber = uploads.length + totalFiles.length;
    if (imagesNumber > 10) return toast.error("Max is 10");
    else setFiles(prev => [...prev, ...totalFiles]);
  };

  const removeImage = index => {
    setUploads(prev => prev.filter((_, idx) => idx !== index));
  };

  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        {heading}
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        Add up to 10 images of your
      </p>
      <div className="relative m-8 flex h-[8.5rem] items-center justify-center border-[1px] border-dashed border-[#525252]">
        <input
          onChange={handleFile}
          ref={inputRef}
          type="file"
          hidden
          multiple
          max={10}
          accept="image/*"
          className="absolute bottom-0 left-0 right-0 top-0 cursor-pointer opacity-0"
        />
        <p className="maxW-[15.063rem] text-center text-[0.625rem] font-medium text-[#525252]">
          Drop your messages here, or{" "}
          <span
            className="cursor-pointer text-[#0FAA8E]"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            click to browse
          </span>{" "}
          <br />
          1200 x 1600 (3:4) recommended, up to 10MB each
        </p>
      </div>
      {files.map((file, index) => (
        <UploadMultipleProductImages
          file={file}
          key={index}
          setUploads={setUploads}
        />
      ))}
      <div className="flex flex-wrap gap-[0.5rem]">
        {uploads.map((upload, index) => {
          return (
            <div className="relative" key={index}>
              <div className="absolute right-[5px] top-[2px] rounded-full bg-white">
                <img
                  src={CloseBlack}
                  alt="close"
                  className="cursor-pointer p-[0.1rem]"
                  onClick={() => removeImage(index)}
                />
              </div>
              <img
                src={upload}
                alt="product"
                className="h-[6rem] w-[6rem] rounded-md object-cover"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
