import React from "react";
import { Card, DollarCart } from "../../constants/assets-constants";

const paymentOptions = [
  {
    title: "Card",
    img: `${Card}`,
  },
  {
    title: "Other Methods",
    img: `${DollarCart}`,
  },
];
export const Shipping = () => {
  return (
    <div className="mt-[3rem]">
      <div className="flex gap-[2rem] mb-[1.5rem]">
        {paymentOptions.map((po, i) => {
          return (
            <div key={i}>
              <p className="text-[1.25rem] text-dark-100 mb-[1rem] font-bold">
                Pay With
              </p>
              <PaymentOptionCards img={po.img} title={po.title} />
            </div>
          );
        })}
      </div>
      <p className="text-[1.25rem] mb-[1rem] font-bold text-black">
        Contact Information
      </p>
      <div className="mb-[1rem]">
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">
            <FormInput
              id="email"
              name="email"
              label="Email Address"
              placeholder="Your email address"
            />
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex flex-col lg:flex-row my-[1rem]">
          <div className="flex-1">
            <FormSelect name="Country" label="Country">
              <option value="Nigeria">Nigeria</option>
              <option value="US">United States</option>
              <option value="UK">United Kingdom</option>
            </FormSelect>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="flex gap-[2rem] flex-col lg:flex-row">
          <div className="flex-1">
            <FormInput
              id="name"
              name="name"
              label="Full Name"
              placeholder="John Doe"
            />
          </div>
          <div className="flex-1">
            <FormInput
              id="address"
              name="address"
              label="Shipping Address"
              placeholder="Write your full address"
            />
          </div>
        </div>
        <div className="flex gap-[1rem] mt-[1rem] flex-col lg:flex-row">
          <div className="flex-1">
            <FormInput
              id="postal"
              name="postal"
              label="Postal Code"
              placeholder="Postal Code"
            />
          </div>
          <div className="flex-1">
            <FormInput
              id="state"
              name="state"
              label="State"
              placeholder="Write your state"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button className="w-[20rem] bg-[#101010] h-[3.25rem] rounded-[0.375rem] my-[1.5rem] text-[#fff]">
          Pay
        </button>
      </div>
    </div>
  );
};

const PaymentOptionCards = ({ img, title }) => {
  return (
    <div className="w-[9.8rem] lg:w-[11.5rem] bg-[#EDF8F6] rounded-[0.375rem] flex justify-center items-center shadow-[0px 4px 10px 0px rgba(0, 0, 0, 0.15)] cursor-pointer">
      <div className="my-[1rem]">
        <div className="flex justify-center">
          <img src={img} alt="icon" />
        </div>
        <p className=" text-[1rem] lg:text-[1.25rem] whitespace-nowrap text-[#1f1f1f] font-bold mt-[1.5rem]">
          {title}
        </p>
      </div>
    </div>
  );
};

const FormInput = (props) => {
  return (
    <div>
      <div>
        <label
          htmlFor={props.id}
          className="text-[1.25rem] font-normal text-[#000]"
        >
          {props.label}
        </label>
        {props.isRequired && (
          <span className="font-black text-[#FF0606]">*</span>
        )}
        {props.labelIcon}
      </div>
      <input
        type={props.type || "text"}
        className="w-full rounded-[0.375rem] py-[0.7rem] mt-[0.54rem] text-[1rem] bg-transparent border-neutral-100 text-neutral-100 focus:outline-none focus:shadow-transparent focus:border-black focus:ring-transparent"
        name={props.name}
        onChange={props.onChange}
        id={props.id}
        value={props.value}
        aria-describedby={props.id}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        required={props.isRequired}
      />
      {!!props.errorMsg && (
        <small id={props.id} className="block mt-1 text-xs text-red-600">
          {props.errorMsg}
        </small>
      )}
    </div>
  );
};

const FormSelect = (props) => {
  return (
    <div>
      <div>
        <label
          htmlFor={props.id}
          className="text-[1.25rem] font-normal text-[#000]"
        >
          {props.label}
        </label>
        {props.isRequired && (
          <span className="font-black text-[#FF0606]">*</span>
        )}
        {props.labelIcon}
      </div>
      {/* <input
          type={props.type || "text"}
          className="w-full rounded-[0.375rem] py-[0.7rem] mt-[0.54rem] text-[1rem] bg-transparent border-neutral-100 text-neutral-100 focus:outline-none focus:shadow-transparent focus:border-black focus:ring-transparent"
          name={props.name}
          onChange={props.onChange}
          id={props.id}
          value={props.value}
          aria-describedby={props.id}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          required={props.isRequired}
        /> */}
      <select
        className="w-full rounded-[0.375rem] py-[0.7rem] mt-[0.54rem] text-[1rem] bg-transparent border-neutral-100 text-neutral-100 focus:outline-none focus:shadow-transparent focus:border-black focus:ring-transparent"
        name={props.name}
        onChange={props.onChange}
        id={props.id}
        value={props.value}
        aria-describedby={props.id}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        required={props.isRequired}
      >
        {props.children}
      </select>
      {!!props.errorMsg && (
        <small id={props.id} className="block mt-1 text-xs text-red-600">
          {props.errorMsg}
        </small>
      )}
    </div>
  );
};
