import React from "react";

function CustomSelect(props) {
  return (
    <div>
      <div className="mb-6 ">
        <label
          htmlFor={props.id}
          className="form-label mb-1 text-left text-xs font-medium text-dark-100 md:text-sm"
        >
          {props.label}
        </label>
        <select
          className="form-control m-0 block h-10 w-full rounded border border-solid 
          border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-10 text-dark-100 transition ease-in-out 
          placeholder:text-neutral-200 focus:border-blue-600 focus:bg-white focus:text-dark-100 focus:outline-none"
          name={props.name}
          onChange={props.onChange}
          id={props.id}
          value={props.value}
          aria-describedby={props.id}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
        >
          {props.children}
        </select>
        {!!props.errorMsg && (
          <small id={props.id} className="mt-1 block text-xs text-red-600">
            {props.errorMsg}
          </small>
        )}
      </div>
    </div>
  );
}

export default CustomSelect;
