import React from "react";

export const SelectContainer = ({ title, description, onClick, state }) => {
  return (
    <div
      className={`flex gap-[1rem] mb-[1rem] py-[1.8rem] px-[1.0rem] border-[1px] ${
        state ? "border-green-100" : "border-neutral-300"
      } rounded-[0.375rem] cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">
        <div
          className={`border-[1px] rounded-full w-[1rem] h-[1rem] flex justify-center items-center ${
            state ? "border-[#0FAA8E]" : "border-[#fff]"
          }`}
        >
          <div
            className={`border-[1px] border-neutral-300 rounded-full w-[0.7rem] h-[0.7rem] ${
              state ? "bg-[#0FAA8E]" : "bg-[white]"
            }`}
          ></div>
        </div>
      </div>
      <div>
        <p className="text-[0.875rem] text-dark-100 font-[600]">{title}</p>
        <p className="text-[0.875rem] text-neutral-100 mt-[0.1rem]">
          {description}
        </p>
      </div>
    </div>
  );
};
