import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import BusyOverlay from "../../components/commons/busy-overlay";
import { DigitalProductsSettings } from "../../components/products/DigitalProductsSettings";
import File from "../../components/products/File";
import { ImageUpload } from "../../components/products/ImageUpload";
import StockDigital from "../../components/products/StockDigital";
import { useAddProduct } from "../../operations/mutations";
import { Price } from "./../../components/products/Price";
import SEO from "../../components/commons/seo";

const validationSchema = yup.object().shape({
  productName: yup.string().required("Please enter the product name"),
  productShortDescription: yup
    .string()
    .required("Please enter a product description"),
  productPrice: yup.string().required("Please enter the product price"),
  productCurrency: yup.object().required("Please select a currency"),
  productSKU: yup.string().required("Please enter SKU number"),
  productQuantity: yup.string().required("Please enter a quantity"),
});

const DigitalProduct = () => {
  const [productImages, setProductImages] = useState([]);
  const { mutate: addProduct, isLoading } = useAddProduct();

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: "",
      productShortDescription: "",
      productLongDescription: "",
      productPrice: "",
      productCurrency: "",
      productQuantity: "",
      productType: "DIGITAL",
      productLink: "",
      productSKU: "",
      productCollection: "",
      productVariants: [],
      productSetting: {
        discount: {
          discountable: false,
          amount: "10",
        },
        downloadable: true,
      },
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        const { productCollection, productCurrency } = values;
        addProduct({
          ...values,
          productImages,
          productCollection: !!productCollection
            ? productCollection.value
            : null,
          productCurrency: !!productCurrency ? productCurrency.value : null,
        });
      });
    },
  });

  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="Add Digital Product" />
      <form onSubmit={handleSubmit} className="space-y-8">
        <DigitalProductsSettings
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <Price
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <File values={values} setFieldValue={setFieldValue} />
        <ImageUpload
          heading="Thumbnail"
          uploads={productImages}
          setUploads={setProductImages}
        />
        <StockDigital
          values={values}
          errors={errors}
          handleChange={handleChange}
        />
      </form>
    </>
  );
};

export default DigitalProduct;
