import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ButtonComponent from "../../components/commons/buttons";
import SEO from "../../components/commons/seo";
import TitleComponent from "../../components/commons/title-component";
import HeaderComponent from "../../components/header";
import {
  ArrowUpIcon,
  PolygonGreen,
  PurpleCheck,
} from "../../constants/assets-constants";
import pricingLists from "../../constants/pricing-list";
import { useUpdateSubscription } from "../../operations/mutations";
import { userState } from "../../recoil/atoms";
import formatPrice from "../../utils/formatPrice";

const PricingCardComponent = ({ pricing, currency }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useRecoilValue(userState) || {};
  const { mutate: updateSubscription, isLoading } = useUpdateSubscription();

  const handleSubscribe = () => {
    if (pricing.price === "free") return navigate("/signup");

    if (!user) {
      const searchParams = new URLSearchParams();
      searchParams.set("redirect", location.pathname);
      navigate({ pathname: "/login", search: `?${searchParams.toString()}` });
      return;
    }

    updateSubscription({
      frequency: pricing.rate === "annually" ? "YEARLY" : "MONTHLY",
      plan: pricing.type === "Premium" ? "PREMIUM PLAN" : "FREE PLAN",
    });
  };

  return (
    <div className="col-1 flex h-600 flex-col justify-between border border-neutral-300 p-3 sm:p-5 md:h-800 md:p-1/10">
      <SEO title="Pricing" />
      <div className="">
        <div className="text-center">
          <p className="mb-3 text-xl font-bold text-neutral-100 ">
            {pricing.type}
          </p>
          <div className="mb-2 flex items-center justify-center">
            <h2 className="text-2xl font-bold text-dark-100 md:text-4xl lg:text-40 ">
              {pricing.price === "free"
                ? "FREE"
                : currency === "Nigeria"
                ? formatPrice(pricing.priceNaira)
                : formatPrice(pricing.priceDol, "USD")}
            </h2>
            {pricing.rate && pricing.price !== "free" && (
              <small className="pt-1">/{pricing.rate}</small>
            )}
          </div>
          {pricing.rate === "annually" && pricing.price !== "free" && (
            <div className="mb-3 flex items-center justify-center space-x-1 md:mb-5">
              <span className="text-xs font-medium text-green-150 md:text-base">
                You save{" "}
              </span>
              <span className="text-base font-bold text-green-150 md:text-xl">
                {currency === "Nigeria"
                  ? formatPrice(pricing.extraSaveNaira)
                  : formatPrice(pricing.extraSaveDol)}
              </span>
            </div>
          )}
          {pricing.extraMsg && <p className="mb-5">{pricing.extraMsg}</p>}
        </div>
        <ul className="mt-5 flex flex-col space-y-6">
          {pricing.benefits.map((benefit, index) => {
            return (
              <li key={index} className="flex items-center space-x-3 text-left">
                <span>
                  <img src={PurpleCheck} alt="" />
                </span>
                <p className="text-sm md:text-base">{benefit}</p>
              </li>
            );
          })}
          <li className="flex items-center space-x-3 text-left">
            <span>
              <img src={PurpleCheck} alt="" />
            </span>
            <p className="text-sm md:text-base">
              8% Transaction Fee{" "}
              <span className="text-green-100">
                (including Payment Processing Fee)
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div className="mb-5 w-full">
        <ButtonComponent
          loading={isLoading}
          hasWidth
          isGreen={pricing.type === "Premium"}
          btnText={pricing.type === "Premium" ? "Subscribe now" : "Sign up"}
          callToAction={handleSubscribe}
        />
      </div>
    </div>
  );
};

function PricingPage() {
  const [currency, setCurrency] = useState("Nigeria");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [rate, setRate] = useState("monthly");

  const handleCurrencyChange = cur => {
    setCurrency(cur);
    setOpenDropdown(false);
  };

  return (
    <>
      <HeaderComponent />

      <div className="relative px-1/6 py-16">
        <img
          className="absolute right-1/30 top-10 w-8 lg:right-1/45"
          src={PolygonGreen}
          alt=""
        />
        <div className="w-full pt-5 text-center">
          <h5 className="text-2xl font-medium text-dark-100">Pricing</h5>
          <TitleComponent
            isMedium
            text="Build Your Online Business With Ease"
          />
        </div>
        <div className="mt-10 ">
          <div className="flex justify-center md:mb-8">
            <div className="relative h-11">
              <div
                onClick={() => setOpenDropdown(!openDropdown)}
                className="relative flex h-11 w-44 cursor-pointer items-center justify-between rounded-lg border border-green-100 bg-white px-3 text-xl text-dark-100"
              >
                <p className="text-base text-dark-100 md:text-lg ">
                  {currency}
                </p>
                <img src={ArrowUpIcon} alt="" />
              </div>
              {openDropdown && (
                <div className="bottom-o absolute left-0 mt-1 w-44 overflow-hidden rounded-lg border border-green-100 bg-white p-4 text-left shadow-md">
                  <div
                    onClick={() => handleCurrencyChange("Nigeria")}
                    className="mb-3 cursor-pointer text-base text-dark-100 hover:bg-green-50 md:text-lg "
                  >
                    Nigeria
                  </div>
                  <div
                    onClick={() => handleCurrencyChange("International")}
                    className="cursor-pointer text-base text-dark-100 hover:bg-green-50 md:text-lg"
                  >
                    International
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mx-auto mb-12 mt-5 flex h-11 w-4/5 items-center overflow-hidden rounded-15 sm:w-1/60 lg:hidden ">
            <div
              onClick={() => setRate("monthly")}
              className={`flex h-full w-1/2 items-center justify-center text-sm font-semibold md:text-base  ${
                rate === "monthly"
                  ? "bg-green-100 text-white"
                  : "bg-neutral-350 text-dark-100"
              }`}
            >
              Monthly
            </div>
            <div
              onClick={() => setRate("annually")}
              className={`flex  h-full w-1/2 items-center justify-center text-sm font-semibold md:text-base ${
                rate === "annually"
                  ? "bg-green-100 text-white"
                  : "bg-neutral-350 text-dark-100"
              }`}
            >
              Annually
            </div>
          </div>

          <div className="hidden grid-cols-1 gap-x-5 gap-y-12 lg:grid lg:grid-cols-2">
            {pricingLists.map((pricing, index) => {
              return (
                <PricingCardComponent
                  key={index}
                  currency={currency}
                  pricing={pricing}
                />
              );
            })}
          </div>
          <div className="grid grid-cols-1 gap-x-5 gap-y-12 lg:hidden lg:grid-cols-2">
            {pricingLists
              .filter(priceRate => priceRate.rate === rate)
              .map((pricing, index) => {
                return (
                  <PricingCardComponent
                    key={index}
                    currency={currency}
                    pricing={pricing}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingPage;
