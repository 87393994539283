import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import BusyOverlay from "../components/commons/busy-overlay";
import StorefrontHeader from "../components/storefront/header";
import StorefrontSidebar from "../components/storefront/sidebar";
import {
  FacebookBlack,
  InstagramBlack,
  TwitterBlack,
} from "../constants/assets-constants";
import { useCreateCartMutation } from "../operations/mutations";
import {
  useGetCart,
  useGetCollections,
  useGetStorefront,
} from "../operations/queries";

const getLogo = platform => {
  if (platform === "Facebook") return FacebookBlack;
  if (platform === "Instagram") return InstagramBlack;
  if (platform === "Twitter") return TwitterBlack;
};

function StorefrontLayoutWrapper() {
  const [show, setShow] = useState(false);
  const { storeUrl } = useParams();
  const { isLoading: isFetching, data } = useGetStorefront(storeUrl);
  const { mutate: createCart, isLoading } = useCreateCartMutation();
  const store = data?.data?.data?.store;
  const { isLoading: isFetchingCart } = useGetCart(store?.id);
  const { isLoading: isFetchingCollections } = useGetCollections({
    storeId: store?.id,
  });
  const loading =
    isFetching || isFetchingCart || isFetchingCollections || isLoading;

  const showSidebar = () => {
    setShow(true);
    document.body.style.overflow = "hidden";
  };

  const hideSidebar = () => {
    setShow(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (!store) return;
    const cartId = localStorage.getItem(store.id);
    if (!cartId) createCart({ storeId: store.id });
  }, [createCart, store]);

  if (loading) return <BusyOverlay loading={loading} />;

  return (
    <div>
      <StorefrontHeader showSidebar={showSidebar} />
      <div className="sm:flex">
        <div
          className={`${
            show
              ? "fixed left-0 top-0 z-100 block h-full w-full bg-white"
              : "hidden"
          } sm:block sm:basis-2/12`}
        >
          <StorefrontSidebar hideSidebar={hideSidebar} />
        </div>
        <div className="min-w-0 sm:basis-11/12">
          <Outlet />
        </div>
      </div>
      <footer
        className="flex flex-col items-center gap-4 bg-dark-200 p-4"
        style={{ backgroundColor: store?.brandColor }}
      >
        <div className="flex gap-4">
          {store?.socials.map(social => (
            <a
              key={social._id}
              target="_blank"
              rel="noreferrer"
              href={social.url}
              className="hover:opacity-90"
            >
              <div className="flex h-34 w-34 items-center justify-center rounded-full bg-white shadow-lg">
                <img src={getLogo(social.platform)} alt={social.platform} />
              </div>
            </a>
          ))}
        </div>
        <div className="h-[1px] w-full bg-neutral-100" />
        <div className="flex w-full flex-col items-center justify-center gap-4 text-center text-sm sm:flex-row md:text-base">
          <a href={window.location.origin} target="_blank" rel="noreferrer">
            <small className="font-bold text-green-100">
              Powered By Digible
            </small>
          </a>
          <small className="text-white">
            Copyright ® {new Date().getFullYear()} Digible. All rights Reserved
          </small>
        </div>
      </footer>
    </div>
  );
}

export default StorefrontLayoutWrapper;
