import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../commons/data-table";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("productId.productName", {
    header: "Name",
    cell: info => info.getValue() || "N/A",
  }),
  columnHelper.accessor("productId.productSKU", {
    header: "SKU",
    cell: info => info.getValue() || "N/A",
  }),
  columnHelper.accessor("quantity", {
    header: "Quantity",
    cell: info => info.getValue() ?? "N/A",
  }),
  columnHelper.accessor("productVariants", {
    header: "Variants",
    cell: info => {
      const productVariants = info.getValue();
      if (!productVariants || productVariants.length === 0) return "N/A";
      return productVariants
        .map(variant => `${variant.variantHeader} - ${variant.variantName}`)
        .join(", ");
    },
  }),
];

const ProductDetails = ({ order }) => {
  const parsedDetails = order?.orderDetail
    ? JSON.parse(order?.orderDetail)
    : [];

  const productDetails = parsedDetails.hasOwnProperty("cart" || "shipping")
    ? parsedDetails?.cart
    : [];

  return (
    <div className="w-full border-[1px] px-[1.563rem] py-[2.063rem] lg:w-[53rem]">
      <p className="mb-4 text-[0.875rem] font-bold text-[#111827]">
        Product Details
      </p>
      <div className="h-full w-full">
        <DataTable
          data={productDetails}
          columns={columns}
          border
          totalCount={productDetails.length}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
