import { React } from "react";
import { TitleAndContent } from "./TitleAndContent";
import formatPrice from "../../utils/formatPrice";

export const MainDetails = ({ order }) => {
  const customer = order?.orderCustomer ? JSON.parse(order?.orderCustomer) : [];

  return (
    <div className="h-[full] w-full rounded-[8px] border-[1px] px-[1.563rem] py-[2.063rem] lg:w-[53rem]">
      <div className="flex w-[100%] flex-col justify-between gap-[20px] sm:flex-row md:w-[80%] md:gap-[0px]">
        <h1 className="text-[1.5rem] font-bold  text-[#111827] ">
          Order Details
        </h1>
        <div>
          <p className="text-[0.875rem] font-medium text-[#111827]">
            Date: {new Date(order?.createdAt).toLocaleDateString()}
          </p>
          <p className="text-[0.875rem] font-medium text-[#111827]">
            Time: {new Date(order?.createdAt).toLocaleTimeString()}
          </p>
          <p className="text-[0.875rem] font-medium text-[#111827]">
            Order Number: {order?.id}
          </p>
          <p className="text-[0.875rem] font-medium text-[#111827]">
            Total Amount: {formatPrice(order?.orderAmount)}
          </p>
        </div>
      </div>
      <div>
        <p className="mt-[4.25rem] text-[0.875rem] font-bold text-[#111827]">
          Customer Details
        </p>
        <TitleAndContent
          title="Customer Name:"
          content={customer?.name || customer?.fullNames || "N/A"}
        />
        <TitleAndContent
          title="Email Address"
          content={customer?.email || customer?.emailAddress || "N/A"}
        />
        <TitleAndContent
          title="Phone Number"
          content={customer?.phonenumber || "N/A"}
        />
        <TitleAndContent
          title="Country:"
          content={order?.shipping?.country || "N/A"}
        />
        <TitleAndContent
          title="State:"
          content={order?.shipping?.state || "N/A"}
        />
      </div>
    </div>
  );
};
