import { useEffect } from "react";
import cn from "../../utils/cn";

const BusyOverlay = ({ text, loading, opaque = false }) => {
  useEffect(() => {
    if (loading) document.body.style.overflow = "hidden";
    if (!loading) document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loading]);

  return (
    <div
      className={cn(
        "fixed left-0 top-0 z-100 flex h-screen w-full items-center justify-center gap-2 bg-white",
        !loading && "hidden",
        opaque ? "white" : "bg-black bg-opacity-50",
      )}
    >
      <div
        className={cn(
          "h-16 w-16 animate-spin rounded-full border-[0.6rem] border-r-[0.6rem] border-t-[0.6rem] border-[#E6E3DF] border-r-green-100 border-t-green-100 bg-transparent",
          {
            "h-6 w-6 border-[0.25rem] border-r-[0.25rem] border-t-[0.25rem]":
              !!text,
          },
        )}
      />
      <span
        className={cn(
          "hidden text-lg text-white",
          opaque && "text-dark-100",
          text && "inline",
        )}
      >
        {text}
      </span>
    </div>
  );
};

export default BusyOverlay;
