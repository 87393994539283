import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swiper, { Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ButtonComponent from "../../components/commons/buttons";
import Chip from "../../components/commons/chip";
import IconButton from "../../components/commons/icon-button";
import { ShareIcon } from "../../components/icons";
import ShareModal from "../../components/storefront/share-modal";
import { NoImageAvailable } from "../../constants/assets-constants";
import { useUpdateCartMutation } from "../../operations/mutations";
import { useGetCart, useGetStorefront } from "../../operations/queries";
import cn from "../../utils/cn";
import formatPrice from "../../utils/formatPrice";
import { getDefaultVariants, updatedProducts } from "../../utils/storefront";

const MIN = 1;
const MAX = 100;

function ProductComponent({ product, withTitle, reversed, className }) {
  const navigate = useNavigate();
  const { storeUrl } = useParams();
  const [quantity, setQuantity] = useState(MIN);
  const [selectedVariants, setSelectedVariants] = useState(
    getDefaultVariants(product.productVariants),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: cartRes } = useGetCart(store?.id);
  const {
    mutate: updateCart,
    isLoading,
    isSuccess,
  } = useUpdateCartMutation(redirect);

  const handleAddToCart = shouldRedirect => {
    if (!store) return toast.error("Store not found");
    const cartId = localStorage.getItem(store.id);
    if (!cartId) return toast.error("Create a cart for this store");
    const cart = cartRes?.data?.data;
    if (!cart) return toast.error("Cart not found");
    const productToAdd = {
      productId: product.id,
      productVariants: selectedVariants,
      quantity,
    };
    setRedirect(shouldRedirect);
    updateCart({
      ...cart,
      id: cart._id,
      shipping: cart.shipping?.id || null,
      products: updatedProducts(cart, productToAdd),
    });
  };

  const totalAdditionalPrice = selectedVariants.reduce(
    (acc, item) => acc + (item.additionalPrice ?? 0),
    0,
  );

  useEffect(() => {
    if (isSuccess && redirect) {
      setTimeout(() => {
        navigate(`/${storeUrl}/cart`);
      }, 1000);
    }
  }, [isSuccess, navigate, redirect, storeUrl]);

  return (
    <div className={cn("mb-10 sm:px-4", className)}>
      {withTitle && (
        <h1 className="mb-6 text-2xl font-semibold md:text-40">
          Newest product
        </h1>
      )}
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div
          className={`order-last flex flex-col items-start gap-5 ${
            reversed ? "order-last" : "lg:order-first"
          }`}
        >
          <h3 className="text-xl font-medium md:text-3xl">
            {product.productName}
          </h3>
          <p className="flex items-center gap-1 text-lg md:text-22">
            {formatPrice(product.productPrice, product.productCurrency)}{" "}
            {!!totalAdditionalPrice &&
              `+ ${formatPrice(totalAdditionalPrice, product.productCurrency)}`}
          </p>
          <div className="flex flex-col w-full gap-6 my-4 text-lg sm:my-8 sm:flex-row md:text-2xl">
            <div
              className="flex items-center justify-between gap-4 border rounded-md border-neutral-200"
              style={{
                borderColor: store?.brandColor,
                color: store?.brandColor,
              }}
            >
              <IconButton
                variant="ghost"
                className="p-4 text-lg focus:ring-0 md:text-2xl"
                description="Decrease quantity"
                text="-"
                onClick={() => quantity > MIN && setQuantity(prev => prev - 1)}
                style={{ color: store?.brandColor }}
              />
              <input
                type="number"
                className="text-center border-none"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                onBlur={e => {
                  if (e.target.value < MIN || e.target.value > MAX)
                    setQuantity(MIN);
                  if (e.target.value > MAX) setQuantity(MAX);
                }}
                min={MIN}
                max={MAX}
              />
              <IconButton
                variant="ghost"
                className="p-4 text-lg focus:ring-0 md:text-2xl"
                description="Increase quantity"
                text="+"
                onClick={() => quantity < MAX && setQuantity(prev => prev + 1)}
                style={{ color: store?.brandColor }}
              />
            </div>

            <ButtonComponent
              isBlack
              fullWidth
              btnText="Add to Cart"
              callToAction={() => handleAddToCart(false)}
              className="border-transparent"
              style={{ backgroundColor: store?.brandColor }}
              loading={isLoading}
            />
          </div>
          <ButtonComponent
            callToAction={() => handleAddToCart(true)}
            fullWidth
            btnText="Buy Now"
            style={{ borderColor: store?.brandColor, color: store?.brandColor }}
            loading={isLoading}
          />
          {product.productVariants.map((item, index) => (
            <div key={index}>
              <p className="mb-2 text-lg font-medium md:text-2xl">
                {item.variantHeader}
              </p>
              <div className="flex gap-3">
                {item.variants.map((variant, index) => (
                  <Chip
                    key={index}
                    onClick={() => {
                      setSelectedVariants(prev => {
                        const variantHeaderExists = prev.find(
                          p => p.variantHeader === item.variantHeader,
                        );
                        if (variantHeaderExists)
                          return prev.map(p => {
                            if (p.variantHeader === item.variantHeader)
                              return {
                                variantHeader: item.variantHeader,
                                variantName: variant.variantName,
                                additionalPrice: variant.additionalPrice,
                              };
                            return p;
                          });

                        return [
                          ...prev,
                          {
                            variantHeader: item.variantHeader,
                            variantName: variant.variantName,
                            additionalPrice: variant.additionalPrice,
                          },
                        ];
                      });
                    }}
                    className={
                      selectedVariants.find(
                        item => item.variantName === variant.variantName,
                      )
                        ? "border-none ring-2 ring-green-100 focus:ring-green-100"
                        : "ring-neutral-300"
                    }
                    style={{ "--tw-ring-color": store?.brandColor }}
                  >
                    {variant.variantName}
                  </Chip>
                ))}
              </div>
            </div>
          ))}
          <p className="text-lg md:text-2xl">
            {product.productLongDescription}
          </p>
          <IconButton
            onClick={() => setIsOpen(true)}
            variant="ghost"
            text="Share"
            icon={<ShareIcon className="w-6 h-6" />}
            iconPosition="left"
            description="Share this product"
            className="inline-flex w-auto gap-4 p-6 font-medium sm:w-auto"
            textClassName="text-2xl"
            style={{ color: store?.brandColor }}
          />
          <ShareModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <ProductImages
          images={
            product.productImages.length > 0
              ? product.productImages.slice(0, 4)
              : [product.productImage || NoImageAvailable]
          }
        />
      </div>
    </div>
  );
}

function ProductImages({ images }) {
  useEffect(() => {
    const thumbs = new Swiper(".thumbs", {
      modules: [Navigation],
      slidesPerView: images.length,
      slidesPerGroup: images.length,
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      breakpoints: {
        768: {
          direction: "vertical",
          slidesPerView: images.length,
          slidesPerGroup: images.length,
          navigation: { enabled: false },
        },
      },
    });

    if (images.length > 1)
      new Swiper(".gallery", {
        modules: [Thumbs],
        thumbs: { swiper: thumbs },
        breakpoints: { 768: { direction: "vertical" } },
      });
  }, [images]);

  return (
    <div className="relative overflow-hidden">
      <div className="gallery mb-4 h-[400px] w-full md:mb-0 lg:h-[830px]">
        <div className="swiper-wrapper">
          {images.map((image, index) => (
            <div key={index} className="swiper-slide">
              <img
                src={image}
                alt="product"
                className="object-contain w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>
      {images.length > 1 && (
        <div className="thumbs relative h-[150px] max-h-[600px] overflow-hidden md:absolute md:right-1 md:top-1/2 md:z-10 md:h-auto md:w-[100px] md:-translate-y-1/2">
          <div className="swiper-wrapper">
            {images.map((image, index) => (
              <div key={index} className="swiper-slide h-[40px] opacity-50">
                <img
                  src={image}
                  alt="product"
                  className="object-contain w-full h-full"
                />
              </div>
            ))}
          </div>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      )}
    </div>
  );
}

export default ProductComponent;
