import React from "react";

function CartIcon(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 22.8311C6.45 22.8311 5.97933 22.6354 5.588 22.2441C5.196 21.8521 5 21.3811 5 20.8311C5 20.2811 5.196 19.8101 5.588 19.4181C5.97933 19.0267 6.45 18.8311 7 18.8311C7.55 18.8311 8.02067 19.0267 8.412 19.4181C8.804 19.8101 9 20.2811 9 20.8311C9 21.3811 8.804 21.8521 8.412 22.2441C8.02067 22.6354 7.55 22.8311 7 22.8311ZM17 22.8311C16.45 22.8311 15.9793 22.6354 15.588 22.2441C15.196 21.8521 15 21.3811 15 20.8311C15 20.2811 15.196 19.8101 15.588 19.4181C15.9793 19.0267 16.45 18.8311 17 18.8311C17.55 18.8311 18.021 19.0267 18.413 19.4181C18.8043 19.8101 19 20.2811 19 20.8311C19 21.3811 18.8043 21.8521 18.413 22.2441C18.021 22.6354 17.55 22.8311 17 22.8311ZM6.15 6.83105L8.55 11.8311H15.55L18.3 6.83105H6.15ZM5.2 4.83105H19.95C20.3333 4.83105 20.625 5.00172 20.825 5.34305C21.025 5.68505 21.0333 6.03105 20.85 6.38105L17.3 12.7811C17.1167 13.1144 16.8707 13.3727 16.562 13.5561C16.254 13.7394 15.9167 13.8311 15.55 13.8311H8.1L7 15.8311H19V17.8311H7C6.25 17.8311 5.68333 17.5017 5.3 16.8431C4.91667 16.1851 4.9 15.5311 5.25 14.8811L6.6 12.4311L3 4.83105H1V2.83105H4.25L5.2 4.83105ZM8.55 11.8311H15.55H8.55Z" />
    </svg>
  );
}

export default CartIcon;
