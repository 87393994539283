import React from "react";

function ShoppingBagIcon(props) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M28.5026 9.50033H25.3359C25.3359 6.00116 22.5018 3.16699 19.0026 3.16699C15.5034 3.16699 12.6693 6.00116 12.6693 9.50033H9.5026C7.76094 9.50033 6.33594 10.9253 6.33594 12.667V31.667C6.33594 33.4087 7.76094 34.8337 9.5026 34.8337H28.5026C30.2443 34.8337 31.6693 33.4087 31.6693 31.667V12.667C31.6693 10.9253 30.2443 9.50033 28.5026 9.50033ZM19.0026 6.33366C20.7443 6.33366 22.1693 7.75866 22.1693 9.50033H15.8359C15.8359 7.75866 17.2609 6.33366 19.0026 6.33366ZM28.5026 31.667H9.5026V12.667H12.6693V15.8337C12.6693 16.7045 13.3818 17.417 14.2526 17.417C15.1234 17.417 15.8359 16.7045 15.8359 15.8337V12.667H22.1693V15.8337C22.1693 16.7045 22.8818 17.417 23.7526 17.417C24.6234 17.417 25.3359 16.7045 25.3359 15.8337V12.667H28.5026V31.667Z" />
    </svg>
  );
}

export default ShoppingBagIcon;
