import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "../commons/button";
import { FormInput } from "../form";
import { TrashIcon } from "../icons";

const newVariant = {
  variantName: "",
  additionalPrice: "",
  variantQuantity: "",
};

const Variants = ({
  handleRemove,
  variant,
  index,
  handleChange,
  setFieldValue,
  values,
}) => {
  // Function to add a new variant to the product variants
  const handleAddVariant = () => {
    // Create a new array of product variants by mapping over the existing values
    const productVariants = values.map((value, i) =>
      i === index
        ? { ...value, variants: [...value.variants, newVariant] }
        : value,
    );
    // Update the form field value with the new product variants
    setFieldValue("productVariants", productVariants);
  };

  // Function to remove a variant from the product variants
  const handleRemoveVariant = variantIndex => {
    // Create a new array of product variants by mapping over the existing values
    const productVariants = values.map((value, i) =>
      i === index
        ? // Remove the variant from the variants array
          {
            ...value,
            variants: value.variants.filter((_, idx) => idx !== variantIndex),
          }
        : value,
    );
    // Update the form field value with the updated product variants
    setFieldValue("productVariants", productVariants);
  };

  return (
    <div>
      <h5 className="mb-2 text-sm text-dark-100">Variant {index + 1}</h5>
      <div className="mb-2 flex flex-col gap-[10px] lg:flex-row">
        <FormInput
          type="text"
          id={`productVariants[${index}].variantHeader`}
          name={`productVariants[${index}].variantHeader`}
          placeholder="shoe"
          label="Variant Header"
          onChange={handleChange}
          value={variant.variantHeader}
          className="flex-1"
        />
        <Button
          size="sm"
          variant="ghost"
          type="button"
          onClick={() => handleRemove(index)}
        >
          <TrashIcon className="w-4 h-4 text-dark-100" />
        </Button>
      </div>
      <div className="space-y-4">
        {variant.variants.map((variant, i, arr) => (
          <div key={i} className="flex flex-col gap-[10px] lg:flex-row">
            <div className="flex-1">
              <FormInput
                type="text"
                id={`productVariants[${index}].variants[${i}].variantName`}
                name={`productVariants[${index}].variants[${i}].variantName`}
                placeholder="Blue shoe"
                label="Variant Name"
                onChange={handleChange}
                value={variant.variantName}
              />
            </div>
            <div className="flex-1">
              <FormInput
                type="text"
                id={`productVariants[${index}].variants[${i}].additionalPrice`}
                name={`productVariants[${index}].variants[${i}].additionalPrice`}
                placeholder="10,000"
                label="Additional Price"
                onChange={handleChange}
                value={variant.additionalPrice}
              />
            </div>
            <div className="flex-1">
              <FormInput
                type="text"
                id={`productVariants[${index}].variants[${i}].variantQuantity`}
                name={`productVariants[${index}].variants[${i}].variantQuantity`}
                placeholder="5"
                label="Variant Quantity"
                onChange={handleChange}
                value={variant.variantQuantity}
              />
            </div>
            <div className="flex flex-col items-center justify-between gap-2">
              {arr.length > 1 && (
                <Button
                  size="sm"
                  variant="ghost"
                  type="button"
                  onClick={() => handleRemoveVariant(i)}
                >
                  <TrashIcon className="w-4 h-4 text-dark-100" />
                </Button>
              )}
              {i === arr.length - 1 && (
                <Button
                  size="sm"
                  variant="ghost"
                  type="button"
                  onClick={handleAddVariant}
                >
                  <PlusIcon className="w-4 h-4 text-dark-100" />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Variants;
