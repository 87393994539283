import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Swiper, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import BusyOverlay from "../../../components/commons/busy-overlay";
import ProductCard from "../../../components/storefront/product-card";
import {
  useGetProductDetails,
  useGetProductList,
  useGetStorefront,
} from "../../../operations/queries";
import Product from "../product";
import SEO from "../../../components/commons/seo";

function ProductPage() {
  const { storeUrl, productId } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: productRes, isLoading } = useGetProductDetails(productId);
  const { data: response } = useGetProductList({
    storeId: store?.id,
    page: 1,
    pageSize: 13,
  });
  const product = productRes?.data?.data;
  const products = response?.data?.data;

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: product?.productCollection?.name,
      path: `/${storeUrl}/collection/${product?.productCollection?._id}`,
    },
    {
      name: product?.productName,
      path: "#",
    },
  ];

  const recommendedProducts = useMemo(() => {
    if (!products || !productId) return [];
    return products.filter(product => product.id !== productId).slice(0, 8);
  }, [products, productId]);

  useEffect(() => {
    new Swiper(".swiper", {
      modules: [Pagination],
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20,
        },
        1281: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 20,
        },
      },
    });
  }, []);

  return (
    <div className="p-4">
      <SEO
        title={`${product?.productName || "Product"} | ${store?.storeName}`}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={
          !!product?.productImages?.length
            ? product.productImages[0]
            : product?.productImage || store?.storeLogo
        }
      />
      <BusyOverlay loading={isLoading} />
      <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
      {product && <Product product={product} />}
      <h1 className="mb-6 text-2xl font-semibold md:text-40">
        You may also like
      </h1>

      <div className="swiper h-96 md:h-[450px]">
        <div className="swiper-wrapper">
          {recommendedProducts?.map((product, index) => (
            <div key={index} className="swiper-slide">
              <ProductCard product={product} className="h-auto" />
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
}

export default ProductPage;
