import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../constants/assets-constants";

const PageDisplay = ({ linkTitle, link }) => {
  return (
    <div className="my-4 flex gap-2 sm:my-8">
      <Link to="/settings">
        <p className="text-[0.8rem] font-[500] text-green-100">Settings</p>
      </Link>
      <img src={ArrowRight} alt="arrow" className="h-[20px] w-[20px]" />
      <Link to={link}>
        <p className="text-[0.8rem] font-[500] text-neutral-100">{linkTitle}</p>
      </Link>
    </div>
  );
};

export default PageDisplay;
