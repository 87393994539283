import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import BusyOverlay from "../../../components/commons/busy-overlay";
import SEO from "../../../components/commons/seo";
import { useGetCustomers } from "../../../operations/queries";
import { userState } from "../../../recoil/atoms";
import CustomerDetails from "./CustomerDetails";
import CustomerOrders from "./CustomerOrders";

function CustomerDetailsPage() {
  const { customerId } = useParams();
  const { store } = useRecoilValue(userState);
  const { data, isLoading } = useGetCustomers({
    storeId: store.id,
    search: customerId,
    page: 1,
    pageSize: 10,
  });

  const customer = data?.customers?.[0]?.shipping;

  if (isLoading) return <BusyOverlay loading={isLoading} />;

  return (
    <div>
      <SEO title="Customer Details" />
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Customers",
            path: "/customers",
          },
          {
            name: "Customer Details",
            path: "/customers/customer-details",
          },
        ]}
      />
      <div className="mb-4" />
      {customer ? (
        <>
          <CustomerDetails customer={customer} />
          <CustomerOrders customer={customer} />
        </>
      ) : (
        <p>Customer not found</p>
      )}
    </div>
  );
}

export default CustomerDetailsPage;
