import { Menu } from "@headlessui/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Ellipses } from "../../constants/assets-constants";
import { usePopper } from "../../utils/usePopper";

export const MenuWithPopover = ({ children }) => {
  let [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        as="button"
        ref={trigger}
        variant="outline"
        className="h-8 w-8 bg-transparent p-2 text-dark-100 shadow-none hover:bg-slate-100 hover:text-slate-900"
      >
        <img src={Ellipses} alt="ellipses" className="h-4 w-4 shrink-0" />
      </Menu.Button>

      <Portal>
        <Menu.Items
          as="div"
          ref={container}
          className="divide-y divide-neutral-300 rounded-md border border-neutral-300 bg-white shadow-lg outline-none"
        >
          {children}
        </Menu.Items>
      </Portal>
    </Menu>
  );
};

function Portal({ children }) {
  let [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return createPortal(children, document.body);
}
