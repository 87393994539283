import React from "react";

function WarningIcon(props) {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
      {...props}
    >
      <path d="M4.5 7H5.5V8H4.5V7ZM4.5 3H5.5V6H4.5V3ZM5 0.5C2.235 0.5 0 2.75 0 5.5C0 6.82608 0.526784 8.09785 1.46447 9.03553C1.92876 9.49983 2.47995 9.86812 3.08658 10.1194C3.69321 10.3707 4.34339 10.5 5 10.5C6.32608 10.5 7.59785 9.97322 8.53553 9.03553C9.47322 8.09785 10 6.82608 10 5.5C10 4.84339 9.87067 4.19321 9.6194 3.58658C9.36812 2.97995 8.99983 2.42876 8.53553 1.96447C8.07124 1.50017 7.52005 1.13188 6.91342 0.880602C6.30679 0.629329 5.65661 0.5 5 0.5ZM5 9.5C3.93913 9.5 2.92172 9.07857 2.17157 8.32843C1.42143 7.57828 1 6.56087 1 5.5C1 4.43913 1.42143 3.42172 2.17157 2.67157C2.92172 1.92143 3.93913 1.5 5 1.5C6.06087 1.5 7.07828 1.92143 7.82843 2.67157C8.57857 3.42172 9 4.43913 9 5.5C9 6.56087 8.57857 7.57828 7.82843 8.32843C7.07828 9.07857 6.06087 9.5 5 9.5Z" />
    </svg>
  );
}

export default WarningIcon;
