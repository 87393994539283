export const updatedProducts = (cart, product) => {
  // Find a specific product in the cart
  const cartProduct = cart.products.find(item => {
    // Get the product variants from the cart item and the product
    const cartVariants = item.productVariants;
    const productVariants = product.productVariants;

    // Check if the product IDs match
    const productsMatch = item.productId.id === product.productId;

    // If either the cart item or the product has no variants, return the productsMatch value
    if (cartVariants.length === 0 || productVariants.length === 0)
      return productsMatch;

    // Check if the two arrays of variants are the same
    const variantsMatch = cartVariants.every(variant => {
      const productVariant = productVariants.find(
        item => item.variantHeader === variant.variantHeader,
      );
      return productVariant?.variantName === variant.variantName;
    });

    // Return true if both the product IDs and variant names match
    return productsMatch && variantsMatch;
  });
  // Update quantity if product exists
  if (cartProduct) {
    return cart.products.map(item => {
      const updatedItem = { ...item, productId: item.productId.id };
      if (updatedItem.productId === product.productId)
        return { ...updatedItem, quantity: item.quantity + product.quantity };
      return updatedItem;
    });
  }
  const productWithIds = cart.products.map(item => ({
    ...item,
    productId: item.productId.id,
  }));
  // Add product if it does not exist
  return [...productWithIds, product];
};

export const getDefaultVariants = productVariants => {
  return productVariants.map(item => ({
    variantHeader: item.variantHeader,
    variantName: item.variants[0].variantName,
    additionalPrice: item.variants[0].additionalPrice ?? 0,
  }));
};

export const MAX_PRICE = 10000000; // 10 million
