import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { NigerianFlag } from "../../constants/assets-constants";
import useDebounce from "../../hooks/useDebounce";
import { useGetStoreOrders } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import formatPrice from "../../utils/formatPrice";
import DataTable from "../commons/data-table";
import SearchInput from "../commons/search-input";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("orderReference", {
    cell: info => info.getValue(),
    header: "Order Reference",
  }),
  columnHelper.accessor("createdAt", {
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    header: "Date Added",
  }),
  columnHelper.accessor("orderCustomer", {
    header: "Customer",
    cell: info => {
      const customer = JSON.parse(info.getValue());
      const customerName = customer.name || customer.fullNames;
      return (
        <p className="flex items-center gap-2">
          <span className="grid w-6 h-6 leading-none text-white bg-green-100 rounded-full shrink-0 place-items-center">
            {customerName?.slice(0, 1)}
          </span>
          <span className="flex-1">{customerName}</span>
        </p>
      );
    },
  }),
  columnHelper.accessor("orderStatus", { header: "Fulfillment" }),
  columnHelper.accessor("paymentStatus", {
    header: "Payment Status",
    cell: info => (
      <div className="flex items-center gap-2.5">
        <div className="h-1.5 w-1.5 shrink-0 rounded-full bg-green-600" />
        <p className="text-xs font-medium leading-none text-neutral-100">
          {info.getValue()}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor("orderAmount", {
    header: "Total",
    cell: info => formatPrice(info.getValue()),
  }),
  columnHelper.accessor("flag", {
    header: "",
    cell: () => <img src={NigerianFlag} className="w-6 h-4" alt="flag" />,
  }),
];

export const OrderBody = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const debouncedSearch = useDebounce(search, 1000);
  const { store } = useRecoilValue(userState);
  const page = searchParams.get("page") ?? 1;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: parseInt(page) - 1,
    pageSize: 10,
  });
  const { data, isLoading, isPreviousData } = useGetStoreOrders({
    storeId: store.id,
    search: debouncedSearch,
    page: pageIndex + 1,
    pageSize,
  });

  const orders = data?.data?.data?.orders ?? [];
  const pagination = data?.data?.data?.pagination;

  useEffect(() => {
    setSearchParams(prev => {
      prev.set("page", pageIndex + 1);
      return prev;
    });
  }, [pageIndex, setSearchParams]);

  return (
    <div>
      <div className="flex justify-end py-3">
        <SearchInput
          placeholder="Search"
          query={search}
          setQuery={value => {
            setPagination({ pageIndex: 0, pageSize: 10 });
            searchParams.set("search", value);
            setSearchParams(searchParams);
          }}
          containerClass="w-full sm:w-auto"
        />
      </div>
      <DataTable
        data={orders}
        columns={columns}
        handleRowClick={order => {
          navigate(`/orders/${order.id}`);
        }}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={pagination?.total}
        isLoading={isLoading || isPreviousData}
        setPagination={setPagination}
      />
    </div>
  );
};
