import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NoImageAvailable } from "../../constants/assets-constants";
import useDebounce from "../../hooks/useDebounce";
import { useUpdateCartMutation } from "../../operations/mutations";
import formatPrice from "../../utils/formatPrice";
import IconButton from "../commons/icon-button";
import { CloseIcon } from "../icons";

const MIN = 1;
const MAX = 100;

function CartItem({ product, cart, handleIsLoading }) {
  const { id, productName, productImage, productImages } = product.productId;
  const { storeUrl } = useParams();
  const [quantity, setQuantity] = useState(product.quantity);
  const debouncedQuantity = useDebounce(quantity, 1000);
  const { mutate: updateCart, isLoading } = useUpdateCartMutation();

  const handleRemove = () => {
    updateCart({
      ...cart,
      id: cart._id,
      shipping: cart.shipping?.id || null,
      products: cart.products
        .filter(item => {
          const productsMatch = item.productId.id === id;
          const cartVariants = item.productVariants;
          const productVariants = product.productVariants;
          if (cartVariants.length === 0 || productVariants.length === 0)
            return !productsMatch;
          const variantsMatch = cartVariants.every(variant => {
            const productVariant = productVariants.find(
              item => item.variantHeader === variant.variantHeader,
            );
            return productVariant?.variantName === variant.variantName;
          });
          return !(productsMatch && variantsMatch);
        })
        .map(item => ({ ...item, productId: item.productId.id })),
    });
  };

  const handleChange = e => {
    if (e.target.value < MIN) setQuantity(MIN);
    else if (e.target.value > MAX) setQuantity(MAX);
    else setQuantity(Number(e.target.value));
  };

  useEffect(() => {
    if (debouncedQuantity === product.quantity) return;
    updateCart({
      ...cart,
      id: cart._id,
      shipping: cart.shipping?.id || null,
      products: cart.products.map(item => {
        const productsMatch = item.productId.id === id;
        const cartVariants = item.productVariants;
        const productVariants = product.productVariants;
        const noVariants =
          cartVariants.length === 0 || productVariants.length === 0;
        const updatedItem = { ...item, productId: item.productId.id };
        if (noVariants) {
          if (!productsMatch) return updatedItem;
          return { ...updatedItem, quantity: debouncedQuantity };
        }
        const variantsMatch = cartVariants.every(variant => {
          const productVariant = productVariants.find(
            item => item.variantHeader === variant.variantHeader,
          );
          return productVariant?.variantName === variant.variantName;
        });
        if (productsMatch && variantsMatch)
          return { ...updatedItem, quantity: debouncedQuantity };
        return updatedItem;
      }),
    });
  }, [id, cart, product, debouncedQuantity, updateCart]);

  useEffect(() => {
    handleIsLoading(isLoading);
  }, [handleIsLoading, isLoading]);

  return (
    <div className="flex flex-col items-center gap-8 text-dark-100 md:h-52 md:flex-row">
      <img
        src={
          productImages.length > 0
            ? productImages[0]
            : productImage || NoImageAvailable
        }
        alt="product"
        className="h-72 w-full object-cover md:h-full md:w-52"
      />
      <div className="flex h-full w-full grow items-center justify-between gap-8 md:w-auto">
        <div>
          <Link to={`/${storeUrl}/product/${id}`}>
            <p className="text-lg hover:underline md:text-2xl">{productName}</p>
          </Link>
          <div className="flex flex-wrap items-center gap-2">
            {product.productVariants.map((variant, index) => (
              <p className="text-sm md:text-lg" key={index}>
                {variant.variantHeader} - {variant.variantName}
              </p>
            ))}
          </div>
          <p className="flex items-center gap-1 text-base md:text-xl">
            {formatPrice(parseFloat(product.totalPrice) * product.quantity)}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <input
            type="number"
            className="h-12 w-12 border-neutral-300 p-0 text-center text-lg md:text-2xl"
            value={quantity}
            onChange={handleChange}
            disabled={isLoading}
            min={MIN}
            max={MAX}
          />
          <IconButton
            variant="ghost"
            onClick={handleRemove}
            icon={<CloseIcon />}
            description="Remove item from cart"
            className="h-10 w-10 p-0"
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default CartItem;
