import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import BusyOverlay from "../../components/commons/busy-overlay";
import Pagination from "../../components/commons/pagination";
import ProductCard from "../../components/storefront/product-card";
import { useGetProductList, useGetStorefront } from "../../operations/queries";

const PAGE_SIZE = 13;

function Featured() {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") ?? 1;

  const store = data?.data?.data?.store;
  const { data: response, isLoading } = useGetProductList({
    storeId: store?.id,
    page,
    pageSize: PAGE_SIZE,
  });

  const products = response?.data?.data;
  const pagination = response?.data?.pagination;

  return (
    <div className="mb-10 px-4">
      <BusyOverlay loading={isLoading} />
      <div className="mb-6 flex items-center justify-between text-dark-100">
        <h1 className="text-2xl font-semibold md:text-40">Newest</h1>
        <Link to={`/${storeUrl}/all`} className="hover:underline">
          <p className="text-lg font-medium md:text-26">View all products</p>
        </Link>
      </div>
      <div className="mb-6 grid grid-cols-2 gap-4 sm:gap-6 lg:grid-cols-3 xl:grid-cols-4">
        {products?.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            className="first-of-type:col-span-2 first-of-type:row-span-2"
          />
        ))}
      </div>
      {pagination && (
        <Pagination
          currentPage={parseInt(pagination.page)}
          totalCount={pagination.total}
          pageSize={parseInt(pagination.pageSize)}
          onPageChange={page => setSearchParams(prev => ({ ...prev, page }))}
        />
      )}
    </div>
  );
}

export default Featured;
