import React from "react";

function SearchIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.531 17.117L22.814 21.399L21.399 22.814L17.117 18.531C15.5237 19.8082 13.542 20.5029 11.5 20.5C6.532 20.5 2.5 16.468 2.5 11.5C2.5 6.532 6.532 2.5 11.5 2.5C16.468 2.5 20.5 6.532 20.5 11.5C20.5029 13.542 19.8082 15.5237 18.531 17.117ZM16.525 16.375C17.7941 15.0699 18.5029 13.3204 18.5 11.5C18.5 7.632 15.367 4.5 11.5 4.5C7.632 4.5 4.5 7.632 4.5 11.5C4.5 15.367 7.632 18.5 11.5 18.5C13.3204 18.5029 15.0699 17.7941 16.375 16.525L16.525 16.375Z" />
    </svg>
  );
}

export default SearchIcon;
