import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import React, { Fragment } from "react";
import * as yup from "yup";
import { CloseIcon } from "../../constants/assets-constants";
import { useWithdrawMoney } from "../../operations/mutations";
import { useGetBankAccount, useGetCurrentUser } from "../../operations/queries";
import Button from "../commons/button";
import FormInput from "./../form/form-input";
import FormSelect from "./../form/form-select";
import { toast } from "react-toastify";

const WithdrawMoney = ({ isOpen, setIsOpen }) => {
  const { data: userData } = useGetCurrentUser();
  const balance = userData?.data?.data?.user?.balance ?? 0;
  const { data: bankAccounts } = useGetBankAccount();
  const { mutate: withdrawMoney, isLoading } = useWithdrawMoney(handleSuccess);

  const validationSchema = yup.object().shape({
    bankId: yup.object().required("Select Account"),
    withdrawalAmount: yup
      .number()
      .max(balance, "Cannot withdraw more than available balance")
      .required("Enter the amount"),
  });
  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      bankId: "",
      withdrawalAmount: 100,
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        if (values.withdrawalAmount < 100) {
          toast.error("Amount should be more than 100");
        } else {
          withdrawMoney({ ...values, bankId: values.bankId.value });
        }
      });
    },
  });

  function handleSuccess() {
    resetForm();
    setIsOpen(false);
  }

  const options = bankAccounts?.data?.data?.map(account => {
    return {
      value: account.id,
      label: `${account.accountName} ${account.accountNumber}`,
    };
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <form onSubmit={handleSubmit}>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-[48.938rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[1.25rem] font-bold text-[#1F1F1F]"
                  >
                    Withdraw Money
                  </Dialog.Title>
                  <img
                    src={CloseIcon}
                    alt="close-icon"
                    className="absolute right-[20px] top-[20px] cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                  <p className="my-[1.125rem] text-[0.875rem] font-bold text-[#1F1F1F]">
                    Cash Withdrawal
                  </p>
                  <FormSelect
                    name="bankId"
                    label="Select Account"
                    value={values.bankId}
                    onChange={selectedOption => {
                      setFieldValue("bankId", selectedOption ?? "");
                    }}
                    error={Boolean(errors.bankId)}
                    errorMsg={errors.bankId}
                    options={options}
                    openMenuOnFocus
                  />
                  <FormInput
                    name="withdrawalAmount"
                    value={values.withdrawalAmount}
                    placeholder="Enter Amount"
                    label="Amount"
                    error={Boolean(errors.withdrawalAmount)}
                    onChange={handleChange}
                    errorMsg={errors.withdrawalAmount}
                    className="my-[1.69rem]"
                    type="number"
                    max={balance}
                  />
                  <div className="mb-[2.38rem] mt-[1.69rem] flex justify-center">
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      disabled={Object.keys(errors).length > 0}
                    >
                      Withdraw
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </form>
      </Dialog>
    </Transition>
  );
};

export default WithdrawMoney;
