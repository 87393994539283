import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import { useGetStorefront } from "../../../operations/queries";
import SEO from "../../../components/commons/seo";

function AboutPage() {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: "About",
      path: `/${storeUrl}/about`,
    },
  ];

  return (
    <div className="mb-20 max-w-[900px] p-4 text-dark-100">
      <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
      <SEO title="About" />
      <div className="mb-6 sm:mb-10" />
      <h1 className="mb-6 text-2xl font-semibold sm:mb-10 md:text-4xl">
        About
      </h1>
      <p className="whitespace-pre-wrap text-base md:text-xl">
        {store?.storeDescription}
      </p>
    </div>
  );
}

export default AboutPage;
