import cn from "../../utils/cn";
import { CloseIcon } from "../icons";
import Button from "./button";

function Chip({ children, className, withIcon, variant = "ghost", ...props }) {
  return (
    <Button
      variant={variant}
      className={cn(
        "border border-neutral-300 rounded-full h-auto py-1 px-3 active:scale-100",
        className
      )}
      {...props}
    >
      {children}
      {withIcon && <CloseIcon className="w-4 h-4 ml-1" />}
    </Button>
  );
}

export default Chip;
