import { useFormik } from "formik";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import ButtonComponent from "../../../components/commons/buttons";
// import { CardIcon } from "../../../components/icons";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import BusyOverlay from "../../../components/commons/busy-overlay";
import CustomInput from "../../../components/commons/custom-inputs";
import CustomSelect from "../../../components/commons/custom-select";
import { useCreateOrderMutation } from "../../../operations/mutations";
import { useGetCart, useGetStorefront } from "../../../operations/queries";
import SEO from "../../../components/commons/seo";

const validationSchema = yup.object().shape({
  fullName: yup.string().required("What is your name?"),
  emailAddress: yup
    .string()
    .email("Please enter a valid email address")
    .required("What is your email address?"),
  country: yup.string().required("Select your country"),
  phoneNumber: yup.string().required("what is your phone number?"),
  state: yup.string().required("What is your state?"),
  address: yup.string(),
  postalCode: yup.string(),
  specialInstructions: yup.string(),
});

const checkout = localStorage.getItem("checkout");

const initialValues = checkout
  ? JSON.parse(checkout)
  : {
      emailAddress: "",
      country: "Nigeria",
      fullName: "",
      address: "",
      postalCode: "",
      state: "",
      specialInstructions: "",
      phoneNumber: "",
    };

function CheckoutPage() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: cartRes } = useGetCart(store?.id);
  const cart = cartRes?.data?.data;
  const { mutate: createOrder, isLoading } = useCreateOrderMutation();

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: "Cart",
      path: `/${storeUrl}/cart`,
    },
    {
      name: "Checkout",
      path: `/${storeUrl}/checkout`,
    },
  ];

  const { errors, values, validateForm, handleSubmit, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit: values => {
        validateForm(values).then(() => {
          if (!store || !cart) return;
          localStorage.setItem("storeUrl", storeUrl);
          localStorage.setItem("checkout", JSON.stringify(values));
          createOrder({ ...values, cartId: cart._id });
        });
      },
    });

  useEffect(() => {
    if (status === "cancelled") toast.error("Payment cancelled");
  }, [status]);

  return (
    <div className="mb-20 max-w-[600px] p-4">
      <SEO
        title={`Checkout | ${store?.storeName}`}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={store?.storeLogo}
      />
      <BusyOverlay loading={isLoading} />
      <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
      <div className="mb-10" />
      <h1 className="mb-10 text-2xl font-semibold md:text-4xl">Checkout</h1>
      <div>
        <h4 className="mb-4 text-base font-semibold md:text-xl">
          Contact Information
        </h4>
        <form onSubmit={handleSubmit}>
          <CustomInput
            type="email"
            id="emailAddress"
            name="emailAddress"
            value={values.emailAddress}
            placeholder="Your email address"
            error={Boolean(errors.emailAddress)}
            onChange={handleChange}
            label="Email address"
            errorMsg={errors.emailAddress}
            autoComplete="email"
          />
          <CustomSelect
            type="country"
            id="country"
            name="country"
            value={values.country}
            error={Boolean(errors.country)}
            onChange={handleChange}
            label="Country"
            errorMsg={errors.country}
            autoComplete="country"
          >
            <option value="Nigeria">Nigeria</option>
            <option value="International">International</option>
          </CustomSelect>

          <CustomInput
            type="text"
            id="fullName"
            name="fullName"
            value={values.fullName}
            placeholder="John Doe"
            error={Boolean(errors.fullName)}
            onChange={handleChange}
            label="Full name"
            errorMsg={errors.fullName}
            autoComplete="name"
          />

          <CustomInput
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber}
            placeholder="+239010020300"
            error={Boolean(errors.phoneNumber)}
            onChange={handleChange}
            label="Phone number"
            errorMsg={errors.phoneNumber}
            autoComplete="tel"
          />

          <CustomInput
            type="text"
            id="address"
            name="address"
            value={values.address}
            placeholder="Write your full address"
            error={Boolean(errors.address)}
            onChange={handleChange}
            label="Shipping address"
            errorMsg={errors.address}
            autoComplete="street-address"
          />
          <CustomInput
            type="text"
            id="postalCode"
            name="postalCode"
            value={values.postalCode}
            placeholder="000000"
            error={Boolean(errors.postalCode)}
            onChange={handleChange}
            label="Postal code"
            errorMsg={errors.postalCode}
            autoComplete="postal-code"
          />
          <CustomInput
            type="text"
            id="state"
            name="state"
            value={values.state}
            placeholder="Write your state"
            error={Boolean(errors.state)}
            onChange={handleChange}
            label="State"
            errorMsg={errors.state}
            autoComplete="address-level1"
          />
          <div>
            <div className="mb-6 ">
              <label
                htmlFor="specialInstructions"
                className="form-label mb-1 text-left text-xs font-medium text-dark-100 md:text-sm"
              >
                Special instructions (optional)
              </label>
              <textarea
                id="specialInstructions"
                name="specialInstructions"
                value={values.specialInstructions}
                onChange={handleChange}
                className="mb-10 min-h-[200px] w-full rounded border-neutral-300 p-4 px-3 py-1.5 text-10 text-dark-100"
                placeholder="Special instructors for seller"
              />
            </div>
          </div>

          <ButtonComponent
            type="submit"
            isBlack
            fullWidth
            btnText="Pay"
            className="border-transparent"
            style={{ backgroundColor: store?.brandColor }}
            disabled={
              !cart ||
              cart.products.length === 0 ||
              Object.keys(errors).length > 0
            }
          />
        </form>
      </div>
    </div>
  );
}

export default CheckoutPage;
