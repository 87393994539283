import React from "react";
import { NoImageAvailable } from "../../constants/assets-constants";

export const OrderPictureAndName = ({ orders }) => {
  const parsedDetails = orders?.orderDetail
    ? JSON.parse(orders?.orderDetail)
    : [];
  const productDetails = parsedDetails.hasOwnProperty("cart" || "shipping")
    ? parsedDetails?.cart
    : [];

  return (
    <div className="p-4 mt-8 border rounded-lg sm:mt-10 lg:flex-row">
      <div className="flex flex-wrap gap-4">
        {productDetails.map((detail, index) => {
          return (
            <ProductCard
              key={index}
              title={detail?.productId?.productName}
              productVariants={detail?.productVariants}
              image={
                detail?.productId?.productImages?.length > 0
                  ? detail?.productId?.productImages
                  : detail?.productId?.productImage
                  ? [detail?.productId?.productImage]
                  : []
              }
            />
          );
        })}
      </div>
    </div>
  );
};

const ProductCard = ({ title, image, productVariants }) => {
  return (
    <div>
      <p className="text-[1.5rem] font-bold text-[#111827]">{title}</p>
      <div className="flex flex-wrap items-center gap-2">
        {productVariants?.map((variant, index) => (
          <p className="text-sm md:text-lg" key={index}>
            {variant.variantHeader} - {variant.variantName}
          </p>
        ))}
      </div>
      <div className="grid grid-cols-3 gap-[1rem]">
        {!image || image.length < 1 ? (
          <img
            className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563rem] rounded-[8px] object-cover"
            alt="NoImage"
            src={NoImageAvailable}
          />
        ) : (
          image.map((image, index) => (
            <img
              className="mb-[0.625rem] mt-[1rem] h-[6.938rem] w-[5.563rem] rounded-[8px] object-cover"
              src={image}
              key={index}
              alt="i"
            />
          ))
        )}
      </div>
    </div>
  );
};
