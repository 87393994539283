import React from "react";
import { FormInput } from "../form";

const StockDigital = ({ values, errors, handleChange, ...Props }) => {
  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        Stock & Inventory
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        To start selling, all you need is a name, price and image
      </p>
      <p className="mb-[0.94rem] mt-[3.25rem] text-[0.875rem] font-bold text-[#1F1F1F]">
        General
      </p>
      <p className="mt-[0.94rem] max-w-[22.5rem] text-[0.75rem] font-normal text-[#525252]">
        Give your product a short and clear name. 50-60 characters is the
        recommended length for search engines.
      </p>
      <div className="mb-[1.88rem] mt-[2.25rem] grid items-start gap-6 sm:grid-cols-2">
        <FormInput
          id="productSKU"
          name="productSKU"
          value={values.productSKU}
          placeholder="SUN-G, JK1234..."
          error={Boolean(errors.productSKU)}
          onChange={handleChange}
          label="Store Keeping Unit (SKU)"
          errorMsg={errors.productSKU}
          isRequired
        />
        <FormInput
          id="productQuantity"
          name="productQuantity"
          value={values.productQuantity}
          placeholder="100"
          error={Boolean(errors.productQuantity)}
          onChange={handleChange}
          label="Quantity in Stock"
          errorMsg={errors.productQuantity}
        />
      </div>
    </div>
  );
};

export default StockDigital;
