import React from "react";
import { Link } from "react-router-dom";
import {
  DigibleLogo,
  FacebookBlack,
  InstagramBlack,
  TwitterBlack,
} from "../../constants/assets-constants";

const footerLists = [
  {
    title: "About us",
    lists: [
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
      },
      {
        name: "Terms of Use",
        path: "/terms-of-use",
      },
      {
        name: "Acceptable Use Policy",
        path: "/acceptable-use-policy",
      },
    ],
  },
  {
    title: "Contact us",
    lists: [
      {
        name: "Digibleonline@gmail.com",
        path: "mailto:Digibleonline@gmail.com",
      },
    ],
  },
];

const socialLinks = [
  {
    icon: FacebookBlack,
    path: "https://www.facebook.com/profile.php?id=100083037197148&mibextid=LQQJ4d",
  },
  {
    icon: InstagramBlack,
    path: "https://instagram.com/digible_one?igshid=YmMyMTA2M2Y=",
  },
  {
    icon: TwitterBlack,
    path: "https://twitter.com/digible6?s=21&t=m4cr9VpOL7m4JdPvOd5hnA",
  },
];

function FooterComponent() {
  return (
    <div className="bg-dark-200  ">
      <div className="px-1/6 py-10  ">
        <div className="grid grid-cols-12  ">
          <div className="col-span-12 mb-8 md:col-span-4 md:mb-0">
            <Link to="/">
              <img className="object-contain" src={DigibleLogo} alt="" />
            </Link>
          </div>
          {footerLists.map((link, index) => {
            return (
              <div
                key={index}
                className="col-span-6 mx-1/10 text-left md:col-span-2"
              >
                <Link to="/about">
                  <h5 className="mb-4 text-17 font-medium leading-17 text-white">
                    {link.title}
                  </h5>
                </Link>

                <ul className="flex flex-col space-y-4">
                  {link.lists.map((list, index) => {
                    return (
                      <li key={index}>
                        <Link to={list.path}>
                          <p className="text-xs text-white lg:text-base">
                            {list.name}
                          </p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {link.title.includes("Contact") && (
                  <div className="mt-10 flex w-full items-end justify-start space-x-3 md:hidden">
                    {socialLinks.map((socialLink, index) => {
                      return (
                        <div
                          key={index}
                          className="flex h-6 w-6 items-center justify-center rounded-full bg-white shadow-lg "
                        >
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={socialLink.path}
                          >
                            <img src={socialLink.icon} alt="" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          <div className=" col-span-4 hidden items-end justify-center space-x-3 md:flex">
            {socialLinks.map((socialLink, index) => {
              return (
                <div
                  key={index}
                  className="flex h-34 w-34 items-center justify-center rounded-full bg-white shadow-lg "
                >
                  <a target="_blank" rel="noreferrer" href={socialLink.path}>
                    <img src={socialLink.icon} alt="" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full border-t border-neutral-250 py-4 text-center md:py-6">
        <small className="text-10 text-white md:text-13">
          Copyright ® 2022 Digible. All rights Reserved
        </small>
      </div>
    </div>
  );
}

export default FooterComponent;
