import React from "react";
import ButtonComponent from "../../components/commons/buttons";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import { MarketPlaceMockup } from "../../constants/assets-constants";
import { useNavigate } from "react-router-dom";

function MarketPlace() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col-reverse items-center justify-start w-full h-auto py-10 text-left px-1/6 md:px-1/10 md:flex-row md:justify-between lg:py-20 md:h-900 ">
      <div className="h-full mt-10 w-1/94 md:w-1/2 lg:w-2/5 md:mt-0">
        <img
          className="object-contain w-full h-full"
          src={MarketPlaceMockup}
          alt=""
        />
      </div>
      <div className="w-full text-left md:w-2/5 lg:w-1/2">
        <TitleComponent isMedium text="Marketplace" />
        <ContentComponent text="List, Promote and Discover Products on our Marketplace, Boost Sales and improve discoverability of your products." />
        <div className="mt-10 lg:mt-20" />
        <ButtonComponent
          isGreen
          callToAction={() => navigate("/marketplace")}
          btnText="Marketplace"
        />
      </div>
    </div>
  );
}

export default MarketPlace;
