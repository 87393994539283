import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { StarIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Chip from "../../../components/commons/chip";
import { ProductItems } from "../../../components/marketplace/MarketPlaceBody";
import {
  AddButton,
  BookmarkIcon,
  MinusButton,
  SneakersDisplay,
  SneakersDisplay1,
} from "../../../constants/assets-constants";
import { cartMarketplaceState } from "../../../recoil/atoms";
import formatPrice from "../../../utils/formatPrice";
import SEO from "../../../components/commons/seo";
// import required modules
// import { Pagination } from "swiper/modules";

const Images = [`${SneakersDisplay}`, `${SneakersDisplay1}`];
const COLOURS = ["Gray", "Green", "Pink", "Red"];

export const Marketplacedetails = () => {
  const setCart = useSetRecoilState(cartMarketplaceState);
  const cart = useRecoilValue(cartMarketplaceState);
  const reset = useResetRecoilState(cartMarketplaceState);
  const [currentColour, setCurrentColour] = useState(COLOURS[0]);
  const [rating, setRating] = useState(5);
  const [qty, setQty] = useState(12);

  const decreaseQty = () => {
    if (qty <= 0) {
      setQty(0);
    } else {
      setQty(qty - 1);
    }
  };

  const increaseQty = () => {
    setQty(qty + 1);
  };

  const onChange = e => {
    if (typeof e.target.value === "undefined") {
      setQty(0);
    } else {
      const value = parseInt(e.target.value);
      setQty(value);
    }
  };

  const { id } = useParams();

  const Product = ProductItems.find(product => product.id === id);

  const addToCart = () => {
    setCart(prev => {
      const exists = prev.find(item => item.Product.name === Product.name);
      if (!exists) return [...prev, { Product, quantity: 1 }];
      return prev.map(item =>
        item.Product.name === Product.name
          ? {
              ...item,
              quantity: item.quantity + 1,
            }
          : item,
      );
    });

    toast.success("Product added successfully");
  };

  const ratings = [
    {
      title: "5 stars",
      rating: "w-[96%]",
      percentage: "96%",
    },
    {
      title: "4 stars",
      rating: "w-[2%]",
      percentage: "1%",
    },
    {
      title: "3 stars",
      rating: "w-[1%]",
      percentage: "1%",
    },
    {
      title: "2 stars",
      rating: "w-[0%]",
      percentage: "0%",
    },
    {
      title: "1 star",
      rating: "w-[1%]",
      percentage: "1%",
    },
  ];
  return (
    <div>
      <div className="my-[2rem] rounded-[8px] px-[2rem] lg:px-[0rem]">
        <SEO title="MarketPlace Details" />
        <Swiper
          cssMode
          pagination
          mousewheel
          keyboard
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide>
            <ImageContainer image={Product.image} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="my-[1.5rem] flex flex-col gap-4 p-4 sm:gap-6 sm:p-[2rem] lg:flex-row">
        {/* title and details */}
        <div className="flex-1 pr-[0.2rem]">
          <p className="font-Metropolis-Bold text-xl text-[#1F1F1F] sm:text-[2.4375rem]">
            {Product.name}
          </p>
          <div className="mt-[0.5rem] flex gap-[0.3rem]">
            <p className="font-Metropolis-Medium text-lg text-[#1F1F1F] sm:text-[1.875rem]">
              {formatPrice(51000)}
            </p>
          </div>
          <div className="mt-[1rem] flex items-center gap-[0.3rem]">
            <StarIcon className="h-6 w-6 fill-[#D0E83D] sm:h-[1.875rem] sm:w-[1.875rem]" />
            <StarIcon className="h-6 w-6 fill-[#D0E83D] sm:h-[1.875rem] sm:w-[1.875rem]" />
            <StarIcon className="h-6 w-6 fill-[#D0E83D] sm:h-[1.875rem] sm:w-[1.875rem]" />
            <StarIcon className="h-6 w-6 fill-[#D0E83D] sm:h-[1.875rem] sm:w-[1.875rem]" />
            <StarIcon className="h-6 w-6 fill-[#D0E83D] sm:h-[1.875rem] sm:w-[1.875rem]" />
            <p className="font-Metropolis-Medium text-base text-[#525252] sm:text-[1.5rem]">
              780 ratings
            </p>
          </div>
          <div className="my-[1rem] border-t-[1px] border-[#DCDCDC]"></div>
          <p className="font-Metropolis-Medium max-w-[47.5rem] text-base leading-[1.8rem] text-[#525252] sm:text-[1.5rem]">
            Lorem ipsum dolor sit amet consectetur. Libero sollicitudin auctor
            enim tristique varius odio auctor orci. Hendrerit condimentum varius
            et non laoreet donec dictumst. Turpis justo varius fermentum
            ultrices ac. Imperdiet nulla malesuada ornare placerat dui egestas.
          </p>
          <div className="mt-[2rem] flex items-center gap-[0.3rem]">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <StarIcon
                  className={`${
                    index <= rating ? "fill-[#0FAA8E]" : "fill-[#000]"
                  } h-6 w-6 cursor-pointer sm:h-[1.875rem] sm:w-[1.875rem]`}
                  key={index}
                  onClick={() => setRating(index)}
                />
              );
            })}
            <p className="font-Metropolis-Medium ml-[0.3rem] text-base text-[#525252] sm:text-[1.5rem]">
              Rate The Product
            </p>
          </div>
        </div>
        {/* quantity,color and ratings */}
        <div className="flex-1 border-none border-[#DCDCDC] lg:border-l-[1px]">
          <div className="flex-1">
            <p className="font-Metropolis-Medium mb-1 mt-[1.5rem] text-lg text-[#1F1F1F] sm:text-[1.5rem]">
              Quantity
            </p>
            <div className="relative flex w-full justify-between md:w-[31.875rem] lg:w-full">
              <input
                type="text"
                placeholder="1"
                onChange={onChange}
                value={qty}
                className="h-[3.5rem] w-full rounded-[0.375rem] border-[1px] border-[#898989]"
              />
              <div className="absolute right-5 top-4 flex gap-[0.5rem]">
                <div className="cursor-pointer" onClick={increaseQty}>
                  <img src={AddButton} alt="icon" />
                </div>
                <div className="cursor-pointer" onClick={decreaseQty}>
                  <img src={MinusButton} alt="icon" />
                </div>
              </div>
              {/* <div></div> */}
            </div>
            <button
              className="my-[1.5rem] h-[3.25rem] w-full rounded-[0.375rem] bg-[#101010] text-[#fff] md:w-[20rem] lg:w-full"
              onClick={addToCart}
            >
              Add to Cart
            </button>
            <p className="font-Metropolis-Medium text-lg text-[#1F1F1F] sm:text-[1.5rem]">
              Colours
            </p>
            <div className="my-[1rem] flex gap-[1rem]">
              <div className="flex gap-3">
                {COLOURS.map((colour, index) => (
                  <Chip
                    key={index}
                    onClick={() => setCurrentColour(colour)}
                    className={
                      currentColour === colour
                        ? "border-none ring-2 ring-green-100 focus:ring-green-100"
                        : "ring-neutral-300"
                    }
                  >
                    {colour}
                  </Chip>
                ))}
              </div>
            </div>

            <div className="mb-4 flex justify-between">
              <p className="font-Metropolis-Medium text-lg text-[#000] sm:text-[1.5rem]">
                Ratings
              </p>
              <div className="flex gap-[0.3rem]">
                <StarIcon className="h-[1.875rem] w-[1.875rem] fill-[#D0E83D]" />
                <div className="flex items-center">
                  <div className="flex gap-[0.3rem]">
                    <p className="font-Metropolis-Regular text-[1rem] text-[#000]">
                      4.9
                    </p>
                    <p className="font-Metropolis-Regular text-[1rem] text-[#000]">
                      (761 ratings)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {ratings.map((r, index) => {
              return (
                <div className="mb-[1rem] flex gap-[0.5rem]" key={index}>
                  <div className="flex-1 items-center">
                    <p className="font-Metropolis-Regular text-[1.125rem] text-[#000]">
                      {r.title}
                    </p>
                  </div>
                  <div className="flex-[3]">
                    <div className="h-[1.5625rem] w-full rounded-[0.375rem] border-[1px] border-[#898989]">
                      <div
                        className={`!important ${r.rating} h-[1.5625rem] rounded-[0.375rem] bg-[#0FAA8E]`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-1 justify-end">
                    <p>{r.percentage}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <style>
        {`
        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span.swiper-pagination-bullet.swiper-pagination-bullet-active{
          background: #0FAA8E;
        }
        span.swiper-pagination-bullet{
          background: #fff;
        }
        `}
      </style>
    </div>
  );
};

const ImageContainer = ({ image }) => {
  return (
    <div className="sm:[h-30rem] relative h-[20rem] w-full md:h-[40rem]">
      <img src={image} alt="background" className="relative" />
      <img
        src={BookmarkIcon}
        alt="bookmark"
        className="absolute right-[1.14rem] top-[1.14rem] !h-[3rem] !w-[3rem]"
      />
    </div>
  );
};
