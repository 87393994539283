import React from "react";
import { Link } from "react-router-dom";
import BusyOverlay from "../../../components/commons/busy-overlay";
import PinInput from "../../../components/commons/pin-input";
import SEO from "../../../components/commons/seo";
import TitleComponent from "../../../components/commons/title-component";
import { DigibleLogo } from "../../../constants/assets-constants";
import AuthLayoutWrapper from "../../../layouts/AuthLayout";
import { useActivateUserMutation } from "../../../operations/mutations";

function VerifyNewAccount() {
  const { mutate: activateUser, isLoading } = useActivateUserMutation();
  const email = localStorage.getItem("userEmail");

  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="Verify Account" />
      <AuthLayoutWrapper isSignUp>
        <div className="space-y-10 text-center">
          <div className="flex justify-center">
            <Link to="/">
              <img
                className="w-40 object-contain lg:w-auto"
                src={DigibleLogo}
                alt=""
              />
            </Link>
          </div>

          <TitleComponent text="Verify your account" />
          <div className="w-full">
            <p className="mb-4 text-10 leading-14 text-neutral-100 md:text-xs">
              Please enter the code sent to <strong>{email}</strong>
            </p>
            <PinInput
              onComplete={code => activateUser({ token: code, email: email })}
            />
          </div>
        </div>
      </AuthLayoutWrapper>
    </>
  );
}

export default VerifyNewAccount;
