import React from "react";
import { Marketplacebackground } from "../../constants/assets-constants";
import { MarketPlaceBody } from "../../components/marketplace/MarketPlaceBody";
import SEO from "../../components/commons/seo";

const MarketPlace = () => {
  return (
    <div>
      <div className="my-[2rem] px-[1rem] lg:px-[3.56rem]">
        <SEO title="Marketplace"/>
        <img
          src={Marketplacebackground}
          alt="background"
          className="w-full object-cover lg:h-[21rem] h-[10rem]"
        />
        <MarketPlaceBody />
      </div>
    </div>
  );
};

export default MarketPlace;
