import React from "react";
import SEO from "../../components/commons/seo";
import WithdrawalBody from "../../components/withdrawal/WithdrawalBody";
import WithdrawalHeader from "../../components/withdrawal/WithdrawalHeader";

const Withdrawal = () => {
  return (
    <div>
      <SEO title="Withdrawals" />
      <WithdrawalHeader />
      <WithdrawalBody />
    </div>
  );
};

export default Withdrawal;
