import React from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import FormSelect from "../../../components/form/form-select";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import SEO from "../../../components/commons/seo";

const CurrencyPage = () => {
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <PageDisplay linkTitle="Currencies" />
      <SEO title="Currency" />
      <div className="w-full rounded-[0.42875rem]  border-[1px] border-neutral-300 md:w-[36rem]">
        <div className="p-4 sm:p-8">
          <PageTitle
            title="Currencies"
            description="Manage the currencies that you will operate in"
            buttontitle="Add currencies"
          />
          <FormSelect
            name="currency"
            //   value={values.currency}
            //   error={Boolean(errors.currency)}
            //   onChange={handleChange}
            label="Default store currency"
            className="relative mb-[0.62rem]"
            //   errorMsg={errors.selectCurrency}
            labelIcon={
              <ArrowDownIcon className="absolute right-[20px] h-[1.5rem] w-[1.5rem]" />
            }
          >
            <option value="">NGN</option>
            <option value="NGN">Pounds</option>
            <option value="USD">Dollars</option>
          </FormSelect>
          <FormSelect
            name="currency"
            //   value={values.currency}
            //   error={Boolean(errors.currency)}
            //   onChange={handleChange}
            label="Store currencies"
            className="relative mb-[0.62rem]"
            //   errorMsg={errors.selectCurrency}
            labelIcon={
              <ArrowDownIcon className="absolute right-[20px] h-[1.5rem] w-[1.5rem]" />
            }
          >
            <option value="">NGN,USD, EUR</option>
          </FormSelect>
        </div>
        <div className="border-t-[1px] border-neutral-300 py-[2rem]">
          <div className="flex justify-end">
            <div className="flex gap-[1rem] pr-[2rem]">
              <button className="gap-[10px] rounded-md border px-[1.0rem] py-[0.7rem] text-dark-100">
                <p className="font-dark-100 text-[0.75rem] font-[500]">
                  Cancel changes
                </p>
              </button>
              <button className="gap-[10px] rounded-md border bg-green-100 px-[2.0rem] py-[0.7rem]">
                <p className="text-[0.75rem] font-[500] text-[#fff]">Save</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyPage;
