import React, { useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import DataTable from "../../components/commons/data-table";
import SearchInput from "../../components/commons/search-input";
import useDebounce from "../../hooks/useDebounce";
import { useGetCustomers } from "../../operations/queries";
import { userState } from "../../recoil/atoms";

const columns = [
  {
    accessorKey: "shipping.createdAt",
    header: () => "Date added",
    cell: info =>
      Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }).format(new Date(info.getValue())),
  },
  {
    accessorKey: "shipping.fullName",
    header: () => "Name",
    cell: info => info.getValue(),
  },
  {
    accessorKey: "shipping.emailAddress",
    header: () => "Email",
    cell: info => info.getValue(),
  },
  {
    accessorKey: "shipping.address",
    header: () => "Address",
    cell: info => info.getValue(),
  },
];

function CustomersTab() {
  const { store } = useRecoilValue(userState);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: parseInt(page) - 1,
    pageSize: 10,
  });
  const search = searchParams.get("search") ?? "";
  const debouncedSearch = useDebounce(search, 1000);

  const { data, isLoading, isPreviousData } = useGetCustomers({
    storeId: store.id,
    search: debouncedSearch,
    page: pageIndex + 1,
    pageSize,
  });
  const customers = useMemo(() => data?.customers ?? [], [data]);
  const pagination = data?.pagination;

  return (
    <>
      <div className="mb-6 flex justify-end">
        <SearchInput
          placeholder="Search"
          query={search}
          setQuery={value => {
            setPagination({ pageIndex: 0, pageSize: 10 });
            searchParams.set("search", value);
            setSearchParams(searchParams);
          }}
          containerClass="w-full sm:w-auto"
          iconClass="h-4 w-4 sm:h-5 sm:w-5"
          inputClass="text-sm"
        />
      </div>
      <DataTable
        label="Customers"
        data={customers}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={pagination?.total}
        handleRowClick={customer => {
          navigate(`/customers/${customer._id}`);
        }}
        isLoading={isLoading || isPreviousData}
        setPagination={setPagination}
      />
    </>
  );
}

export default CustomersTab;
