import { Menu } from "@headlessui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  DeleteIcon,
  EditIcon,
  NoImageAvailable,
  ViewIcon,
} from "../../constants/assets-constants";
import useDebounce from "../../hooks/useDebounce";
import { useDeleteProductMutation } from "../../operations/mutations";
import { useGetProductList } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import cn from "../../utils/cn";
import { MenuWithPopover } from "../commons/MenuWithPopover";
import DataTable from "../commons/data-table";
import SearchInput from "../commons/search-input";
import NotificationModal from "../header/NotificationModal";
import PlanModal from "./PlanModal";

const ProductBodyTable = () => {
  const navigate = useNavigate();
  const [displayModal, setDisplayModal] = useState(false);
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { store } = useRecoilValue(userState);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: parseInt(page) - 1,
    pageSize: 10,
  });
  const { mutate: deleteProduct } = useDeleteProductMutation();
  const search = searchParams.get("search") ?? "";
  const debouncedSearch = useDebounce(search, 1000);

  const { data, isLoading, isPreviousData } = useGetProductList({
    storeId: store.id,
    search: debouncedSearch,
    page: pageIndex + 1,
    pageSize,
  });

  const products = useMemo(() => {
    if (!data?.data?.data) return [];
    return data.data.data.map(product => {
      const {
        productName,
        id,
        productCollection,
        productImages,
        productImage,
        productStatus,
        productQuantity,
      } = product;
      const inventory = `${
        productQuantity || "Nothing"
      } in stock for variant(s)`;
      return {
        name: productName,
        id,
        collection: productCollection,
        productImages,
        productImage,
        status: productStatus,
        inventory,
      };
    });
  }, [data]);

  const menuitems = useMemo(
    () => [
      {
        title: "View",
        image: `${ViewIcon}`,
        onClick: productId => navigate(`/products/${productId}`),
      },
      {
        title: "Edit",
        image: `${EditIcon}`,
        onClick: productId => navigate(`/edit-product/${productId}`),
      },
      {
        title: "Delete",
        image: `${DeleteIcon}`,
        onClick: productId => deleteProduct(productId),
      },
    ],
    [navigate, deleteProduct],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => (
          <div className="flex items-center gap-2.5">
            {row.original.productImages[0] || row.original.productImage ? (
              <img
                src={row.original.productImages[0] || row.original.productImage}
                alt={row.original.productName}
                className="block h-8 w-8 shrink-0 object-contain"
              />
            ) : (
              <img
                src={NoImageAvailable}
                alt="blank placeholder"
                className="block h-8 w-8 shrink-0 object-contain"
              />
            )}
            <p className="max-w-xs truncate text-xs font-medium text-neutral-100">
              {row.original.name}
            </p>
          </div>
        ),
      }),
      columnHelper.accessor("collection", {
        header: "Collection",
        cell: ({ row }) =>
          row.original.collection ? row.original.collection.name : "None",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: info => (
          <div className="flex items-center gap-2.5">
            <div className="h-1.5 w-1.5 shrink-0 rounded-full bg-green-600" />
            <p className="text-xs font-medium leading-none text-neutral-100">
              {info.getValue()}
            </p>
          </div>
        ),
      }),
      columnHelper.accessor("inventory", {
        header: "Inventory",
        cell: info => info.getValue(),
      }),
      columnHelper.accessor("view", {
        header: () => "",
        cell: row => (
          <MenuWithPopover>
            {menuitems.map((item, index) => {
              return (
                <Menu.Item
                  key={index}
                  onClick={() => item.onClick(row.row.original.id)}
                  className="px-4 py-2 hover:bg-slate-100"
                >
                  {({ active }) => (
                    <button
                      className={cn(
                        "flex w-full items-center gap-2.5 text-left",
                        active && "bg-slate-100",
                      )}
                    >
                      <img
                        src={item.image}
                        className="h-4 w-4"
                        alt="physical-product-icon"
                      />
                      <p className="flex-1 whitespace-nowrap text-sm font-medium">
                        {item.title}
                      </p>
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </MenuWithPopover>
        ),
      }),
    ];
  }, [menuitems]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set("page", pageIndex + 1);
      return prev;
    });
  }, [pageIndex, setSearchParams]);

  return (
    <div>
      <div className="flex justify-end py-3">
        <SearchInput
          placeholder="Search"
          query={search}
          setQuery={value => {
            setPagination({ pageIndex: 0, pageSize: 10 });
            searchParams.set("search", value);
            setSearchParams(searchParams);
          }}
          containerClass="w-full sm:w-auto"
        />
      </div>
      <DataTable
        data={products}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={data?.data?.pagination?.total}
        isLoading={isLoading || isPreviousData}
        setPagination={setPagination}
      />
      <PlanModal isOpen={displayModal} setIsOpen={setDisplayModal} />
      <NotificationModal
        isOpen={isNotificationModal}
        setIsOpen={setNotificationModal}
      />
    </div>
  );
};

export default ProductBodyTable;
