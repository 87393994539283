import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Swiper, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import HeaderCard from "../../components/storefront/header-card";
import Newsletter from "../../components/storefront/newsletter";
import { useGetProductList, useGetStorefront } from "../../operations/queries";
import Featured from "./featured";
import FeaturedProduct from "./product";
import SEO from "../../components/commons/seo";

function StorefrontPage() {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: response } = useGetProductList({
    storeId: store?.id,
    page: 1,
    pageSize: 13,
  });
  const product = response?.data?.data?.[0];
  const enabledHeaders = store?.headers?.filter(header => header.headerStatus);

  useEffect(() => {
    new Swiper(".swiper", {
      modules: [Pagination, Autoplay],
      autoplay: { delay: 2000, pauseOnMouseEnter: true },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
    });
  }, []);

  return (
    <div className={enabledHeaders?.length === 0 ? "pt-6 sm:pt-10" : undefined}>
      <SEO
        title={store?.storeName || "Storefront"}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={store?.storeLogo}
      />
      {enabledHeaders?.length > 0 && (
        <div className="mb-10">
          <div className="swiper max-h-[45rem]">
            <div className="swiper-wrapper">
              {enabledHeaders.map((header, index) => (
                <div key={index} className="swiper-slide">
                  <HeaderCard header={header} />
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      )}
      <Featured />
      {product && (
        <FeaturedProduct
          product={product}
          withTitle
          reversed
          className="px-4"
        />
      )}
      <div className="mb-20" />
      <div className="sm:px-4">
        <Newsletter color={store?.brandColor} />
      </div>
    </div>
  );
}

export default StorefrontPage;
