import React from "react";
import { FormInput, FormSelect } from "../form";
import { currency } from "../../constants/currency";

export const Price = ({ values, errors, setFieldValue, handleChange }) => {
  return (
    <div className="rounded-[8px] border-[1px] border-[#DCDCDC] p-3 sm:p-4">
      <p className="mb-[0.94rem] text-[1.5rem] font-bold text-[#1F1F1F]">
        Pricing
      </p>
      <p className="text-[0.75rem] font-normal text-[#525252]">
        Give products a price for each of the currencies that you sell in
      </p>
      <p className="mt-[2.81rem] text-[0.875rem] font-medium text-[#1F1F1F] ">
        Prices
      </p>
      <div className="mt-[1rem] flex flex-col gap-6 lg:flex-row lg:items-stretch">
        <div className="flex-1">
          <FormInput
            type="text"
            id="productPrice"
            name="productPrice"
            placeholder="10,000"
            label="Price"
            onChange={handleChange}
            error={Boolean(errors.productPrice)}
            errorMsg={errors.productPrice}
            value={values.productPrice}
            isRequired
            className="h-full"
          />
        </div>
        <div className="flex-1">
          <FormSelect
            label="Product Currency"
            value={values.productCurrency}
            error={Boolean(errors.productCurrency)}
            name="productCurrency"
            onChange={selectedOption => {
              setFieldValue("productCurrency", selectedOption ?? "");
            }}
            errorMsg={errors.productCurrency}
            options={currency}
            isRequired
          />
        </div>
      </div>
      {/* <div className="mb-[1rem] mt-[9rem] flex gap-6">
        <div>
          <label id="container" className="text-[0.75rem] font-medium">
            <input
              type="radio"
              name="radio"
              className="mr-[10px] text-[#0FAA8E]"
            />
            One Time Payment
            <span id="checkmark"></span>
          </label>
        </div>
        <div>
          <label id="container" className="text-[0.75rem] font-medium">
            <input
              type="radio"
              name="radio"
              className="mr-[10px] text-[#0FAA8E]"
            />
            Recurring Payment
            <span id="checkmark"></span>
          </label>
        </div>
      </div> */}
    </div>
  );
};
