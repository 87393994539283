import React from "react";
import { Link } from "react-router-dom";
import AuthLayoutWrapper from "../../../layouts/AuthLayout";

import ButtonComponent from "../../../components/commons/buttons";
import TitleComponent from "../../../components/commons/title-component";
import { DigibleLogo } from "../../../constants/assets-constants";
import * as yup from "yup";
import { useFormik } from "formik";
import { useCreateStoreMutation } from "../../../operations/mutations";
import BusyOverlay from "../../../components/commons/busy-overlay";
import SEO from "../../../components/commons/seo";

function CreateStorePage() {
  const { mutate: createStore, isLoading } = useCreateStoreMutation();

  const validationSchema = yup.object().shape({
    storeName: yup.string().required("Please enter your store name"),
    storeDescription: yup
      .string()
      .max(140, "Store description should not exceed 140 characters")
      .required("Please enter your store description"),
  });

  const { values, validateForm, handleSubmit, errors, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        storeName: "",
        storeDescription: "",
      },
      validationSchema,
      onSubmit: values => {
        validateForm(values).then(() => {
          createStore(values);
        });
      },
    });

  return (
    <>
      <BusyOverlay loading={isLoading} />
      <SEO title="Create Store" />
      <AuthLayoutWrapper isSignUp>
        <div className="flex justify-center">
          <Link to="/">
            <img
              className="w-40 object-contain lg:w-auto"
              src={DigibleLogo}
              alt=""
            />
          </Link>
        </div>

        <div className="mt-10 text-left">
          <TitleComponent text="Enter your store details" />
          <p className="text-xs leading-14 text-neutral-100 md:text-sm">
            Register your store on Digible today.
          </p>
        </div>

        {/* form */}

        <div className="mt-10 w-full text-left">
          <form onSubmit={handleSubmit}>
            <div className="mb-6 ">
              <label htmlFor="storeName" className="form-label font-medium ">
                <p className="mb-1 text-left text-xs text-dark-100">
                  Store name
                </p>
              </label>
              <input
                type="text"
                className="form-control
                  m-0
                  block
                  h-10
                  w-full
                  rounded
                  border
                  border-solid
                  border-gray-300 bg-white
                  bg-clip-padding px-3 py-1.5
                  text-10
                  text-dark-100
                  transition
                  ease-in-out
                  placeholder:text-neutral-200 focus:border-blue-600 focus:bg-white focus:text-dark-100 focus:outline-none"
                id="storeName"
                onChange={handleChange}
                value={values.storeName}
                error={Boolean(errors.storeName)}
                aria-describedby="store name"
                placeholder="Write your store name"
              />
              <small id="storeName" className="mt-1 block text-xs text-red-600">
                {errors.storeName}
              </small>
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="storeName"
                className="form-label flex items-end justify-between font-medium"
              >
                <p className="mb-1 text-left text-xs text-dark-100">
                  Store description
                </p>
                <small className="mb-1 text-left text-8 text-neutral-550">
                  Max:140 words
                </small>
              </label>

              <textarea
                rows={6}
                type="text"
                className="form-control
                  m-0
                  block
                  w-full
                  rounded
                
                  border
                  border-solid
                  border-gray-300 bg-white
                  bg-clip-padding px-3 py-1.5
                  text-10
                  text-dark-100
                  transition
                  ease-in-out
                  placeholder:text-neutral-200 focus:border-blue-600 focus:bg-white focus:text-dark-100 focus:outline-none"
                id="storeDescription"
                onChange={handleChange}
                value={values.storeDescription}
                aria-describedby="store description"
                placeholder="Tell us about your store"
              ></textarea>
              <small
                id="storeDescription"
                className="mt-1 block text-xs text-red-600"
              >
                {errors.storeDescription}
              </small>
            </div>

            <div className="mt-14 w-full">
              <ButtonComponent
                isBlack
                fullWidth
                btnText="Complete"
                type="submit"
              />
            </div>
          </form>
        </div>
      </AuthLayoutWrapper>
    </>
  );
}

export default CreateStorePage;
