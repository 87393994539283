/* eslint-disable react-hooks/rules-of-hooks */
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const usePersist = key => {
  const { persistAtom } = recoilPersist({
    key,
    storage: localStorage,
  });
  return [persistAtom];
};
export default usePersist;

const userState = atom({
  key: "userState",
  default: null,

  effects_UNSTABLE: usePersist("userState"),
});

const signupValidationState = atom({
  key: "signupValidationState",
  default: {
    isValidated: false,
    enforceValidation: false,
  },
});

const onBoardingNavigationState = atom({
  key: "onBoardingNavigationState",
  default: {
    signUp: "active",
    verify: "disabled",
    store: "disabled",
  },
  effects_UNSTABLE: usePersist("onBoardingNavigationState"),
});

const onBoardingProcessState = atom({
  key: "onBoardingProcessState",
  default: {
    signUp: true,
    verify: false,
    store: false,
  },
  effects_UNSTABLE: usePersist("onBoardingProcessState"),
});

const cartState = atom({
  key: "cartState",
  default: [],
  effects_UNSTABLE: usePersist("cartState"),
});

const cartMarketplaceState = atom({
  key: "cartMarketplaceState",
  default: [],
  effects_UNSTABLE: usePersist("cartMarketplaceState")
})
export {
  signupValidationState,
  onBoardingNavigationState,
  cartMarketplaceState,
  onBoardingProcessState,
  userState,
  cartState,
};
