import React from "react";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import { ECommerceToolImg } from "../../constants/assets-constants";

function ECommerceToolComponent() {
  return (
    <div className="text-left py-1/10 mt-1/10">
      <div className="w-full text-center px-1/10 md:w-1/70 lg:w-1/55 md:text-left">
        <TitleComponent isMedium text="Your All In One Business Growth Tool" />
        <ContentComponent
          text="Track your sales, manage inventory, 
        accept payments, offer discounts, and grow your business with our All in One Tool."
        />
      </div>
      <div className="w-full mt-10 md:w-9/12 pl-1/6 md:mt-0">
        <img className="object-contain w-full" src={ECommerceToolImg} alt="" />
      </div>
    </div>
  );
}

export default ECommerceToolComponent;
