import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import BusyOverlay from "../../../components/commons/busy-overlay";
import Button from "../../../components/commons/button";
import FormInput from "../../../components/form/form-input";
import FormSelect from "../../../components/form/form-select";
import PageDisplay from "../../../components/settings/PageDisplay";
import { PageTitle } from "../../../components/settings/PageTitle";
import { currency } from "../../../constants/currency";
import {
  useAddShippingProfileMutation,
  useUpdateShippingProfileMutation,
} from "../../../operations/mutations";
import { useGetShippingProfile } from "../../../operations/queries";
import { userState } from "../../../recoil/atoms";
import ShippingItem from "./ShippingItem";
import SEO from "../../../components/commons/seo";

const validationSchema = yup.object().shape({
  amount: yup.number().required("Please input an amount"),
  currency: yup.object().required("Please select a currency"),
  state: yup.string().required("Please input a state"),
  city: yup.string().required("Please input a city"),
});

const getInitialValues = (mode, shipping) => {
  if (!shipping || mode === "creating")
    return {
      city: "",
      state: "",
      currency: "",
      amount: 0,
    };
  return {
    city: shipping.city ?? "",
    state: shipping.state ?? "",
    currency:
      shipping.currency ?? ""
        ? { value: shipping.currency, label: shipping.currency }
        : "",
    amount: shipping.amount ?? 0,
  };
};

const ShippingPage = () => {
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [mode, setMode] = useState("creating");
  const { store } = useRecoilValue(userState);
  const {
    mutate: addShippingProfile,
    isLoading: isSaving,
    isSuccess: isAdded,
  } = useAddShippingProfileMutation();
  const {
    mutate: updateShippingDetails,
    isLoading: isUpdated,
    isSuccess: isSaved,
  } = useUpdateShippingProfileMutation();
  const { data: response, isLoading } = useGetShippingProfile(store?.id);
  const shippingProfiles = response?.data?.data;

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(mode, selectedShipping),
    validationSchema,
    onSubmit: async values => {
      if (!store) return toast.error("Store not found");
      const payload = {
        ...values,
        storeId: store.id,
        currency: values.currency.value,
      };
      if (mode === "creating") return addShippingProfile(payload);
      if (!selectedShipping) return toast.error("Shipping profile not found");
      updateShippingDetails({ ...selectedShipping, ...payload });
    },
  });

  useEffect(() => {
    if (isSaved || isAdded) {
      resetForm();
      setMode("creating");
    }
  }, [isSaved, isAdded, resetForm]);

  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <BusyOverlay loading={isLoading} />
      <PageDisplay linkTitle="Shipping" />
      <SEO title="Shipping" />
      <div className="flex flex-col gap-[1.2rem] md:flex-row">
        <div className="flex-1 rounded-[0.42875rem] border-[1px] border-neutral-300">
          <div className="p-4 sm:p-8">
            <PageTitle title="Shipping" description="Manage Shipping Profile" />
            {shippingProfiles?.map(shipping => (
              <ShippingItem
                key={shipping.id}
                shipping={shipping}
                onEdit={() => {
                  setSelectedShipping(shipping);
                  setMode("update");
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex-1 rounded-[0.42875rem] border-[1px] border-neutral-300">
          <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-8">
            <div className="p-4 sm:p-8">
              <div className="mb-[2rem] flex justify-between">
                <div>
                  <p className="text-[1.75rem] font-[600] text-dark-100">
                    Details
                  </p>
                </div>
              </div>
              <FormInput
                id="city"
                name="city"
                value={values.city}
                placeholder="Garki"
                error={Boolean(errors.city)}
                onChange={handleChange}
                label="City"
                errorMsg={errors.city}
                className="mb-4"
                isRequired
              />
              <FormInput
                id="state"
                name="state"
                value={values.state}
                placeholder="Lagos"
                error={Boolean(errors.state)}
                onChange={handleChange}
                label="State"
                errorMsg={errors.state}
                className="mb-4"
                isRequired
              />
              <FormSelect
                label="Currency"
                value={values.currency}
                error={Boolean(errors.currency)}
                name="currency"
                onChange={selectedOption => {
                  setFieldValue("currency", selectedOption ?? "");
                }}
                errorMsg={errors.currency}
                options={currency}
                isRequired
              />
              <FormInput
                id="amount"
                name="amount"
                value={values.amount}
                placeholder="5000"
                error={Boolean(errors.amount)}
                onChange={handleChange}
                label="Amount"
                errorMsg={errors.amount}
                isRequired
                className="my-4"
                type="number"
              />
            </div>
            <div className="flex justify-end border-t-[1px] border-neutral-300 px-2 py-8">
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={() => {
                    resetForm();
                    setMode("creating");
                  }}
                >
                  Cancel changes
                </Button>
                <Button
                  className="px-8 py-3"
                  type="submit"
                  isLoading={isSaving || isUpdated}
                  disabled={Object.keys(errors).length > 0}
                >
                  {mode === "creating" ? "Save" : "Update"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShippingPage;
