import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import BusyOverlay from "../../components/commons/busy-overlay";
import { useVerifySubscriptionMutation } from "../../operations/mutations";

export default function VerifySubscriptionPage() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const reference = searchParams.get("tx_ref");
  const transactionId = searchParams.get("transaction_id");
  const { mutate: verify, isLoading } = useVerifySubscriptionMutation();

  useEffect(() => {
    const referenceExists = reference && transactionId;
    const hasPaid = status === "successful" || status === "completed";
    if (hasPaid && referenceExists) verify({ reference, transactionId });
  }, [status, transactionId, reference, verify]);

  if (isLoading)
    return (
      <BusyOverlay
        loading={isLoading}
        text="Verifying subscription..."
        opaque
      />
    );

  if (status === "cancelled")
    return <Navigate to="/pricing?status=cancelled" />;

  return <Navigate to="/dashboard" />;
}
