import React from "react";
import { Link } from "react-router-dom";

const SettingContainer = ({ image, title, description, link, rightIcon }) => {
  return (
    <Link to={link}>
      <div className=" w-full lg:w-[25rem] justify-between flex py-[1.8rem] px-[1rem] border-[1px] border-[#dcdcdc] rounded-[0.375rem] cursor-pointer">
        <div className="flex gap-[0.9rem]">
          <div className="flex items-center justify-center">
            <img src={`${image}`} alt="icon" className="w-[40px] h-[40px]" />
          </div>
          <div>
            <p className="text-[0.875rem] font-[600] text-[#1f1f1f]">{title}</p>
            <p className="text-[0.75rem] text-[#525252]">{description}</p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={`${rightIcon}`}
            alt="rightIcon"
            className="w-[24px] h-[24px]"
          />
        </div>
      </div>
    </Link>
  );
};

export default SettingContainer;
