import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { CloseIcon } from "../../constants/assets-constants";
import FormInput from "../form/form-input";

const InviteUser = ({ isOpen, setIsOpen }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[48.938rem] relative p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-[#1F1F1F] text-[1.25rem] font-bold"
                >
                  Invite Users
                </Dialog.Title>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  className="absolute top-[20px] right-[20px] cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
                <FormInput
                  id="name"
                  name="Name"
                  // value={values.billFrom}
                  placeholder="fijiyousagmail.com"
                  // error={Boolean(errors.billFrom)}
                  // onChange={handleChange}
                  label="Email"
                  // errorMsg={errors.billFrom}
                  isRequired
                  className="my-[1rem]"
                />
                <div className="border-t-[1px] mt-[20px] border-[#DCDCDC]"></div>
                <div className="flex justify-end mt-[10px]">
                  <div className="flex gap-[20px]">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="font-medium text-[0.75rem] text-[#898989] cursor-pointer"
                    >
                      Cancel
                    </button>
                    <button className="w-[5.875rem] rounded-[6px] font-medium text-[#ffffff] text-[0.75rem] py-[0.438rem] px-[1.563rem] bg-[#0FAA8E]">
                      Invite
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InviteUser;
