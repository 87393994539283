export const NavigationLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Demo",
    link: "https://youtu.be/5LICNWQ6Uqc?feature=shared",
  },
  {
    name: "Pricing",
    link: "/pricing",
  },
  {
    name: "Markeplace",
    link: "/marketplace",
  },
];
