import React from "react";

function ArrowIcon({ titleId, descriptionId, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-3 w-3 flex-none stroke-neutral-100 rotate-180"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      aria-hidden="true"
      role="graphics-symbol"
      {...props}
    >
      <title id={titleId}>Arrow</title>
      <desc id={descriptionId}>
        Arrow icon that points to the next page in big screen resolution sizes
        and previous page in small screen resolution sizes.
      </desc>
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
    </svg>
  );
}

export default ArrowIcon;
