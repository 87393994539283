import { FormInput, FormSelect } from "../../components/form";
import IconButton from "../../components/commons/icon-button";
import { CloseIcon } from "../../components/icons";

function Item(props) {
  const { values, errors, handleChange, index, handleRemove, canRemove } =
    props;

  return (
    <div className="border border-neutral-300 p-4 rounded-lg mb-6">
      <div className="flex justify-between">
        <div className="mb-4">
          <h2 className="font-semibold text-lg md:text-2xl mb-2">
            Item {index + 1}
          </h2>
          <p className="text-xs text-neutral-100">Customize Items Details</p>
        </div>
        {canRemove && (
          <IconButton
            variant="ghost"
            onClick={() => handleRemove(index)}
            icon={<CloseIcon />}
            description="Remove invoice item"
            className="w-10 h-10 p-0"
          />
        )}
      </div>
      <div className="grid sm:grid-cols-2 gap-6">
        <FormSelect
          id={`items[${index}].itemName`}
          name={`items[${index}].itemName`}
          value={values.itemName}
          error={Boolean(errors.itemName)}
          onChange={handleChange}
          label="Select Product"
          errorMsg={errors.itemName}
          isRequired
        >
          <option value=""></option>
          <option value="Product">Product</option>
          <option value="Another Product">Another Product</option>
        </FormSelect>
        <FormInput
          id={`items[${index}].quantity`}
          name={`items[${index}].quantity`}
          value={values.quantity}
          placeholder="E.G.....36"
          error={Boolean(errors.quantity)}
          onChange={handleChange}
          label="Quantity"
          errorMsg={errors.quantity}
          type="number"
          min={0}
          isRequired
        />
        <FormInput
          id={`items[${index}].vat`}
          name={`items[${index}].vat`}
          value={values.vat}
          error={Boolean(errors.vat)}
          onChange={handleChange}
          label="VAT Amount"
          errorMsg={errors.vat}
          isRequired
          type="number"
        />
      </div>
    </div>
  );
}

export default Item;
