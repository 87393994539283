import { EyeIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import formatPrice from "../../utils/formatPrice";

function Template({ template }) {
  return (
    <div className="font-medium">
      <div className="mb-4 flex flex-col items-center gap-4 sm:mb-0 sm:flex-row sm:gap-40">
        <div className="relative mb-2 h-56 w-full overflow-hidden rounded-md sm:w-64">
          <img
            src={template.image}
            alt="template"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 h-full w-full bg-neutral-650 opacity-50" />
          <Link
            to="#"
            className="absolute inset-0 flex h-full w-full items-center justify-center gap-4 text-white"
          >
            <EyeIcon className="w-6" />
            <p>View</p>
          </Link>
        </div>
        <Button className="w-full sm:w-auto">
          {template.price === 0 ? "Apply Design" : "Purchase Design"}
        </Button>
      </div>
      <div className="text-black">
        <p>{template.name}</p>
        <p className="flex items-center gap-1">
          {template.price === 0 ? "Free" : formatPrice(template.price)}
        </p>
      </div>
    </div>
  );
}

export default Template;
