import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CloseIcon, SearchIcon } from "../icons";
import { useGetCollections, useGetStorefront } from "../../operations/queries";
import useDebounce from "../../hooks/useDebounce";

function StorefrontSidebar({ hideSidebar }) {
  const { storeUrl } = useParams();
  const navigate = useNavigate();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: response } = useGetCollections({ storeId: store?.id });
  const collections = response?.data?.data;
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    if (!debouncedSearch) return;
    const params = new URLSearchParams();
    params.set("search", debouncedSearch);
    navigate({ pathname: `/${storeUrl}/all`, search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, storeUrl]);

  const handleKeyDown = ({ key }) => {
    if (key !== "Enter") return;
    const params = new URLSearchParams();
    params.set("search", search);
    navigate({ pathname: `/${storeUrl}/all`, search: params.toString() });
  };

  return (
    <div className="flex flex-col gap-10 p-4 font-medium">
      <nav>
        <ul className="flex flex-col gap-3">
          <button
            onClick={hideSidebar}
            className="mb-4 grid h-8 w-8 place-items-center focus:ring-4 focus:ring-gray-200 sm:hidden"
          >
            <CloseIcon />
          </button>
          <li>
            <Link
              onClick={hideSidebar}
              to={`/${storeUrl}/all`}
              className="text-lg text-dark-100 sm:text-2xl"
            >
              All
            </Link>
          </li>
          {collections?.map(collection => (
            <li key={collection.id}>
              <Link
                onClick={hideSidebar}
                to={`/${storeUrl}/collection/${collection.id}`}
                className="text-lg text-dark-100 sm:text-2xl"
              >
                {collection.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="text:base flex flex-col gap-2 sm:text-xl">
        <Link onClick={hideSidebar} to={`/${storeUrl}/about`}>
          About Us
        </Link>
      </div>
      <div className="flex items-center gap-2 rounded border border-neutral-300 p-2 focus-within:ring-1 focus-within:ring-blue-400">
        <SearchIcon className="h-5 w-5 text-neutral-200" />
        <input
          placeholder="Search"
          className="border-none p-0 text-sm focus:outline-none focus:ring-0"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}

export default StorefrontSidebar;
