import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userState } from "../recoil/atoms";
import {
  getAnalytics,
  getBankAccount,
  getBankList,
  getCart,
  getCartAmount,
  getCollections,
  getCurrentUser,
  getCustomerOrders,
  getCustomers,
  getOrderDetails,
  getProductDetails,
  getProductList,
  getShippingProfiles,
  getStoreOrders,
  getStoreWithdrawals,
  getStorefront,
  getSubscription,
} from "./query.def";
import { useNavigate } from "react-router-dom";

export const useGetCurrentUser = () => {
  const [, setUser] = useRecoilState(userState);

  return useQuery("currentUser", getCurrentUser, {
    onSuccess: data => {
      setUser(prev => ({ ...prev, ...data.data.data }));
    },
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching current user",
      );
    },
  });
};

export const useGetStoreOrders = params => {
  const queryClient = useQueryClient();
  return useQuery(["ordersList", params], () => getStoreOrders(params), {
    enabled: !!params?.storeId,
    keepPreviousData: true,
    placeholderData: () => queryClient.getQueryData(["ordersList", params]),
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching orders");
    },
  });
};

export const useGetStoreWithdrawal = params => {
  const queryClient = useQueryClient();
  return useQuery(["withdrawals", params], () => getStoreWithdrawals(params), {
    select: data => data.data.data,
    keepPreviousData: true,
    placeholderData: () => queryClient.getQueryData(["withdrawals", params]),
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching withdrawals");
    },
  });
};

export const useGetBankList = (enabled = true) => {
  return useQuery("bankList", getBankList, {
    enabled,
    staleTime: Infinity,
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching Bank List");
    },
  });
};

export const useGetStorefront = url => {
  const navigate = useNavigate();
  return useQuery(["storefront", url], () => getStorefront(url), {
    enabled: !!url,
    onError: err => {
      const notFound = err?.response?.status === 404;
      if (notFound) navigate("/404", { replace: true });
      toast.error(
        notFound
          ? "Store not found"
          : err?.response?.data?.message || "Error fetching store",
      );
    },
  });
};

export const useGetProductList = params => {
  const queryClient = useQueryClient();
  return useQuery(["products", params], () => getProductList(params), {
    enabled: !!params?.storeId,
    keepPreviousData: true,
    placeholderData: () => queryClient.getQueryData(["products", params]),
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching products");
    },
  });
};

export const useGetOrderDetails = id => {
  return useQuery(["order", id], () => getOrderDetails(id), {
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching OrderDetails",
      );
    },
  });
};

export const useGetProductDetails = id => {
  return useQuery(["product", id], () => getProductDetails(id), {
    enabled: !!id,
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching product details",
      );
    },
  });
};

export const useGetBankAccount = () => {
  return useQuery("bankAccount", getBankAccount, {
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching products");
    },
  });
};

export const useGetCart = storeId => {
  const cartId = localStorage.getItem(storeId);
  return useQuery(["cart", cartId], () => getCart(cartId), {
    enabled: !!cartId,
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching cart");
    },
  });
};

export const useGetCartAmount = storeId => {
  return useQuery(["cartAmount", storeId], () => getCartAmount(storeId), {
    enabled: !!storeId,
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching cart amount");
    },
  });
};

export const useGetCollections = params => {
  const queryClient = useQueryClient();
  return useQuery(["collection", params], () => getCollections(params), {
    enabled: !!params?.storeId,
    keepPreviousData: true,
    placeholderData: () => queryClient.getQueryData(["collection", params]),
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching collections");
    },
  });
};

export const useGetAnalytics = storeId => {
  return useQuery(["analytics", storeId], () => getAnalytics(storeId), {
    enabled: !!storeId,
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching analytics");
    },
  });
};

export const useGetCustomers = params => {
  const queryClient = useQueryClient();
  return useQuery(["customers", params], () => getCustomers(params), {
    enabled: !!params?.storeId,
    select: data => data.data.data,
    keepPreviousData: true,
    placeholderData: () => queryClient.getQueryData(["customers", params]),
    onError: err => {
      toast.error(err?.response?.data?.message || "Error fetching customers");
    },
  });
};

export const useGetCustomerOrders = email => {
  return useQuery(["customerOrders", email], () => getCustomerOrders(email), {
    enabled: !!email,
    select: data => data.data.data,
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching customer orders",
      );
    },
  });
};

export const useGetSubscription = () => {
  return useQuery("subscription", getSubscription, {
    select: data => data.data.data,
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching subscription",
      );
    },
  });
};

export const useGetShippingProfile = storeId => {
  return useQuery(["shipping", storeId], () => getShippingProfiles(storeId), {
    enabled: !!storeId,
    onError: err => {
      toast.error(
        err?.response?.data?.message || "Error fetching shipping details",
      );
    },
  });
};
