import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import { CloseIcon } from "../../constants/assets-constants";
import { useAddCollection } from "../../operations/mutations";
import { userState } from "../../recoil/atoms";
import Button from "../commons/button";
import { FormInput } from "../form";

const validationSchema = yup.object().shape({
  name: yup.string().required("Please enter a name"),
});

const CollectionModal = ({ isOpen, setIsOpen }) => {
  const { mutate: addCollection, isLoading } = useAddCollection();
  const { store } = useRecoilValue(userState);

  const { errors, values, validateForm, handleSubmit, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: { name: "", storeId: store.id },
      validationSchema,
      onSubmit: values => {
        validateForm(values).then(() => {
          addCollection(values);
        });
      },
    });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[48.938rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-[1.25rem] font-bold text-[#1F1F1F]"
                >
                  Add Collection
                </Dialog.Title>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  className="absolute right-[20px] top-[20px] cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
                <p className="mt-[0.75rem] text-[0.875rem] font-medium text-[#898989] ">
                  To create a collection, all you need is a name for your
                  collection.
                </p>

                <p className="my-[1rem] text-[1.1rem] font-bold text-[#1F1F1F]">
                  Details
                </p>
                <form onSubmit={handleSubmit}>
                  <FormInput
                    id="name"
                    name="name"
                    value={values.name}
                    placeholder="Shoes, Sunglasses..."
                    error={Boolean(errors.name)}
                    onChange={handleChange}
                    label="Name"
                    errorMsg={errors.name}
                    isRequired
                  />
                  <div className="mt-[1rem] flex justify-end">
                    <div className="flex gap-[1rem]">
                      <Button
                        onClick={() => setIsOpen(false)}
                        className="cursor-pointer bg-transparent p-[1rem] font-medium text-[#898989] hover:bg-transparent"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="rounded-[6px] bg-[#0FAA8E] px-[1.563rem] py-[0.438rem] font-[0.75rem] text-[#ffffff]"
                        disabled={isLoading}
                        type="submit"
                      >
                        Publish Collection
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CollectionModal;
