import IconButton from "../../components/commons/icon-button";
import { FormInput } from "../../components/form";
import { TrashIcon } from "../../components/icons";

function Metadata(props) {
  const { values, errors, handleChange, index, handleRemove } = props;

  return (
    <div className="flex flex-wrap items-center gap-5">
      <FormInput
        id={`metadata[${index}].key`}
        name={`metadata[${index}].key`}
        value={values.key}
        placeholder="Some key"
        error={Boolean(errors.key)}
        onChange={handleChange}
        label="Key"
        errorMsg={errors.key}
        className="grow"
      />
      <FormInput
        id={`metadata[${index}].value`}
        name={`metadata[${index}].value`}
        value={values.value}
        placeholder="Some value"
        error={Boolean(errors.value)}
        onChange={handleChange}
        label="Value"
        errorMsg={errors.value}
        className="grow"
      />
      <IconButton
        variant="ghost"
        onClick={() => handleRemove(index)}
        icon={<TrashIcon />}
        description="Remove metadata"
        className="w-5 h-5 sm:ml-2 p-0 text-neutral-200 rounded-md"
      />
    </div>
  );
}

export default Metadata;
