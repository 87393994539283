export const currency = [
  {
    value: "NGN",
    label: "Nigerian Naira (NGN)",
  },
  {
    value: "USD",
    label: "United States Dollar (USD)",
  },
];
