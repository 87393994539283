import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  onBoardingNavigationState,
  onBoardingProcessState,
} from "../recoil/atoms";

export default function SignUpFlow() {
  const openTab = useRecoilValue(onBoardingNavigationState);

  const process = useRecoilValue(onBoardingProcessState);

  const navigate = useNavigate();

  return (
    <div className="flex justify-between mb-10 md:mb-16">
      <button
        onClick={() => navigate("/signup")}
        disabled={!process.signUp}
        className="flex space-x-2 items-center"
      >
        <span
          className={`rounded-full h-4 w-4 text-10 flex items-center justify-center text-white
                ${
                  openTab.signUp === "active"
                    ? "bg-dark-100"
                    : openTab.signUp === "completed"
                    ? "bg-green-100"
                    : "bg-neutral-500"
                }
                `}
        >
          1
        </span>
        <span
          className={`text-10 sm:text-xs font-medium
               text- ${
                 openTab.signUp === "active"
                   ? "text-dark-100"
                   : openTab.signUp === "completed"
                   ? "text-green-100"
                   : "text-neutral-550"
               }
              `}
        >
          Sign up
        </span>
        <span
          className={`text-10 font-medium
         ${
           openTab.signUp === "active"
             ? "text-dark-100"
             : openTab.signUp === "completed"
             ? "text-green-100"
             : "text-neutral-550"
         }
        `}
        >
          &gt;
        </span>
      </button>
      <button
        onClick={() => navigate("/verify-account")}
        disabled={!process.verify}
        className="flex space-x-2 items-center"
      >
        <span
          className={`rounded-full h-4 w-4 text-10 flex items-center justify-center text-white
                 ${
                   openTab.verify === "active"
                     ? "bg-dark-100 text-white"
                     : openTab.verify === "completed"
                     ? "bg-green-100 text-white"
                     : "bg-neutral-500 text-neutral-200"
                 }
                `}
        >
          2
        </span>
        <span
          className={`text-10 sm:text-xs font-medium
               ${
                 openTab.verify === "active"
                   ? "text-dark-100"
                   : openTab.verify === "completed"
                   ? "text-green-100"
                   : "text-neutral-550"
               }
              `}
        >
          Verification
        </span>
        <span
          className={`text-8
        ${
          openTab.verify === "active"
            ? "text-dark-100"
            : openTab.verify === "completed"
            ? "text-green-100"
            : "text-neutral-550"
        }
        `}
        >
          &gt;
        </span>
      </button>
      <button
        onClick={() => navigate("/create-store")}
        disabled={!process.store}
        className="flex space-x-2 items-center"
      >
        <span
          className={`rounded-full h-4 w-4 text-10 flex items-center justify-center
                 ${
                   openTab.store === "active"
                     ? "bg-dark-100 text-white"
                     : openTab.store === "completed"
                     ? "bg-green-100 text-white"
                     : "bg-neutral-500 text-neutral-200"
                 }
                `}
        >
          3
        </span>
        <span
          className={`text-10 sm:text-xs font-medium
               ${
                 openTab.store === "active"
                   ? "text-dark-100"
                   : openTab.store === "completed"
                   ? "text-green-100"
                   : "text-neutral-550"
               }
              `}
        >
          Create Store
        </span>
      </button>
    </div>
  );
}
