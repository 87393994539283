import React from "react";
import { Link, useParams } from "react-router-dom";
import BusyOverlay from "../../../components/commons/busy-overlay";
import ProductDescription from "../../../components/products/ProductDescription";
import ProductImages from "../../../components/products/ProductImages";
import { ArrowLeft } from "../../../constants/assets-constants";
import { useGetProductDetails } from "../../../operations/queries";
import SEO from "../../../components/commons/seo";

const ProductDetails = () => {
  const { productId } = useParams();
  const product = useGetProductDetails(productId);
  const extractedData = product?.data?.data?.data;

  return (
    <>
      <BusyOverlay loading={product.isLoading} />
      <SEO title="Product Details" />
      <div className="pb-[20px] pl-[13px] pr-[13px]">
        <Link to="/products">
          <div className="flex gap-[5px]">
            <img src={ArrowLeft} alt="arrow-right" />
            <p className="mt-[0.2rem] text-[0.75rem] font-medium text-[#6B7280]">
              Back to Products
            </p>
          </div>
        </Link>
        <ProductImages product={extractedData} />
        <ProductDescription product={extractedData} />
      </div>
    </>
  );
};

export default ProductDetails;
