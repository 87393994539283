import { Link } from "react-router-dom";
import Button from "../../components/commons/button";
import cn from "../../utils/cn";

const COLOURS = ["#000000", "#0FAA8E", "#E91E63", "#F44336"];

function ColourInput({
  value,
  setFieldValue,
  subHeading,
  handleChange,
  withLink,
}) {
  return (
    <div className="rounded-lg border border-neutral-300 p-4 md:pb-20">
      <div className="mb-4">
        <h2 className="mb-2 text-lg font-semibold md:text-2xl">
          Select Colour
        </h2>
        <p className="text-xs text-neutral-100">{subHeading}</p>
      </div>
      <div>
        <h4 className="mb-2 text-lg font-medium md:text-2xl">Colour</h4>
        <div className="flex flex-wrap justify-between gap-4">
          <div className="flex flex-wrap gap-6">
            <div className="flex gap-3">
              {COLOURS.map((colour, index) => (
                <button
                  type="button"
                  onClick={() => setFieldValue("brandColor", colour)}
                  key={index}
                  className={cn(
                    `h-10 w-10 rounded-full ring-1 ring-white md:h-60 md:w-60 bg-[${colour}]`,
                    {
                      "ring-2 ring-green-100": value === colour,
                      "ring-2 ring-neutral-300":
                        value !== colour && colour === "#FFFFFF",
                    },
                  )}
                />
              ))}
            </div>
            <input
              type="text"
              className="rounded-md border-neutral-100 text-neutral-100"
              id="brandColor"
              name="brandColor"
              value={value}
              placeholder="#DCDCDC"
              onChange={handleChange}
            />
          </div>

          {withLink && (
            <Link to="/storefront-templates">
              <Button
                variant="link"
                className="gap-2 text-xl text-green-100 hover:text-green-600 hover:no-underline"
              >
                <span className="text-3xl"> +</span> Choose Template
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ColourInput;
