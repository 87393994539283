import React from "react";
import { Link, useParams } from "react-router-dom";
import { DigibleLogo, HamBurger } from "../../constants/assets-constants";
import { useGetCart, useGetStorefront } from "../../operations/queries";
import IconButton from "../commons/icon-button";
import { CartIcon } from "../icons";

function StorefrontHeader({ showSidebar }) {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const store = data?.data?.data?.store;
  const { data: cartRes } = useGetCart(store?.id);
  const cart = cartRes?.data?.data;

  return (
    <div className="border-b-2 border-neutral-300">
      <div className="mx-auto flex max-w-screen-2xl items-center justify-between p-4">
        <div className="flex items-center gap-2">
          <button
            onClick={showSidebar}
            className="grid h-8 w-8 place-items-center focus:ring-4 focus:ring-gray-200 sm:hidden"
          >
            <img src={HamBurger} alt="menu" />
          </button>
          <Link to={`/${storeUrl}`} className="inline-block">
            <img
              className="h-11 object-contain"
              src={store?.storeLogo ?? DigibleLogo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex items-center justify-between gap-2 sm:gap-4">
          <Link className="relative h-7 sm:h-8" to={`/${storeUrl}/cart`}>
            <IconButton
              variant="ghost"
              text="Your Cart"
              icon={<CartIcon />}
              description="View your cart"
              className="inline-flex h-8 w-8 gap-1 p-0 font-medium text-dark-100 sm:h-auto sm:w-auto sm:p-2"
              textClassName="hidden sm:inline"
            />
            {!!cart?.products.length && (
              <p
                className="absolute -top-2 right-0 grid h-5 w-5 place-items-center rounded-full bg-black text-10 leading-tight text-white sm:text-xs sm:leading-none"
                style={{ backgroundColor: store?.brandColor }}
              >
                {cart.products.length}
              </p>
            )}
          </Link>
          <select className="form-select border-none text-xs sm:text-base">
            <option value="NGN">NGN</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default StorefrontHeader;
