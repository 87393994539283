import {
  Mail,
  PersonalInformation,
  Shipping,
  TheTeam,
  ArrowRight,
} from "./assets-constants";

export const settingsLinks = [
  {
    image: `${Shipping}`,
    title: "Shipping",
    link: "/settings/shipping",
    description: "Manage Shipping profiles",
    rightIcon: `${ArrowRight}`,
  },
  {
    image: `${TheTeam}`,
    title: "The Team",
    description: "Manage the users of Digible Store",
    rightIcon: `${ArrowRight}`,
    link: "/settings/the-team",
  },
  {
    image: `${PersonalInformation}`,
    title: "Personal Information",
    description: "Manage your Digible Profile",
    rightIcon: `${ArrowRight}`,
    link: "/settings/personal-information",
  },
  {
    image: `${Mail}`,
    title: "Digibleonline@gmail.com",
    link: "mailto:Digibleonline@gmail.com",
    description: "Can’t find the answers you’re looking for?",
    rightIcon: `${ArrowRight}`,
  },
];
