import { Tab } from "@headlessui/react";

function TabGroup({ children, selectedIndex, setSelectedIndex }) {
  return (
    <Tab.Group
      as="div"
      className="mx-auto max-w-screen-lg rounded-lg border border-neutral-300 p-4"
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      {children}
    </Tab.Group>
  );
}

export default TabGroup;
