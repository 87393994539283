import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../components/commons/button";
import { ColourInput } from "../../components/form";
import InvoiceSettings from "./invoice-settings";
import Item from "./item";

const DEFAULT_ITEM = {
  itemName: "",
  quantity: "",
  currency: "",
  price: "",
  vat: "",
};

function CreateInvoicePage() {
  const validationSchema = yup.object().shape({
    invoiceNumber: yup.string().required("Invoice number is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
  });

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceNumber: "",
      dateIssued: "",
      billedTo: "",
      dueDate: "",
      billFrom: "",
      phoneNumber: "",
      items: [DEFAULT_ITEM],
      colour: "",
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {});
    },
  });

  const handleAdd = () => {
    setFieldValue("items", [...values.items, DEFAULT_ITEM]);
  };

  const handleRemove = index => {
    const newArr = values.items.filter((_, idx) => idx !== index);
    setFieldValue("items", newArr);
  };

  return (
    <form className="p-4 text-dark-100" onSubmit={handleSubmit}>
      <InvoiceSettings
        values={values}
        errors={errors}
        handleChange={handleChange}
      />
      {values.items.map((_, index) => (
        <Item
          key={index}
          index={index}
          values={values.items[index]}
          errors={errors}
          handleChange={handleChange}
          handleRemove={handleRemove}
          canRemove={values.items.length > 1}
        />
      ))}
      <div className="mb-6 flex justify-end">
        <Button
          variant="ghost"
          className="gap-1 self-end text-xl font-semibold"
          onClick={handleAdd}
        >
          <span className="text-3xl">+</span> Add More Items
        </Button>
      </div>
      <ColourInput
        subHeading="Customize Button Colours"
        value={values.colour}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <div className="flex justify-end">
        <Button type="submit">Save Details</Button>
      </div>
    </form>
  );
}

export default CreateInvoicePage;
