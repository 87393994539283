import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/commons/button";
import { FormInput } from "../../components/form";
import { useRef } from "react";
import { useUploadFileMutation } from "../../operations/mutations";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

const MAX_FILE_SIZE = 73400320; // 70MB

function Header({ values, handleChange, setFieldValue, index }) {
  const [enabled, setEnabled] = useState(values.headerStatus || false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const controller = useRef(new AbortController());
  const { mutate: uploadFile, isError, data } = useUploadFileMutation();
  const isUploading = progress > 0 && progress < 100;

  const handleFile = e => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    if (files[0].size > MAX_FILE_SIZE) toast.error("File size exceeded");
    else {
      setFileName(files[0].name);
      setFile(files[0]);
      handleUpload(files[0]);
    }
    e.target.value = "";
  };

  const handleUpload = file => {
    uploadFile({
      file,
      cb: handleProgress,
      signal: controller.current.signal,
    });
  };

  const handleCancel = () => {
    controller.current.abort();
    controller.current = new AbortController();
    setProgress(0);
    setFileName("");
  };

  const handleProgress = event => {
    const value = event.total
      ? Math.round((event.loaded / event.total) * 100)
      : 0;
    setProgress(value);
  };

  useEffect(() => {
    setFieldValue(`headers[${index}].headerStatus`, enabled);
  }, [enabled, setFieldValue, index]);

  useEffect(() => {
    if (!data) return;
    setFieldValue(`headers[${index}].landscapeImage`, data.data.data);
  }, [data, setFieldValue, index]);

  return (
    <div className="mb-6 rounded-lg border border-neutral-300 p-4">
      <div className="mb-4 flex justify-between gap-4">
        <div>
          <h2 className="mb-2 text-lg font-semibold md:text-2xl">
            Header {index + 1}
          </h2>
          <p className="text-xs text-neutral-100">
            Upload High Resolution image to display on Header 1
          </p>
        </div>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? "bg-green-100" : "bg-neutral-600"
          } relative inline-flex h-5 w-10 flex-shrink-0 items-center rounded-full`}
        >
          <span className="sr-only">Enable header</span>
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <div className="grid gap-6 sm:grid-cols-2">
        <div>
          <p className="mb-4 text-sm font-semibold">Add Landscape Image</p>
          <div className="flex flex-col gap-1 text-sm text-dark-100 sm:flex-row sm:items-center sm:justify-between sm:text-base">
            {isUploading ? (
              <div className="h-1.5 w-full rounded-full bg-gray-200">
                <div
                  className="h-1.5 rounded-full bg-green-100"
                  style={{ width: progress + "%" }}
                />
              </div>
            ) : progress === 100 && fileName ? (
              <div>
                <p className="break-all text-sm">{fileName}</p>
                {isError && (
                  <div className="flex items-center gap-2 text-xs text-red-600">
                    <button
                      onClick={() => handleUpload(file)}
                      type="button"
                      className="flex items-center gap-2 leading-none"
                    >
                      Retry upload
                      <ArrowPathIcon className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {values.landscapeImage ? (
                  <p className="break-all text-sm">{values.landscapeImage}</p>
                ) : (
                  <p>
                    <span className="mr-1 text-xl">+</span>
                    Upload file here, not more than 70MB each
                  </p>
                )}
              </div>
            )}
            {isUploading ? (
              <Button
                type="button"
                onClick={handleCancel}
                variant="link"
                className="w-max whitespace-nowrap text-red-600"
                size="sm"
              >
                Cancel upload
              </Button>
            ) : (
              <Button
                type="button"
                onClick={() => inputRef.current.click()}
                variant="link"
                className="w-max whitespace-nowrap text-green-100"
                size="sm"
              >
                {values.landscapeImage || fileName
                  ? "Change header"
                  : "Click to Browse"}
              </Button>
            )}
          </div>
          <input
            onChange={handleFile}
            ref={inputRef}
            type="file"
            hidden
            accept="image/*"
          />
        </div>
        <FormInput
          id={`headers[${index}].name`}
          name={`headers[${index}].name`}
          value={values.name}
          placeholder="E.G.....Jasmine"
          onChange={handleChange}
          label="Main Heading"
        />
        {values.subHeading.map((_, i) => (
          <FormInput
            key={i}
            id={`headers[${index}].subHeading[${i}]`}
            name={`headers[${index}].subHeading[${i}]`}
            value={values.subHeading[i]}
            placeholder="E.G.....New Arrival"
            onChange={handleChange}
            label={`Sub Heading ${i + 1}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Header;
