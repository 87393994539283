export const INVOICES = [
  {
    invoiceNumber: "146-84-9263",
    dateIssued: "2/23/2023",
    dueDate: "8/24/2022",
    billedTo: "1914 Service Circle",
  },
  {
    invoiceNumber: "644-75-1852",
    dateIssued: "2/23/2023",
    dueDate: "11/10/2022",
    billedTo: "6 Lake View Terrace",
  },
  {
    invoiceNumber: "556-99-1472",
    dateIssued: "10/16/2022",
    dueDate: "7/12/2022",
    billedTo: "89 Northfield Point",
  },
  {
    invoiceNumber: "248-89-1019",
    dateIssued: "6/22/2023",
    dueDate: "12/7/2022",
    billedTo: "0 Erie Point",
  },
  {
    invoiceNumber: "469-21-0645",
    dateIssued: "6/24/2023",
    dueDate: "1/8/2023",
    billedTo: "7 Golf View Drive",
  },
  {
    invoiceNumber: "224-63-3052",
    dateIssued: "5/21/2023",
    dueDate: "9/27/2022",
    billedTo: "82 Thackeray Crossing",
  },
  {
    invoiceNumber: "473-44-9141",
    dateIssued: "4/11/2023",
    dueDate: "3/11/2023",
    billedTo: "4744 Burning Wood Trail",
  },
  {
    invoiceNumber: "437-56-7739",
    dateIssued: "1/24/2023",
    dueDate: "11/20/2022",
    billedTo: "5450 Tennessee Point",
  },
  {
    invoiceNumber: "292-81-3063",
    dateIssued: "9/7/2022",
    dueDate: "1/14/2023",
    billedTo: "674 Burrows Circle",
  },
  {
    invoiceNumber: "576-53-9066",
    dateIssued: "11/8/2022",
    dueDate: "1/2/2023",
    billedTo: "15574 Manitowish Point",
  },
  {
    invoiceNumber: "251-36-0386",
    dateIssued: "1/10/2023",
    dueDate: "8/29/2022",
    billedTo: "43 Independence Court",
  },
  {
    invoiceNumber: "180-73-6313",
    dateIssued: "3/16/2023",
    dueDate: "11/26/2022",
    billedTo: "245 Stang Terrace",
  },
  {
    invoiceNumber: "806-47-4114",
    dateIssued: "9/30/2022",
    dueDate: "8/4/2022",
    billedTo: "7306 Shopko Way",
  },
  {
    invoiceNumber: "303-18-4942",
    dateIssued: "2/7/2023",
    dueDate: "11/19/2022",
    billedTo: "57 Dixon Hill",
  },
  {
    invoiceNumber: "632-13-7701",
    dateIssued: "1/27/2023",
    dueDate: "1/15/2023",
    billedTo: "89040 Main Terrace",
  },
  {
    invoiceNumber: "812-86-2441",
    dateIssued: "11/28/2022",
    dueDate: "7/22/2022",
    billedTo: "122 Shasta Place",
  },
  {
    invoiceNumber: "735-96-0095",
    dateIssued: "12/20/2022",
    dueDate: "4/7/2023",
    billedTo: "86 Melby Trail",
  },
  {
    invoiceNumber: "344-45-5341",
    dateIssued: "7/29/2022",
    dueDate: "4/25/2023",
    billedTo: "3 Golf Point",
  },
  {
    invoiceNumber: "415-17-1554",
    dateIssued: "1/25/2023",
    dueDate: "1/16/2023",
    billedTo: "9152 Basil Way",
  },
  {
    invoiceNumber: "141-43-3052",
    dateIssued: "10/10/2022",
    dueDate: "7/4/2023",
    billedTo: "6928 Huxley Pass",
  },
];

export const DISCOUNTS = [
  {
    code: "KFYV",
    description: "Ut tellus.",
    amount: "$9.65",
    status: "inactive",
    redemption: "not redeemed",
  },
  {
    code: "AYMB",
    description:
      "Morbi odio odio, elementum eu, interdum eu, tincidunt in, leo.",
    amount: "$3.84",
    status: "inactive",
    redemption: "redeemed",
  },
  {
    code: "KGAB",
    description:
      "Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci.",
    amount: "$8.91",
    status: "active",
    redemption: "redeemed",
  },
  {
    code: "LTBQ",
    description: "In hac habitasse platea dictumst.",
    amount: "$0.88",
    status: "inactive",
    redemption: "not redeemed",
  },
  {
    code: "LFBO",
    description: "Nulla nisl.",
    amount: "$4.36",
    status: "inactive",
    redemption: "redeemed",
  },
  {
    code: "NTGJ",
    description: "Maecenas pulvinar lobortis est.",
    amount: "$8.05",
    status: "active",
    redemption: "redeemed",
  },
  {
    code: "ETAD",
    description: "Ut tellus.",
    amount: "$4.23",
    status: "active",
    redemption: "redeemed",
  },
  {
    code: "FYTM",
    description: "Vivamus in felis eu sapien cursus vestibulum.",
    amount: "$1.71",
    status: "inactive",
    redemption: "not redeemed",
  },
  {
    code: "UARR",
    description: "Suspendisse potenti.",
    amount: "$4.39",
    status: "inactive",
    redemption: "not redeemed",
  },
  {
    code: "OOSA",
    description: "Pellentesque ultrices mattis odio.",
    amount: "$0.66",
    status: "active",
    redemption: "not redeemed",
  },
];
