import { cva } from "class-variance-authority";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import cn from "../../utils/cn";

export const buttonVariants = cva(
  "font-medium inline-flex items-center justify-center rounded-md text-sm shadow-md transition-colors active:scale-95 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-1 disabled:opacity-50 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default: "bg-green-100 text-white hover:bg-[#0a7965]",
        link: "text-green-100 shadow-none hover:underline focus:ring-transparent active:text-[#0a7965] active:scale-100",
        outline:
          "bg-white text-dark-100 border border-neutral-450 hover:bg-slate-50",
        ghost:
          "bg-transparent text-dark-100 shadow-none hover:text-slate-900 hover:bg-slate-200",
        black: "bg-black text-white hover:bg-gray-900",
      },
      size: {
        default: "h-10 py-2 px-4",
        sm: "h-9 px-2",
        lg: "h-11 px-8",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

const Button = ({
  className,
  children,
  variant,
  isLoading,
  size,
  type = "button",
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      className={cn(buttonVariants({ variant, size, className }))}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />}
      {children}
    </button>
  );
};

export default Button;
