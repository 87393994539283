import { ArrowDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import Select from "react-select";

function FormSelect(props) {
  const { id, label, labelIcon, error, errorMsg, isRequired, ...rest } = props;
  return (
    <div className="rounded-md border border-neutral-400 bg-[#F4F6F6] p-4">
      <div className="mb-2 flex items-center gap-0.5 text-10 font-medium text-neutral-100">
        <label htmlFor={id}>{label}</label>
        {isRequired && <span className="font-black text-[#FF0606]">*</span>}
        {labelIcon}
      </div>
      <Select
        id={id}
        required={isRequired}
        isClearable
        classNames={{
          container: () => "w-full",
          control: () =>
            "h-8 !min-h-[2rem] !border-none !bg-[#F4F6F6] text-xs text-neutral-200",
          input: () => "react-select__input",
        }}
        components={{ DropdownIndicator }}
        {...rest}
      />
      {error && !!errorMsg && (
        <small id={id} className="mt-1 block text-xs text-red-600">
          {errorMsg}
        </small>
      )}
    </div>
  );
}

const DropdownIndicator = () => (
  <div className="grid place-items-center p-2">
    <ArrowDownIcon className="h-4 w-4 text-neutral-100" />
  </div>
);

export default FormSelect;
