import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Fragment } from "react";
import * as yup from "yup";
import Button from "../../components/commons/button";
import IconButton from "../../components/commons/icon-button";
import { FormInput } from "../../components/form";
import { CloseIcon } from "../../components/icons";
import Metadata from "./MetaData";

const METADATA = { key: "", value: "" };

export default function CreateCustomerGroup({ isOpen, setIsOpen }) {
  const closeModal = () => setIsOpen(false);

  const validationSchema = yup.object().shape({
    invoiceNumber: yup.string().required("Invoice number is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
  });

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      metadata: [],
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {});
    },
  });

  const handleAdd = () => {
    setFieldValue("metadata", [...values.metadata, METADATA]);
  };

  const handleRemove = index => {
    const newArr = values.metadata.filter((_, idx) => idx !== index);
    setFieldValue("metadata", newArr);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-screen-md transform overflow-hidden rounded-lg bg-white p-2 pb-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end">
                  <IconButton
                    variant="ghost"
                    onClick={closeModal}
                    icon={<CloseIcon />}
                    description="Close modal"
                    className="h-5 w-5 rounded-md p-0"
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="px-6">
                    <Dialog.Title
                      as="h3"
                      className="mb-9 text-base font-semibold sm:text-xl"
                    >
                      Create a New Customer Group
                    </Dialog.Title>
                    <div className="mb-8 flex flex-col gap-4">
                      <p className="text-sm font-semibold text-dark-100">
                        Details
                      </p>
                      <FormInput
                        id="title"
                        name="title"
                        value={values.title}
                        placeholder="Create customer group"
                        error={Boolean(errors.title)}
                        onChange={handleChange}
                        label="Title"
                        errorMsg={errors.title}
                        isRequired
                      />
                      <p className="text-sm font-semibold text-dark-100">
                        Metadata
                      </p>
                      {values.metadata.map((_, index) => (
                        <Metadata
                          key={index}
                          index={index}
                          values={values.metadata[index]}
                          errors={errors}
                          handleChange={handleChange}
                          handleRemove={handleRemove}
                        />
                      ))}
                      <Button
                        onClick={handleAdd}
                        variant="outline"
                        className="w-full gap-1 border-neutral-450 text-xs font-semibold text-dark-100 shadow-none"
                      >
                        <PlusIcon className="w-4 text-lg" />
                        Add Metadata
                      </Button>
                    </div>
                  </div>
                  <div className="flex justify-between gap-5 border-t border-neutral-300 px-6 pt-4 sm:justify-end">
                    <Button
                      variant="ghost"
                      onClick={closeModal}
                      className="flex-1 shadow-none sm:flex-none"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" className="flex-1 sm:flex-none">
                      Publish Group
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
