import { useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../components/commons/breadcrumbs";
import BusyOverlay from "../../../components/commons/busy-overlay";
import Filters from "../../../components/commons/filters";
import Pagination from "../../../components/commons/pagination";
import ProductCard from "../../../components/storefront/product-card";
import useDebounce from "../../../hooks/useDebounce";
import {
  useGetProductList,
  useGetStorefront,
} from "../../../operations/queries";
import { MAX_PRICE } from "../../../utils/storefront";
import { pluralize } from "../../../utils/text";
import SEO from "../../../components/commons/seo";

const PAGE_SIZE = 13;

function AllProductsPage() {
  const { storeUrl } = useParams();
  const { data } = useGetStorefront(storeUrl);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") ?? 1;
  const search = searchParams.get("search");
  const startingPrice = searchParams.get("startingPrice");
  const endingPrice = searchParams.get("endingPrice") ?? MAX_PRICE;
  const debouncedSearch = useDebounce(search, 1000);
  const debouncedStartingPrice = useDebounce(startingPrice, 1000);
  const debouncedEndingPrice = useDebounce(endingPrice, 1000);
  const isPriceFiltered = debouncedStartingPrice && debouncedEndingPrice;

  const store = data?.data?.data?.store;
  const { data: response, isLoading } = useGetProductList({
    storeId: store?.id,
    search: debouncedSearch,
    page,
    pageSize: PAGE_SIZE,
    ...(isPriceFiltered && {
      startingPrice: debouncedStartingPrice,
      endingPrice: debouncedEndingPrice,
    }),
  });

  const products = response?.data?.data;
  const pagination = response?.data?.pagination;

  const breadcrumbs = [
    {
      name: "Home",
      path: `/${storeUrl}`,
    },
    {
      name: "All Products",
      path: `/${storeUrl}/all`,
    },
  ];

  return (
    <div className="mb-20">
      <SEO
        title={`All Products | ${store?.storeName}`}
        description={store?.storeDescription}
        alt={store?.storeName}
        image={store?.storeLogo}
      />
      <BusyOverlay loading={isLoading} />
      <div className="px-4 pt-4">
        <Breadcrumbs breadcrumbs={breadcrumbs} color={store?.brandColor} />
      </div>
      <div className="p-4">
        <h1 className="mb-6 text-2xl font-semibold md:text-40">All products</h1>
        <Filters />
        <p className="mb-10 text-base text-neutral-200 md:text-lg">
          {pluralize(pagination?.total, "product")}
        </p>

        <div className="mb-6 grid grid-cols-2 grid-rows-6 gap-4 sm:grid-rows-3 sm:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {products?.map(product => (
            <ProductCard
              key={product.id}
              product={product}
              className="first-of-type:col-span-2 first-of-type:row-span-2"
            />
          ))}
        </div>
        {pagination && (
          <Pagination
            currentPage={parseInt(pagination.page)}
            totalCount={pagination.total}
            pageSize={parseInt(pagination.pageSize)}
            onPageChange={page => {
              searchParams.set("page", page);
              setSearchParams(searchParams);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default AllProductsPage;
