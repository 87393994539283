import React from "react";
import SEO from "../../../components/commons/seo";

const MailPage = () => {
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <SEO title="Mail" />
    </div>
  );
};

export default MailPage;
