import React from "react";
import cn from "../../utils/cn";
import { AdditionalDetails } from "./AdditionalDetails";
import { MainDetails } from "./MainDetails";
import ProductDetails from "./ProductDetails";

const OrderInfo = ({ order }) => {
  return (
    <div className="space-y-8 pb-8">
      <div className="mt-8 flex items-center gap-2.5">
        <div
          className={cn(
            "h-2 w-2 shrink-0 rounded-full bg-red-600",
            order?.orderStatus === "Paid" && "bg-green-600",
          )}
        />
        <p className="text-sm font-normal text-[#111827]">
          {order?.orderStatus}
        </p>
      </div>
      <MainDetails order={order} />
      <AdditionalDetails order={order} />
      <ProductDetails order={order} />
    </div>
  );
};

export default OrderInfo;
