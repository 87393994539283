import React from "react";

function Newsletter({ color }) {
  return (
    <div className="container mx-auto mb-20">
      <div
        className="bg-dark-200 px-5 py-10 sm:rounded-md md:px-16 lg:rounded-2xl"
        style={{ backgroundColor: color }}
      >
        <div className="flex flex-col items-center justify-between space-y-6 lg:flex-row ">
          <div className="w-full text-left lg:w-2/5">
            <h3 className="mb-6 text-lg font-medium text-white lg:mb-8 lg:text-30">
              Subscribe to our emails
            </h3>
            <p className="pb-6 text-sm text-white lg:text-base lg:leading-6">
              Subscribe to receive the latest news and updates
            </p>
          </div>
          <div className="flex w-full justify-between rounded-lg bg-white py-2 pl-3 pr-2 lg:w-96">
            <input
              type="email"
              placeholder="Enter email address"
              className="w-3/5 border-none text-10 text-neutral-100 outline-none placeholder:text-10 placeholder:text-neutral-200 focus:border-0 md:text-13 md:placeholder:text-13  "
            />
            <button
              className="ml-onclick-form px-8/5 w-2/6 rounded-md bg-dark-100 py-3 text-10 text-white shadow-lg md:text-sm"
              style={{ backgroundColor: color }}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
