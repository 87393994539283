import React from "react";
import { TitleAndContent } from "../order/TitleAndContent";

const ProductDescription = ({ product }) => {
  return (
    <div className="mt-[0.688rem] h-[full] w-full rounded-[8px] border-[1px] px-[1.563rem] py-[2.063rem] lg:w-[53rem]">
      <div className="flex w-[100%] justify-end gap-[20px] md:gap-[0px]">
        <div className="flex items-center gap-2.5">
          <div className="h-2 w-2 shrink-0 rounded-full bg-green-600" />
          <p className="text-[0.75rem] font-medium leading-none text-[#111827]">
            Published
          </p>
        </div>
      </div>
      <div>
        <h3 className="mt-[4.25rem] text-[0.875rem] font-bold text-[#111827]">
          Details
        </h3>
        <TitleAndContent title="Type" content={product?.productType || "NIL"} />
        <TitleAndContent title="SKU" content={product?.productSKU || "NIL"} />
        <TitleAndContent
          title="Quantity"
          content={product?.productQuantity || "NIL"}
        />
        <TitleAndContent
          title="Collection"
          content={product?.productCollection?.name || "NIL"}
        />
        <TitleAndContent
          title="Discountable"
          content={product?.productSetting?.discount?.discountable || "NIL"}
        />
        {!!product?.productVariants?.length && (
          <div className="text-[0.875rem] font-medium text-[#6B7280]">
            <h3 className="font-bold text-[#111827]">Variants</h3>
            {product?.productVariants?.map((variant, index) => (
              <TitleAndContent
                key={variant.variantHeader + index}
                title={variant.variantHeader}
                content={variant.variants
                  .map(variant => variant.variantName)
                  .join(", ")}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDescription;
