import HeaderComponent from "../../components/header";
import HeroSection from "./hero-section";
import ECommerceToolComponent from "./e-commerce-tool";
import SellProducts from "./sell-products";
import MarketPlace from "./market-place";
import CustomersComponent from "./customers";
import CreateStore from "./create-store";
import Subscribe from "./subscribe";
import FooterComponent from "../../components/footer";
import SEO from "../../components/commons/seo";

const LandingPage = () => {
  return (
    <div>
      <SEO withoutTemplate />
      <HeaderComponent />
      <HeroSection />
      <ECommerceToolComponent />
      <SellProducts />
      <MarketPlace />
      <CustomersComponent />
      <CreateStore />
      <Subscribe />
      <FooterComponent />
    </div>
  );
};

export default LandingPage;
