import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CloseIcon,
  CloseRedIcon,
  GreenDot,
  OrangeDot,
  Sneakers,
} from "../../constants/assets-constants";

const NotificationContents = [
  {
    productName: "Sneakers",
    ProductImages: `${Sneakers}`,
    statusButton: `${OrangeDot}`,
    status: "pending",
  },
  {
    productName: "Sneakers",
    ProductImages: `${Sneakers}`,
    status: "Approved",
    statusButton: `${GreenDot}`,
  },
  {
    productName: "Sneakers",
    ProductImages: `${Sneakers}`,
    status: "Approved",
    statusButton: `${GreenDot}`,
  },
  {
    productName: "Sneakers",
    ProductImages: `${Sneakers}`,
    status: "Failed",
    statusButton: `${CloseRedIcon}`,
  },
];

const NotificationModal = ({ isOpen, setIsOpen }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="md:w-[22.6875rem] w-full m-[1rem] relative p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-[#1F1F1F] text-[1.25rem] font-bold"
                >
                  Notification
                </Dialog.Title>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  className="absolute top-[20px] right-[20px] cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
                <div className="mt-[1rem]">
                  {NotificationContents.map((nc, index) => {
                    return (
                      <NotificationContentsContainer
                        key={index}
                        image={nc.ProductImages}
                        title={nc.productName}
                        statusbutton={nc.statusButton}
                        status={nc.status}
                      />
                    );
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NotificationModal;

const NotificationContentsContainer = ({
  image,
  title,
  statusbutton,
  status,
}) => {
  return (
    <div className="flex justify-between pb-[1rem] pt-[1rem] border-b-[1px] border-b-[#DCDCDC]">
      <div className="flex gap-[15px]">
        <img
          src={image}
          alt="productImage"
          className="w-[2rem] h-[2.5625rem]"
        />
        <div className="flex items-center">
          <p className="text-[0.75rem] text-[#525252] font-medium">{title}</p>
        </div>
      </div>
      <div
        className={` ${
          status === "Approved" ? "flex" : "hidden"
        } items-center justify-center`}
      >
        <button className="text-center text-[0.75rem] font-medium text-[#525252] w-[3.75rem] h-[1.5rem] rounded-[0.375rem] border-[1px] border-[#D9D9D9]">
          View
        </button>
      </div>
      <div className="flex w-[5rem] justify-between">
        <div className="flex items-center">
          <img
            src={statusbutton}
            alt="button"
            className="w-[0.825rem] h-[0.825rem]"
          />
        </div>
        <div className="flex items-center">
          <p className="text-[#898989] text-[0.75rem] font-medium">{status}</p>
        </div>
      </div>
    </div>
  );
};
