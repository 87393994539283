const pricingLists = [
  {
    price: "free",
    type: "Forever Free",
    rate: "monthly",

    benefits: [
      "All forms of Digital Media",
      "Automatic Fulfillment/Logistics(for Physical Goods)",
      "Inventory Management",
      "Payable Invoice Creator",
      "Free Market Place Listings",
      "Basic Store Front Customization",
      "1GB Storage",
    ],
  },
  {
    priceNaira: "12000",
    priceDol: "14",
    type: "Premium",
    rate: "monthly",
    extraMsg: "More tools to grow your business",
    benefits: [
      "Everything in Free Plan",
      "Offer Subscription Services",
      "Custom Domain Name",
      "Dynamic Header Page",
      "Full Store Front Customization",
      "1week Promoted Listings on marketplace",
      "15GB Storage",
    ],
  },
  {
    price: "free",
    type: "Forever Free",
    rate: "annually",

    benefits: [
      "All forms of Digital Media",
      "Automatic Fulfillment/Logistics(for Physical Goods)",
      "Inventory Management",
      "Payable Invoice Creator",
      "Free Market Place Listings",
      "Basic Store Front Customization",
      "1GB Storage",
    ],
  },
  {
    priceNaira: "120000",
    priceDol: "140",
    type: "Premium",
    rate: "annually",
    extraSaveNaira: "24000",
    extraSaveDol: "28",
    extraMsg: "More tools to grow your business",
    benefits: [
      "Everything in Free Plan",
      "Offer Subscription Services",
      "Custom Domain Name",
      "Dynamic Header Page",
      "Full Store Front Customization",
      "1week Promoted Listings on marketplace",
      "15GB Storage",
    ],
  },
];

export default pricingLists;
