import SEO from "../../components/commons/seo";
import FooterComponent from "../../components/footer";
import HeaderComponent from "../../components/header";

export default function AcceptableUsePolicyPage() {
  return (
    <div>
      <HeaderComponent />
      <SEO title="Acceptable Use Policy" />
      <main className="px-4 py-10 text-sm text-neutral-100 sm:px-1/6 sm:text-lg">
        <h1 className="mb-4 text-2xl font-semibold text-dark-100 md:text-4xl">
          Acceptable Use Policy
        </h1>
        <p className="mb-2">
          Digible provides a commerce platform that enables merchants to manage
          sales, inventory, payments, fulfillment, shipping, business
          operations, marketing, and engage with existing and potential
          customers.The actions Users take can have a big impact on our system
          as a whole. That’s why all Digible Users must follow this Acceptable
          Use Policy in their use of the Services. If you violate this policy,
          we may suspend or terminate your account. This Acceptable Use Policy
          (“AUP”) describes activities that are prohibited in connection with
          your use of the Services. We may also suspend or terminate accounts
          according to our Terms of Use where we see Fit, or other factors that
          pose a threat to our platform.
        </p>
        <ol className="ml-4 list-decimal space-y-2">
          <li>
            <h2 className="font-semibold">Prohibited Activities</h2>
            You may not use Digible in connection with any product, service,
            transaction or activity that:
            <ul className="ml-4 list-disc space-y-1">
              <li>
                violates any law or government regulation, or promotes or
                facilitates such by third parties;
              </li>
              <li>violate the CAN-SPAM act or other anti-spam laws;</li>
              <li>
                exploit or abuse children, including but not limited to images
                or depictions of child abuse or sexual abuse, or that present
                children in a sexual manner;
              </li>
              <li>
                harass, bully, defame or threaten a specific individual or group
                of individuals;
              </li>
              <li>
                promote or condone hate or violence against people based on
                race, ethnicity, color, national origin, religion, age, gender,
                sexual orientation, disability, medical condition;
              </li>
              <li>promote self-harm;</li>
              <li>
                violates any rule or regulation of Visa, MasterCard, Verve or
                any other electronic funds transfer network (each, a “Card
                Network”);
              </li>
              <li>is illegal, fraudulent, deceptive, unfair or predatory;</li>
              <li>promote or support terrorism or violent extremism;</li>
              <li>
                involves any of the prohibited business categories listed in
                clause 2; or
              </li>
              <li>
                results in or creates a significant risk of chargebacks,
                penalties, damages or other harm or liability.
              </li>
            </ul>
          </li>
          <li>
            <h2 className="font-semibold">Prohibited Business Categories</h2>
            You may not use Digible in connection with any product, service,
            transaction or activity that:
            <ul className="ml-4 list-disc space-y-1">
              <li>
                falls within the Prohibition List of the Nigerian Customs
                Administration of the Federal Republic of Nigeria
              </li>
              <li>relates to the sale and/or purchase of:</li>
              <ol className="ml-4 list-decimal space-y-1">
                <li>
                  banned narcotics, steroids, certain controlled substances or
                  other products that present a risk a consumer’s safety;
                </li>
                <li>blood, bodily fluids or body parts;</li>
                <li>burglary tools;</li>
                <li>counterfeit items;</li>
                <li>illegal drugs and drug paraphernalia;</li>
                <li>fireworks, destructive devices and explosives;</li>
                <li>
                  identity documents, government documents, personal financial
                  records or personal information (in any form, including
                  mailing lists);
                </li>
                <li>
                  lottery tickets, sweepstakes entries or slot machines without
                  the required license;
                </li>
                <li>
                  offensive material or hate speech or items that promote hate,
                  violence, racial intolerance, or the financial exploitation of
                  a crime;
                </li>
                <li>chemicals;</li>
                <li>recalled items;</li>
                <li>prohibited services;</li>
                <li>
                  unlicensed financial services, stocks or other securities;
                </li>
                <li>stolen property;</li>
                <li>
                  items that infringe or violate any copyright, trademark, right
                  of publicity or privacy or any other proprietary right under
                  the laws of any jurisdiction;
                </li>
                <li>
                  sales of currency without BDC license, cryptocurrency
                  operators;
                </li>
                <li>obscene material or pornography;</li>
                <li>certain sexually oriented materials or services;</li>
                <li>
                  certain firearms, firearm parts or accessories, ammunition,
                  weapons or knives;
                </li>
                <li>
                  any product or service that is illegal or marketed or sold in
                  such a way as to create liability to Digible
                </li>
                <li>
                  production of military and paramilitary wears and
                  accoutrement, including those of the Police and the Customs,
                  Immigration and Prison Services.
                </li>
              </ol>
              <li>
                We may, at any time and without notice, remove any Materials,
                and suspend or terminate your Account or your access to the
                Services if you engage in activities that violate the letter or
                spirit of this AUP, including activities outside of your use of
                the Services.Digible has the right, but not the obligation, to
                monitor or investigate any Materials and your use of the
                Services at any time for compliance with this AUP and the
                Digible Terms of Service, or any other agreement between you and
                Digible.one governing your use of the Services (collectively,
                the “Terms”). Our determination of whether a violation of this
                AUP has occurred will be final and binding, and any action taken
                with respect to enforcing this AUP, including taking no action
                at all, will be at our sole discretion.Digible may choose to
                modify this AUP, including the list of Restricted Items, at any
                time. By continuing to use the Services or access your Account
                after a revised version of the AUP has been posted, you agree to
                comply with the latest version of the AUP. In the event of a
                conflict between the AUP and the Terms, this AUP will take
                precedence, but only to the extent required to resolve such
                conflict. Capitalized terms used but not defined in this AUP
                shall have the meanings set forth in the Terms.
              </li>
            </ul>
          </li>
        </ol>
      </main>
      <FooterComponent />
    </div>
  );
}
