import { createColumnHelper } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import {
  Dot,
  ExportIcon,
  NigerianFlag,
} from "../../constants/assets-constants";
import DataTable from "../commons/data-table";
import SearchInput from "../commons/search-input";
import ExportOrder from "../order/ExportOrder";
import useDebounce from "../../hooks/useDebounce";
import formatPrice from "../../utils/formatPrice";
import { useGetStoreWithdrawal } from "../../operations/queries";
import { useSearchParams } from "react-router-dom";
import BusyOverlay from "../commons/busy-overlay";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("withdrawalReference", { header: "Reference Number" }),
  columnHelper.accessor("createdAt", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
  }),
  columnHelper.accessor("bank.bankName", { header: "Bank Name" }),
  columnHelper.accessor("bank", {
    header: "Account Number",
    cell: info =>
      `${info.getValue().accountName} ${info.getValue().accountNumber}`,
  }),
  columnHelper.accessor("withdrawalStatus", {
    header: "Payment Status",
    cell: info => (
      <div className="flex justify-start lg:justify-center">
        <div className="flex gap-[10px]">
          <img src={Dot} alt="dot" />{" "}
          <p className="max-w-[7.5625rem] text-[0.75rem] font-medium text-[#525252] ">
            {info.getValue()}
          </p>
        </div>
      </div>
    ),
  }),
  columnHelper.accessor("withdrawalAmount", {
    header: "Amount",
    cell: info => formatPrice(info.getValue()),
  }),
  columnHelper.accessor("flag", {
    header: "",
    cell: () => <img src={NigerianFlag} className="h-4 w-6" alt="flag" />,
  }),
];

const WithdrawalBody = () => {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: parseInt(page) - 1,
    pageSize: 10,
  });
  const search = searchParams.get("search") ?? "";
  const debouncedSearch = useDebounce(search, 1000);

  const { data, isLoading, isPreviousData } = useGetStoreWithdrawal({
    search: debouncedSearch,
    page: pageIndex + 1,
    pageSize,
  });

  const withdrawals = useMemo(() => data?.sortedWithdrawals ?? [], [data]);
  const totalCount = data?.pagination?.total;

  return (
    <div className="mb-[2rem] rounded-lg border border-neutral-300 p-6 shadow">
      <BusyOverlay loading={isLoading} />
      <div className="mb-[20px] flex flex-col items-start gap-4 font-semibold sm:flex-row sm:items-center sm:justify-between">
        <div className="flex gap-2.5">
          <div className="flex cursor-pointer items-center whitespace-nowrap text-[1rem] font-semibold text-[#1F1F1F]">
            Withdrawal History
          </div>
        </div>
        <button
          className="hidden items-center gap-2.5 rounded-md border px-[1.0rem] py-[0.7rem] hover:bg-slate-200"
          onClick={() => setIsOpenExport(true)}
        >
          <img src={ExportIcon} alt="export" />
          <p className="text-sm">Export History</p>
        </button>
      </div>
      <div className="mb-6 hidden justify-end">
        <SearchInput
          placeholder="Search"
          query={search}
          setQuery={value => {
            setPagination({ pageIndex: 0, pageSize: 10 });
            searchParams.set("search", value);
            setSearchParams(searchParams);
          }}
          containerClass="w-full sm:w-auto"
          iconClass="h-4 w-4 sm:h-5 sm:w-5"
          inputClass="text-sm"
        />
      </div>
      <DataTable
        label="Withdrawals"
        data={withdrawals}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={totalCount}
        isLoading={isLoading || isPreviousData}
        setPagination={setPagination}
      />
      <ExportOrder isOpen={isOpenExport} setIsOpen={setIsOpenExport} />
    </div>
  );
};

export default WithdrawalBody;
