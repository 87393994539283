import React from "react";
import cn from "../../utils/cn";

function FormInput(props) {
  const {
    className,
    id,
    label,
    isRequired,
    labelIcon,
    type = "text",
    errorMsg,
    error,
    ...rest
  } = props;
  return (
    <div
      className={cn(
        "rounded-md border border-neutral-400 bg-[#F4F6F6] p-4",
        className,
      )}
    >
      <div className="mb-2 flex items-center gap-0.5 text-10 font-medium text-neutral-100">
        <label htmlFor={id}>{label}</label>
        {isRequired && <span className="font-black text-[#FF0606]">*</span>}
        {labelIcon}
      </div>
      <input
        type={type}
        className="w-full border-none bg-transparent p-0 text-xs text-neutral-200 focus:ring-transparent disabled:text-neutral-200"
        id={id}
        aria-describedby={id}
        required={isRequired}
        {...rest}
      />
      {error && !!errorMsg && (
        <small id={id} className="mt-1 block text-xs text-red-600">
          {errorMsg}
        </small>
      )}
    </div>
  );
}

export default FormInput;
