import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { MarketplaceNavigation } from "../components/marketplace/MarketplaceNavigation";
import WrapperLayout from "../layouts/MainLayout";
import StorefrontLayoutWrapper from "../layouts/StorefrontLayout";
import AboutUsPage from "../pages/about";
import AcceptableUsePolicyPage from "../pages/acceptable-use-policy";
import ForgotPasswordPage from "../pages/auth/forgot-password";
import LoginPage from "../pages/auth/login";
import NewPasswordPage from "../pages/auth/new-password";
import CreateStorePage from "../pages/auth/signup/create-store";
import SignUserUp from "../pages/auth/signup/sign-up";
import VerifyNewAccount from "../pages/auth/signup/verify-account";
import VerifyPasswordCodePage from "../pages/auth/verify-email-code";
import CustomersPage from "../pages/customers";
import CustomerDetailsPage from "../pages/customers/customer-details";
import DashboardPage from "../pages/dashboard";
import LandingPage from "../pages/home";
import InvoicePage from "../pages/invoice";
import InvoiceCreatorPage from "../pages/invoice-creator";
import CreateInvoicePage from "../pages/invoice-creator/create-invoice";
import MarketPlacePage from "../pages/marketplace";
import { MarketPlaceCartPage } from "../pages/marketplace/marketplace-cart";
import { Marketplacedetails } from "../pages/marketplace/marketplace-details/index";
import NotFoundPage from "../pages/not-found";
import OrdersPage from "../pages/orders";
import OrderDetails from "../pages/orders/order-details";
import PricingPage from "../pages/pricing";
import PrivacyPolicyPage from "../pages/privacy-policy";
import DigitalProduct from "../pages/products/DigitalProduct";
import { EditProduct } from "../pages/products/EditProduct";
import PhysicalProduct from "../pages/products/PhysicalProduct";
import ProductsPage from "../pages/products/index";
import ProductDetails from "../pages/products/product-details";
import SettingsPage from "../pages/settings";
import CurrencyPage from "../pages/settings/currency-page";
import MailPage from "../pages/settings/mail-page";
import PersonalInformationPage from "../pages/settings/personal-information-page";
import RegionsPage from "../pages/settings/regions-page";
import ReturnReasonsPage from "../pages/settings/return-reasons-page";
import ShippingPage from "../pages/settings/shipping-page";
import StoreDetailsPage from "../pages/settings/store-details-page";
import TheTeamPage from "../pages/settings/theteam-page";
import StoreManagementPage from "../pages/store-management";
import StorefrontPage from "../pages/storefront";
import StorefrontTemplatesPage from "../pages/storefront-templates";
import AboutPage from "../pages/storefront/about-page";
import AllProductsPage from "../pages/storefront/all-products-page";
import CartPage from "../pages/storefront/cart-page";
import CheckoutPage from "../pages/storefront/checkout-page";
import CollectionPage from "../pages/storefront/collection-page";
import ProductPage from "../pages/storefront/product-page";
import TermsOfUsePage from "../pages/terms-of-use";
import VerifyOrderPage from "../pages/verify-order-page";
import VerifySubscriptionPage from "../pages/verify-subscription-page";
import Withdrawal from "../pages/withdrawal";
import AuthGuard from "./guards/auth-guard";
import GuestGuard from "./guards/guest-guard";
import Providers from "../providers";

const routes = createRoutesFromElements(
  <Route element={<Providers />}>
    {/* Public Routes */}
    <Route path="/" element={<LandingPage />} />
    <Route path="/404" element={<NotFoundPage />} />
    <Route path="/complete" element={<VerifyOrderPage />} />
    <Route path="/verify" element={<VerifySubscriptionPage />} />
    <Route path="/about" element={<AboutUsPage />} />
    <Route path="/pricing" element={<PricingPage />} />
    <Route
      path="/acceptable-use-policy"
      element={<AcceptableUsePolicyPage />}
    />
    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="/terms-of-use" element={<TermsOfUsePage />} />
    {/* Auth Routes */}
    <Route element={<GuestGuard />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUserUp />} />
      <Route path="/create-store" element={<CreateStorePage />} />
      <Route path="/verify-account" element={<VerifyNewAccount />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/new-password" element={<NewPasswordPage />} />
      <Route path="/verify-email" element={<VerifyPasswordCodePage />} />
    </Route>
    {/* Dashboard Routes */}
    <Route element={<AuthGuard />}>
      <Route element={<WrapperLayout />}>
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/orders/:orderId" element={<OrderDetails />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:productId" element={<ProductDetails />} />
        <Route path="/edit-product/:productId" element={<EditProduct />} />
        <Route
          path="/products/physical-product"
          element={<PhysicalProduct />}
        />
        <Route
          path="/products/:physicalProductId"
          element={<PhysicalProduct />}
        />
        <Route path="/products/digital-product" element={<DigitalProduct />} />
        <Route path="/products/digitalProductId" element={<DigitalProduct />} />
        <Route path="/customers" element={<CustomersPage />} />
        <Route
          path="/customers/:customerId"
          element={<CustomerDetailsPage />}
        />
        <Route path="/store-management" element={<StoreManagementPage />} />
        <Route
          path="/storefront-templates"
          element={<StorefrontTemplatesPage />}
        />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route
          path="/settings/personal-information"
          element={<PersonalInformationPage />}
        />
        <Route path="/settings/regions" element={<RegionsPage />} />
        <Route
          path="/settings/return-reasons"
          element={<ReturnReasonsPage />}
        />
        <Route path="/settings/currencies" element={<CurrencyPage />} />
        <Route path="/settings/store-details" element={<StoreDetailsPage />} />
        <Route path="/settings/shipping" element={<ShippingPage />} />
        <Route path="/settings/the-team" element={<TheTeamPage />} />
        <Route path="/settings/mail" element={<MailPage />} />
        {/* <Route  path="/discounts" element={<DiscountsPage />} /> */}
        <Route path="/invoice-creator" element={<InvoiceCreatorPage />} />
        <Route path="/invoice-creator/create" element={<CreateInvoicePage />} />
        <Route path="/invoice-creator/view" element={<InvoicePage />} />
      </Route>
    </Route>
    {/* Storefront Routes */}
    <Route element={<StorefrontLayoutWrapper />}>
      <Route path="/:storeUrl" element={<StorefrontPage />} />
      <Route path="/:storeUrl/product/:productId" element={<ProductPage />} />
      <Route path="/:storeUrl/all" element={<AllProductsPage />} />
      <Route
        path="/:storeUrl/collection/:collectionId"
        element={<CollectionPage />}
      />
      <Route path="/:storeUrl/cart" element={<CartPage />} />
      <Route path="/:storeUrl/checkout" element={<CheckoutPage />} />
      <Route path="/:storeUrl/about" element={<AboutPage />} />
    </Route>
    {/* Marketplace Routes */}
    <Route element={<MarketplaceNavigation />}>
      <Route path="/marketplace" element={<MarketPlacePage />} />
      <Route path="/market-details/:id" element={<Marketplacedetails />} />
      <Route path="/marketplace/cart" element={<MarketPlaceCartPage />} />
    </Route>
    {/* Redirect to 404 Page */}
    <Route path="*" element={<NotFoundPage />} />
  </Route>,
);

export default createBrowserRouter(routes);
