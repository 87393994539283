import React from "react";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import FooterComponent from "../../components/footer";
import HeaderComponent from "../../components/header";
import {
  DaveAvatar,
  EllipsePink,
  GroupedGreenDots,
  OchukoAvatar,
  PolygonGreen,
  PolygonLime,
  PolygonPink,
} from "../../constants/assets-constants";
import SEO from "../../components/commons/seo";

function AboutUsPage() {
  return (
    <div>
      <SEO title="About Us" />
      <HeaderComponent />
      <div className="relative mt-10 px-1/6 pb-40 pt-10 text-center md:pt-20">
        <img
          src={GroupedGreenDots}
          className="absolute left-0 top-3/4 hidden lg:block"
          alt=""
        />
        <img
          src={PolygonGreen}
          className="absolute left-3/4 top-0 block w-12 md:left-1/2 md:w-auto"
          alt=""
        />
        <img
          src={PolygonLime}
          className="absolute right-1/45 top-1/12 hidden md:block"
          alt=""
        />
        <img
          src={EllipsePink}
          className="absolute left-1/45 top-1/10 hidden md:block"
          alt=""
        />
        <div className="relative mx-auto w-full lg:w-1/70">
          <img
            src={PolygonPink}
            className="absolute bottom-4 right-1/6 hidden md:block"
            alt=""
          />
          <TitleComponent isMedium text="About Us" />
          <div className="mt-3 flex flex-col space-y-8 text-base leading-6 text-neutral-100 md:mt-10 lg:text-xl lg:leading-8">
            <p>
              Digible is a platform for creators with Digital Products, such as
              Ebooks, courses and other Digital resources that can be sold
              online. Digible is also a platform for Small Businesses, Service
              Providers, and Professionals to accept Payments online through a
              simplified Storefront Builder / Invoice Creator.
            </p>
            <p>
              Create Fully Customizable Store Fronts with your brand logo and
              colours.
            </p>
            <p>
              With our Marketplace feature you can promote your products and
              reach more customers
            </p>
            <p>
              Sell Physical Goods? We have got you covered with our automatic
              fulfillment integration you never have to worry about Shipping to
              your customers (Currently only available in Nigeria). Manage your
            </p>
            <p>
              Run Subscription Based Services and Accept Recurring Payments from
              your clients."
            </p>
            <p>
              With our Simplified Dashboard you can track Important Metrics
              upload and setup Your store Fronts in 5minutes or less.
            </p>

            <p>
              With Our Simplified feature of verifying and connecting Bank
              accounts You can accept payments in Multiple Currencies through
              our wallet system without needing a 3rd party payment processor.
            </p>
          </div>
        </div>
        <div className="mt-12 md:mt-32">
          <TitleComponent isMedium text="Our Team" />
          <div className="mt-12 flex flex-col justify-center space-x-0 space-y-10 md:mt-10 md:flex-row md:space-x-32 md:space-y-0">
            <div className="flex w-full flex-col items-center space-y-1 md:w-1/43 md:space-y-3 lg:w-2/6 xl:w-1/20 ">
              <img
                className="mb-1 w-2/5 object-contain md:w-full"
                src={DaveAvatar}
                alt=""
              />
              <TitleComponent text="Dave Momodu" />
              <ContentComponent text="Founder" />
            </div>

            <div className="flex w-full flex-col items-center  space-y-3 !whitespace-nowrap md:w-1/43 lg:w-2/6 xl:w-1/20">
              <img
                className="w-2/5 object-contain md:w-full"
                src={OchukoAvatar}
                alt=""
              />
              <TitleComponent text="Ochucko Akpomudjere" />
              <ContentComponent text="COO" />
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default AboutUsPage;
