import { React } from "react";

export const TitleAndContent = ({ title, content }) => {
  return (
    <div className="mb-[0.75rem] mt-[0.75rem] flex w-[100%] justify-between">
      <p className="text-[0.875rem] font-medium text-[#6B7280]">{title}</p>
      <p className="text-[0.875rem] font-medium text-[#6B7280]">{content}</p>
    </div>
  );
};
