function CustomerDetails({ customer }) {
  if (!customer) return "Customer not found";

  return (
    <div className="mb-6 rounded-md border border-neutral-200 bg-[#EDFFF5] px-4 py-10">
      <div className="h-[100px]" />
      <div>
        <div className="mb-4 grid h-10 w-10 place-items-center rounded-full bg-green-100 text-25 font-semibold leading-none text-white sm:h-14 sm:w-14">
          {`${customer.fullName.charAt(0)}`}
        </div>
        <p className="mb-2 font-medium sm:px-2">{customer.fullName}</p>
        <div className="flex justify-between">
          <div className="flex items-center gap-2 sm:px-2">
            <div className="font-medium">
              <h6 className="text-10 text-neutral-100">First seen</h6>
              <p className="text-xs text-neutral-200">
                {new Intl.DateTimeFormat("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }).format(new Date(customer.createdAt))}
              </p>
            </div>
            {/* <div className="h-[33px] w-[1px] bg-neutral-300" />
                <div className="font-medium">
                  <h6 className="text-10 text-neutral-100">Phone</h6>
                  <p className="text-xs text-neutral-200">N/A</p>
                </div> */}
            <div className="h-[33px] w-[1px] bg-neutral-300" />
            <div className="font-medium">
              <h6 className="text-10 text-neutral-100">Email</h6>
              <a
                href={`mailto:${customer.emailAddress}`}
                className="block text-xs text-neutral-200"
              >
                {customer.emailAddress}
              </a>
            </div>
            <div className="h-[33px] w-[1px] bg-neutral-300" />
            <div className="font-medium">
              <h6 className="text-10 text-neutral-100">Location</h6>
              <p className="flex items-center gap-1 text-xs text-neutral-200">
                {customer.state}, {customer.country}
              </p>
            </div>
          </div>
          {/* <CustomerMenu /> */}
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
