import React, { Fragment } from "react";
import {
  CloseIcon,
  PromptedListingIcon,
  PurpleArrowIcon,
} from "../../constants/assets-constants";
import { Dialog, Transition } from "@headlessui/react";

const Instruction = [
  "Improve your product visibility",
  "Promote your products & Sell faster ",
  "Grow sales & Reach more customers",
];

const PromotedListingContent = [
  {
    title: "7 Days Promoted Listing",
    price: "NGN 2,030",
  },
  {
    title: "1 Month Promoted Listing ",
    price: "NGN 7,500",
  },
  {
    title: "3 Months Promoted Listing",
    price: "NGN 20,500",
  },
  {
    title: "6 Months Promoted Listing",
    price: "NGN 38,500",
  },
  {
    title: "1 Year Promoted Listing",
    price: "NGN 60,500",
  },
];

const PlanModal = ({ isOpen, setIsOpen }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[24.75rem] relative p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-[#1F1F1F] text-center text-[1.25rem] font-bold"
                >
                  Choose A Plan
                </Dialog.Title>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  className="absolute top-[20px] right-[20px] cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
                <div className="flex justify-center mt-[1.0rem]">
                  <div>
                    {Instruction.map((i, index) => {
                      return (
                        <div key={index} className="flex gap-[0.19rem]">
                          <img src={PurpleArrowIcon} alt="purple" />
                          <p className="text-[#525252] text-[0.8125rem] font-medium">
                            {i}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="my-[0.8rem]">
                  {PromotedListingContent.map((p, i) => {
                    return (
                      <PromotedListingMenu
                        key={i}
                        title={p.title}
                        price={p.price}
                      />
                    );
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PlanModal;

const PromotedListingMenu = ({ title, price }) => {
  return (
    <div className="flex justify-between pt-[1rem] pb-[0.7rem] border-b-[1px] border-[#DCDCDC]">
      <div className="flex gap-[0.2rem]">
        <img
          src={PromptedListingIcon}
          alt="promotedlisting"
          className="w-[1.5rem] h-[1.5rem]"
        />
        <p className="text-[#1F1F1F] text-[0.75rem] font-medium mt-[0.1rem]">
          {title}
        </p>
      </div>
      <div>
        <p className="text-[#1F1F1F] mb-[1rem] text-[0.75rem] font-medium">
          {price}
        </p>
        <button className="flex text-[#FFF] cursor-pointer bg-[#0FAA8E] py-[0.5rem] px-[1.2rem] gap-[2px] border-[1px] font-medium text-[0.75rem] rounded-[0.375rem] border-[#D9D9D9]">
          Purchase
        </button>
      </div>
    </div>
  );
};
