import axiosInstance from "../utils/axiosInstance";

export const getCurrentUser = () => {
  return axiosInstance.get("/business/account/user/me");
};

export const getStoreOrders = payload => {
  const { storeId, ...params } = payload || {};
  return axiosInstance.get(`/business/stores/order/list/${storeId}`, {
    params,
  });
};

export const getStoreWithdrawals = params => {
  return axiosInstance.get("/business/stores/withdrawal/list", {
    params,
  });
};

export const getStorefront = url => {
  return axiosInstance.get(`/business/stores/store/url/${url}`);
};

export const getBankList = () => {
  return axiosInstance.get("/business/stores/bank/list");
};

export const getProductList = payload => {
  const { storeId, ...params } = payload || {};
  return axiosInstance.get(`/business/stores/product/list/${storeId}`, {
    params,
  });
};
export const getOrderDetails = id => {
  return axiosInstance.get(`/business/stores/order/view/${id}`);
};

export const getProductDetails = id => {
  return axiosInstance.get(`/business/stores/product/view/${id}`);
};

export const getBankAccount = () => {
  return axiosInstance.get(
    `https://api.digible.one/v1/business/stores/bank/view`,
  );
};

export const getCart = cartId => {
  return axiosInstance.get(`/business/stores/cart/get/store/${cartId}`);
};

export const getCartAmount = storeId => {
  return axiosInstance.get(`/business/stores/cart/amount`, {
    data: { storeId },
  });
};

export const getCollections = payload => {
  const { storeId, ...params } = payload || {};
  return axiosInstance.get(`/business/stores/store/collections/${storeId}`, {
    params,
  });
};

export const getAnalytics = storeId => {
  return axiosInstance.get(`/business/stores/store/analytics/${storeId}`);
};

export const getCustomers = payload => {
  const { storeId, ...params } = payload || {};
  return axiosInstance.get(`/business/stores/order/customer/list/${storeId}`, {
    params,
  });
};

export const getCustomerOrders = email => {
  return axiosInstance.get(`/business/stores/order/customer/orders/${email}`);
};

export const getSubscription = () => {
  return axiosInstance.get("/business/stores/subscription");
};

export const getShippingProfiles = storeId => {
  return axiosInstance.get(
    `/business/stores/store/shipping-profile/${storeId}`,
  );
};
