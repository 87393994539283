import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/commons/buttons";
import ContentComponent from "../../components/commons/content-component";
import TitleComponent from "../../components/commons/title-component";
import {
  HeroVector,
  ClipPath,
  PolygonPurple,
  PolygonGreen,
  EllipsePink,
} from "../../constants/assets-constants";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="relative h-full pt-10 pb-0 lg:pt-20">
        <img
          className="absolute left-0 right-0 z-0 w-full top-1/30 "
          src={ClipPath}
          alt="clip path background"
        />
        <div className="relative z-50 flex items-center justify-between px-1/6 lg:px-1/13">
          <img
            className="absolute hidden md:block top-20 right-1/55"
            src={EllipsePink}
            alt=""
          />
          <img
            className="absolute top-0 w-8 right-1/30 lg:right-1/45"
            src={PolygonGreen}
            alt=""
          />
          <img
            className="absolute hidden md:block top-1/55 -left-16"
            src={PolygonPurple}
            alt=""
          />
          <div className="w-full mt-10 text-center md:w-1/2 xl:w-1/45 md:text-left lg:mt-20 ">
            <TitleComponent isLarge text="We Believe In The Future Of Your Business" />
            <ContentComponent
              text="Create a free online store that helps you find
          customers, accept payments from anyone, and grow your digital business."
            />
            <div className="relative z-50 flex justify-center py-10 mt-0 lg:mt-20 lg:block md:justify-start lg:pt-20">
              {/* <img
                className="absolute top-0 hidden lg:block -left-1/30"
                src={Spiral}
                alt=""
              /> */}
              <ButtonComponent
                isBlack
                btnText="Start now"
                callToAction={() => navigate("/signup")}
              />
            </div>
          </div>
          <div className="hidden w-1/2 -mr-20 md:block lg:w-3/5 ">
            <img className="object-contain w-full" src={HeroVector} alt="" />
          </div>
        </div>
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.38) -72.15%, #EFFFFD 100%)",
          }}
          className="absolute z-30 w-full h-32 lg:h-48 top-1/70 lg:top-1/94"
        />
      </div>
    </div>
  );
};

export default HeroSection;
