import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { useRecoilValue } from "recoil";
import useDebounce from "../../hooks/useDebounce";
import { useGetCollections } from "../../operations/queries";
import { userState } from "../../recoil/atoms";
import DataTable from "../commons/data-table";
import SearchInput from "../commons/search-input";
import { EditIcon, DeleteIcon } from "../../constants/assets-constants";
import { MenuWithPopover } from "../commons/MenuWithPopover";
import cn from "../../utils/cn";
import { useDeleteCollectionMutation } from "./../../operations/mutations";
import EditCollectionModal from "./EditCollectionModal";

const ProductsCollection = () => {
  const { store } = useRecoilValue(userState);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [editCollection, setEditCollection] = useState("");
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: parseInt(page) - 1,
    pageSize: 10,
  });
  const search = searchParams.get("search") ?? "";
  const debouncedSearch = useDebounce(search, 1000);
  const { data, isLoading, isPreviousData } = useGetCollections({
    storeId: store.id,
    search: debouncedSearch,
    page: pageIndex + 1,
    pageSize,
  });
  const { mutate: deleteCollection } = useDeleteCollectionMutation();

  const collections = data?.data?.data ?? [];
  const pagination = data?.data?.data?.pagination;

  useEffect(() => {
    setSearchParams(prev => {
      prev.set("page", pageIndex + 1);
      return prev;
    });
  }, [pageIndex, setSearchParams]);

  const menuitems = useMemo(
    () => [
      {
        title: "Edit",
        image: `${EditIcon}`,
        onClick: collections => {
          setEditCollection(collections);
          setIsOpenCategory(true);
        },
      },
      {
        title: "Delete",
        image: `${DeleteIcon}`,
        onClick: collections =>
          deleteCollection({ id: collections.id, storeId: store.id }),
      },
    ],
    [deleteCollection, store.id],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: info => info.getValue(),
      }),
      columnHelper.accessor("id", {
        header: "ID",
        cell: info => info.getValue(),
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        cell: info => (
          <span className="text-center">
            {new Date(info.getValue()).toLocaleDateString()}
          </span>
        ),
      }),
      columnHelper.accessor("updatedAt", {
        header: "Updated At",
        cell: info => new Date(info.getValue()).toLocaleDateString(),
      }),
      columnHelper.accessor("view", {
        header: () => "",
        cell: row => (
          <MenuWithPopover>
            {menuitems.map((item, index) => {
              return (
                <Menu.Item
                  key={index}
                  onClick={() => item.onClick(row.row.original)}
                  className="px-4 py-2 hover:bg-slate-100"
                >
                  {({ active }) => (
                    <button
                      className={cn(
                        "flex w-full items-center gap-2.5 text-left",
                        active && "bg-slate-100",
                      )}
                    >
                      <img
                        src={item.image}
                        className="w-4 h-4"
                        alt="physical-product-icon"
                      />
                      <p className="flex-1 text-sm font-medium whitespace-nowrap">
                        {item.title}
                      </p>
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </MenuWithPopover>
        ),
      }),
    ];
  }, [menuitems]);

  return (
    <div>
      <div className="flex justify-end py-3">
        <SearchInput
          placeholder="Search"
          query={search}
          setQuery={value => {
            setPagination({ pageIndex: 0, pageSize: 10 });
            searchParams.set("search", value);
            setSearchParams(searchParams);
          }}
          containerClass="w-full sm:w-auto"
        />
      </div>
      <EditCollectionModal
        collection={editCollection}
        isOpen={isOpenCategory}
        setIsOpen={setIsOpenCategory}
      />
      <DataTable
        data={collections}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={pagination?.total || collections?.length}
        isLoading={isLoading || isPreviousData}
        setPagination={setPagination}
      />
    </div>
  );
};

export default ProductsCollection;
