import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React from "react";
import cn from "../../utils/cn";
import Button from "./button";

function IconButton({
  text,
  icon,
  description,
  className,
  textClassName,
  iconPosition = "right",
  isLoading,
  ...props
}) {
  return (
    <Button
      type="button"
      className={cn(
        "inline-flex h-7 w-7 items-center justify-center rounded-full text-sm sm:h-8 sm:w-8",
        className,
      )}
      {...props}
    >
      {iconPosition === "left" &&
        (isLoading ? <ArrowPathIcon className="h-6 w-6 animate-spin" /> : icon)}
      {text && <span className={textClassName}>{text}</span>}
      {iconPosition === "right" &&
        (isLoading ? <ArrowPathIcon className="h-6 w-6 animate-spin" /> : icon)}
      {description && <span className="sr-only">{description}</span>}
    </Button>
  );
}

export default IconButton;
