import React from "react";
import PageDisplay from "../../../components/settings/PageDisplay";
import { useRecoilValue } from "recoil";
import { userState } from "../../../recoil/atoms";
import FormInput from "../../../components/form/form-input";
import ButtonContainer from "../../../components/settings/ButtonContainer";
import SEO from "../../../components/commons/seo";

const PersonalInformationPage = () => {
  const { store } = useRecoilValue(userState);
  return (
    <div className="mx-auto max-w-screen-lg py-0 sm:py-9">
      <PageDisplay linkTitle="Personal Information" />
      <SEO title="Personal Information" />
      <div className="w-full rounded-[0.42875rem] border-[1px] border-neutral-300 md:w-[36rem]">
        <div className="p-[2rem]">
          <p className="text-[1.75rem] font-[600] text-dark-100">
            Personal Information
          </p>
          <p className="mt-[1.5rem] text-[0.75rem] text-neutral-100">
            Manage your Digible profile
          </p>
          <p className="my-[0.8rem] text-[0.75rem] font-[600] text-neutral-100">
            Picture
          </p>
          <div className="flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full bg-green-100 text-[3rem] text-white">
            {store.storeName.charAt(0)}
          </div>
          <p className="my-[1.8rem] text-[0.75rem] font-[600] text-neutral-100">
            General
          </p>
          <div className="flex gap-[1rem]">
            <FormInput
              id="name"
              name="Name"
              // value={values.billFrom}
              placeholder="Sarah"
              // error={Boolean(errors.billFrom)}
              // onChange={handleChange}
              label="First name"
              // errorMsg={errors.billFrom}
              className="flex-1"
            />
            <FormInput
              id="name"
              name="Name"
              // value={values.billFrom}
              placeholder="James"
              // error={Boolean(errors.billFrom)}
              // onChange={handleChange}
              label="Last name"
              // errorMsg={errors.billFrom}
              isRequired
              className="flex-1"
            />
          </div>
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="placeholder"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Email"
            // errorMsg={errors.billFrom}
            className="mb-[2rem] mt-[1rem]"
          />
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="National ID"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="Form Of Identification"
            // errorMsg={errors.billFrom}
            className="mb-[2rem] mt-[1rem]"
          />
          <FormInput
            id="name"
            name="Name"
            // value={values.billFrom}
            placeholder="90192834"
            // error={Boolean(errors.billFrom)}
            // onChange={handleChange}
            label="ID Number"
            // errorMsg={errors.billFrom}
            className="mb-[2rem] mt-[1rem]"
          />
          <div className="flex items-center justify-center">
            <button
              className="gap-[10px] rounded-md border bg-green-100 px-[2.0rem] py-[0.7rem]"
              // onClick={onClickSave}
            >
              <p className="text-[0.75rem] font-[500] text-[#fff]">Upload ID</p>
            </button>
          </div>
        </div>
        <ButtonContainer />
      </div>
    </div>
  );
};

export default PersonalInformationPage;
