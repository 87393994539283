import React from "react";

function HeaderCard({ header }) {
  const { landscapeImage, subHeading, name } = header;

  return (
    <div className="relative aspect-[4/3] sm:aspect-video">
      <img
        className="h-full w-full object-cover"
        src={landscapeImage}
        alt="header"
      />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-end gap-3 px-4 py-4 text-white backdrop-brightness-75 md:px-10 md:py-6">
        <h5 className="text-lg font-medium md:text-2xl">{subHeading[0]}</h5>
        <h3 className="text-3xl font-semibold md:text-40">{name}</h3>
        <h5 className="text-lg font-medium md:text-2xl">{subHeading[1]}</h5>
      </div>
    </div>
  );
}

export default HeaderCard;
