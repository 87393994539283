import { Tab } from "@headlessui/react";

function TabList({ children }) {
  return (
    <Tab.List className="flex items-center gap-5 min-h-[36px]">
      {children}
    </Tab.List>
  );
}

export default TabList;
