import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/atoms";

const GuestGuard = ({ children }) => {
  const user = useRecoilValue(userState);
  const [searchParams] = useSearchParams();
  const route = searchParams.get("redirect") || "/dashboard";

  if (!!user?.store) return <Navigate to={route} replace />;

  return children ? <>{children}</> : <Outlet />;
};
export default GuestGuard;
