import { useFormik } from "formik";
import { useRecoilValue } from "recoil";
import * as yup from "yup";

import BusyOverlay from "../../components/commons/busy-overlay";
import Button from "../../components/commons/button";
import { ColourInput } from "../../components/form";
import { useUpdateStoreMutation } from "../../operations/mutations";
import { userState } from "../../recoil/atoms";
import Header from "./header";
import Socials from "./socials";
import StoreSettings from "./store-settings";
import SEO from "../../components/commons/seo";

const initialHeader = {
  name: "",
  subHeading: ["", ""],
  headerStatus: false,
  landscapeImage: "",
};

const defaultSocials = [
  {
    platform: "Twitter",
    username: "",
    url: "",
  },
  {
    platform: "Instagram",
    username: "",
    url: "",
  },
  {
    platform: "Facebook",
    username: "",
    url: "",
  },
];

const getHeaders = storeHeaders => {
  const MAX_HEADERS = 3;
  if (!storeHeaders || storeHeaders.length === 0)
    return Array(MAX_HEADERS).fill(initialHeader);

  if (storeHeaders.length < MAX_HEADERS)
    return [
      ...storeHeaders,
      ...Array(MAX_HEADERS - storeHeaders.length).fill(initialHeader),
    ];

  return storeHeaders;
};

const getSocials = socials => {
  if (!socials || socials.length === 0) return defaultSocials;

  return defaultSocials.map(
    social => socials.find(s => s.platform === social.platform) || social,
  );
};

function StoreManagementPage() {
  const { store } = useRecoilValue(userState);
  const { mutate: updateStore, isLoading } = useUpdateStoreMutation();

  const validationSchema = yup.object().shape({
    storeName: yup.string().required("Store name is required"),
  });

  const {
    errors,
    values,
    validateForm,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeName: store.storeName,
      uniqueLink: `${window.origin}/${store.storeUrl}`,
      storeDescription: store.storeDescription,
      customDomain: "",
      brandColor: store.brandColor,
      socials: getSocials(store.socials),
      headers: getHeaders(store.headers),
      storeLogo: store.storeLogo,
    },
    validationSchema,
    onSubmit: values => {
      validateForm(values).then(() => {
        updateStore({
          ...values,
          id: store.id,
          socials: values.socials
            .filter(social => !!social.username)
            .map(social => ({
              ...social,
              url: `https://${social.platform.toLowerCase()}.com/${
                social.username
              }`,
            })),
          headers: values.headers.filter(header => !!header.name),
        });
      });
    },
  });

  const handlePublish = () => {
    updateStore({ storeStatus: "ACTIVE", id: store.id });
  };

  return (
    <form
      className="relative -m-3 text-dark-100 sm:-m-4"
      onSubmit={handleSubmit}
    >
      <SEO title="Store Management"/>
      <BusyOverlay loading={isLoading} />
      <div className="p-3 sm:p-4">
        <StoreSettings
          values={values}
          errors={errors}
          handleChange={handleChange}
          handlePublish={handlePublish}
          setFieldValue={setFieldValue}
        />
        {values &&
          values.headers.map((header, index) => (
            <Header
              key={index}
              index={index}
              values={header}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          ))}
        <Socials values={values} handleChange={handleChange} />
        <ColourInput
          subHeading="Customize Text and Button Colours on your store front"
          value={values.brandColor}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </div>
      <div className="sticky left-0 z-10 flex justify-end w-full p-4 bg-white border-t -bottom-3 border-t-neutral-300">
        <Button type="submit" disabled={isLoading}>
          Save Details
        </Button>
      </div>
    </form>
  );
}

export default StoreManagementPage;
